import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Cryptograf from 'crypto-js';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environment/environments';
import { UserService } from './user.service';
interface AuthResponse {
  avatar: string;
  nome: string;
  token: string;
  conteudos: Array<number>;
  userType: boolean;
  permissionSubgrupo: Array<number>;
  permissionCharts: Array<{ id_conteudo: number; nome: Array<string> }>;
}

interface AuthTwoFactorResponse {
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient, private userService: UserService) {}

  autenticar(
    login: string,
    senha: string
  ): Observable<HttpResponse<AuthResponse>> {
    return this.http
      .post<AuthResponse>(
        `${this.apiUrl}/login`,
        { login, senha },
        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
          const authToken = response.body?.token || '';
          localStorage.setItem('avatar', response.body?.avatar || '');
          localStorage.setItem('nome', response.body?.nome || '');

          const userTypeCriptografado = Cryptograf.AES.encrypt(
            JSON.stringify(response.body?.userType),
            environment.cryptoKey
          );

          localStorage.setItem(
            'userType',
            JSON.stringify(userTypeCriptografado.toString())
          );

          const conteudosCriptografados = Cryptograf.AES.encrypt(
            JSON.stringify(response.body?.conteudos),
            environment.cryptoKey
          );

          localStorage.setItem(
            'conteudos',
            JSON.stringify(conteudosCriptografados.toString())
          );

          const permissionSubgrupoCriptografado = Cryptograf.AES.encrypt(
            JSON.stringify(response.body?.permissionSubgrupo),
            environment.cryptoKey
          );

          localStorage.setItem(
            'permissionSubgrupo',
            JSON.stringify(permissionSubgrupoCriptografado.toString())
          );

          const permissionChartsCriptografado = Cryptograf.AES.encrypt(
            JSON.stringify(response.body?.permissionCharts),
            environment.cryptoKey
          );

          localStorage.setItem(
            'permissionCharts',
            JSON.stringify(permissionChartsCriptografado.toString())
          );

          this.userService.salvarToken(authToken);
        })
      );
  }

  autenticacaoDoisFatores(
    nome: string
  ): Observable<HttpResponse<AuthTwoFactorResponse | null>> {
    return this.http
      .post<AuthTwoFactorResponse | null>(
        `${this.apiUrl}/usuario/create-two-factor-code`,
        { nome },
        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
          // caso a resposta seja retornada, o email é criptografado e salvo no localStorage
          // para ser utilizado na página de autenticação de dois fatores
          if (response.status === 201) {
            const emailCriptografado = Cryptograf.AES.encrypt(
              JSON.stringify(response.body?.email),
              environment.cryptoKey
            );
            localStorage.setItem(
              'email',
              JSON.stringify(emailCriptografado.toString())
            );
          }
        })
      );
  }

  verificarToken(code: string, email: string): Observable<HttpResponse<null>> {
    return this.http.put<null>(
      `${this.apiUrl}/usuario/verify-two-factor-code`,
      { code, email },
      { observe: 'response' }
    );
  }
}
