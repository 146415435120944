import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { HomeService } from 'src/app/core/services/home.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.scss'],
})
export class HomeAdminComponent implements OnInit {
  showFiller = false;
  relatorio = new FormControl('');
  usuario = localStorage.getItem('nome');
  avatar = localStorage.getItem('avatar');

  navegacao: number = 0;
  conteudos: Array<number> = [];
  admin: boolean = false;

  private unsubscribe$ = new Subject<void>();

  emitRelatorio: boolean = false;
  emitDeslogar: boolean = false;
  emitPaineis: boolean = false;
  emitTrocarSenha: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.admin = this.userService.isAdmin();
    this.homeService.obterVariavel().subscribe((valor) => {
      this.navegacao = valor;
      this.cdr.detectChanges();
    });
  }

  private resetUnsubscribe() {
    // Emite um valor para cancelar as inscrições anteriores
    this.unsubscribe$.next();

    // Cria um novo Subject para futuras desinscrições
    this.unsubscribe$ = new Subject<void>();
  }

  navigateTo() {
    this.resetUnsubscribe();
    this.emitRelatorio = true;

    this._sharedService.emitChange(true);
    this._sharedService.changeEmitted$
      .pipe(takeUntil(this.unsubscribe$), take(2))
      .subscribe((data) => {
        if (data && data === true) {
          this.router.navigate([this.relatorio.value]);
        }
      });
  }

  deslogar() {
    this.resetUnsubscribe();
    this.emitDeslogar = true;

    this._sharedService.emitChange(true);
    this._sharedService.changeEmitted$
      .pipe(takeUntil(this.unsubscribe$), take(2))
      .subscribe((data) => {
        if (data && data === true) {
          this.userService.logout();
          this.router.navigate(['/login']);
        }
      });
  }

 paineis() {
    this.resetUnsubscribe();
    this.emitPaineis = true;

    this._sharedService.emitChange(true);
    this._sharedService.changeEmitted$
      .pipe(takeUntil(this.unsubscribe$), take(2))
      .subscribe((data) => {
        if (data && data === true) {
          this.router.navigate(['home/']);
        }
      });
  }

  trocarSenha() {
    this.resetUnsubscribe();
    this.emitTrocarSenha = true;

    this._sharedService.emitChange(true);
    this._sharedService.changeEmitted$
      .pipe(takeUntil(this.unsubscribe$), take(2))
      .subscribe((data) => {
        if (data && data === true) {
          this.router.navigate(['trocar-senha']);
        }
      });
  }
}
