<div class="dialog-container">
  <p class="dialog-title">Visualização de Arquivo</p>
  <div class="divider"></div>
  <div class="dialog-content">
    <p><strong>Veículo:</strong> {{ data.veiculo }}</p>
    <p><strong>Data:</strong> {{ data.data }}</p>
    <p><strong>Título:</strong> {{ data.titulo }}</p>
    <p><strong>Arquivo:</strong> {{ data.arquivo }}</p>
    <button
      mat-raised-button
      *ngIf="data.midia && data.midia == 'Site' && data.link"
      color="primary"
      (click)="acessar()"
    >
      Acessar site
    </button>

    <hr class="divider-line" />

    <div class="pdf-container">
      <a [href]="pdf" rel="noopener noreferrer">Abrir PDF</a>
    </div>
  </div>

  <button mat-raised-button color="primary" (click)="closeDialog()">
    Fechar
  </button>
</div>
