import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, catchError, tap } from 'rxjs';
import { environment } from 'src/environment/environments';

@Injectable({
  providedIn: 'root',
})
export class TrocarSenhaService {
  private apiUrl: string = environment.apiUrl;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  trocarSenha(old_password: string, new_password: string) {
    return this.http
      .put<any>(
        `${this.apiUrl}/usuario/change-password`,
        { old_password, new_password },
        {
          observe: 'response',
          headers: { authorization: localStorage.getItem('token') ?? '' },
        }
      )
      .pipe(
        tap((response) => {
          return response;
        }),
        catchError((error) => {
          throw new Error(error.error.message);
        })
      );
  }

  linkValido(code: string) {
    return this.http
      .post<any>(
        `${this.apiUrl}/usuario/verify-password-code/${code}`,
        {},
        {
          observe: 'response',
        }
      )
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          throw error;
        })
      );
  }

  esqueciSenha(new_password: string, code: string) {
    return this.http
      .put<any>(
        `${this.apiUrl}/usuario/forgot-password`,
        { new_password, code },
        {
          observe: 'response',
        }
      )
      .pipe(
        tap((response) => {
          return response;
        }),
        catchError((error) => {
          throw { message: error.error.message, status: error.status };
        })
      );
  }

  enviarEmail(login: string, email: string) {
    return this.http
      .post<any>(
        `${this.apiUrl}/usuario/create-forgot-password-code`,
        { login, email },
        {
          observe: 'response',
        }
      )
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          throw error;
        })
      );
  }
}
