import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// Service criado para controlar a comunicação entre componentes
export class SharedService {
  // Observable string sources
  private emitChangeSource = new BehaviorSubject<boolean>(false);
  // Observable string streams
  changeEmitted$: Observable<boolean> = this.emitChangeSource.asObservable();
  // Service message commands
  emitChange(change: boolean) {
    this.emitChangeSource.next(change);
  }
}
