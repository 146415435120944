import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { captureException } from '@sentry/angular-ivy';
import { DashboardComunidadeService } from 'src/app/core/services/dashboard-comunidade.service';
import { DashboardImprensaService } from 'src/app/core/services/dashboard-imprensa.service';
import { DashboardJuridicoService } from 'src/app/core/services/dashboard-juridico.service';
import { DashboardLegislativoService } from 'src/app/core/services/dashboard-legislativo.service';
import Swal from 'sweetalert2';
import { DialogAudioComponent } from '../dialogs/dialog-audio/dialog-audio.component';
import { DialogImgComponent } from '../dialogs/dialog-img/dialog-img.component';
import { DialogTranscricaoComponent } from '../dialogs/dialog-transcricao/dialog-transcricao.component';
import { DialogVideoComponent } from '../dialogs/dialog-video/dialog-video.component';

@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss'],
})
export class TabelaComponent implements OnChanges {
  // Variável que recebe o conteúdo da tabela
  @Input() conteudo: number = 1;
  // Variável que recebe os parâmetros da requisição
  @Input() request: string = '';
  // Variável que recebe o serviço para a requisição
  @Input() service:
    | DashboardComunidadeService
    | DashboardImprensaService
    | DashboardJuridicoService
    | DashboardLegislativoService
    | null = null;

  // Variável que emite o total de registros da tabela
  @Output() countData = new EventEmitter<number>();
  // Variável que emite o status de carregamento da tabela
  @Output() loadingCount = new EventEmitter<boolean>(true);

  displayedColumns: string[] = [];
  dataTabela: { [key: string]: string | number }[] = [];
  dataSource: MatTableDataSource<any>;

  carregandoTabela = true;
  tabelaVazia = false;
  erroTabela = false;

  // Variáveis para a paginação da tabela
  paginaAtual = 1;
  rowsPerPage = 10;
  ultimaPagina = 1;
  length = 0;
  pageIndex = 1;
  pageEvent: PageEvent = <
    {
      length: number;
      pageIndex: number;
      pageSize: number;
      previousPageIndex: number;
    }
  >{};

  constructor(public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource(this.dataTabela);
  }

  // Função que é chamada toda vez que há uma mudança nos inputs
  async ngOnChanges(changes: SimpleChanges) {
    if (changes['request'] && changes['request'].currentValue) {
      // Reiniciando as variáveis de paginação
      this.paginaAtual = 1;
      this.rowsPerPage = 10;
      this.ultimaPagina = 1;
      this.length = 0;
      this.pageIndex = 1;

      this.carregandoTabela = true;
      this.erroTabela = false;
      this.loadingCount.emit(true);
      await new Promise((resolve, reject) => {
        if (this.service !== null) {
          this.service
            .listarTabela(`${this.conteudo}?page=1&per_page=10&${this.request}`)
            .subscribe({
              next: (value) => {
                this.dataTabela = value.records;
                this.countData.emit(value._metadata.total_count);
                this.loadingCount.emit(false);
                this.carregandoTabela = false;

                if (value.columns) this.displayedColumns = value.columns;
                this.length = value._metadata.total_count;
                this.dataSource = new MatTableDataSource(this.dataTabela);
                this.ultimaPagina = value._metadata.total_pages;
                if (value.columns.length == 0) {
                  this.tabelaVazia = true;
                } else {
                  this.tabelaVazia = false;
                }
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.recarregarTabela();
                reject(error);
              },
            });
        } else {
          this.carregandoTabela = false;
          this.loadingCount.emit(false);
          resolve(true);
          return;
        }
      }).catch((error: HttpErrorResponse) => {
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao carregar os dados da tabela',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
        throw error;
      });
    }
  }

  // Função que é chamada toda vez que há uma mudança na página da tabela
  async eventosTabela(event: PageEvent) {
    this.dataSource = new MatTableDataSource();

    const carregarTabela = async (pagina: number) => {
      this.carregandoTabela = true;
      this.tabelaVazia = false;
      this.erroTabela = false;
      try {
        await new Promise((resolve, reject) => {
          if (this.service !== null) {
            this.service
              .listarTabela(
                `${this.conteudo}?page=${pagina}&per_page=${this.rowsPerPage}&${this.request}`
              )
              .subscribe({
                next: (value) => {
                  this.dataTabela = value.records;
                  if (value.columns) this.displayedColumns = value.columns;
                  this.length = value._metadata.total_count;
                  this.dataSource = new MatTableDataSource(this.dataTabela);
                  this.paginaAtual = pagina;
                  this.carregandoTabela = false;
                  if (value.columns.length == 0) {
                    this.tabelaVazia = true;
                  } else {
                    this.tabelaVazia = false;
                  }
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.recarregarTabela();
                  reject(error);
                },
              });
          } else {
            this.carregandoTabela = false;
            this.loadingCount.emit(false);
            resolve;
          }
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao carregar os dados da tabela',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
        throw error;
      }
    };

    if (event.pageSize != this.rowsPerPage) {
      // Esse if verifica se o usuário mudou a quantidade de itens por página (pageSize)

      // Calcula o índice do primeiro item da página atual, baseado na quantidade anterior de itens por página
      const idx = (this.paginaAtual - 1) * this.rowsPerPage;

      // Atualiza a página atual para refletir a nova posição com a nova quantidade de itens por página
      this.paginaAtual = Math.floor(idx / event.pageSize) + 1;

      // Atualiza a quantidade de itens por página para o novo valor selecionado
      this.rowsPerPage = event.pageSize;

      await carregarTabela(this.paginaAtual);
    } else if (event.previousPageIndex == event.pageIndex - 1) {
      // Esse if detecta se o usuário clicou no botão de próxima página

      await carregarTabela(this.paginaAtual + 1);
    } else if (event.previousPageIndex == event.pageIndex + 1) {
      // Esse if detecta se o usuário clicou no botão de página anterior

      await carregarTabela(this.paginaAtual - 1);
    } else if (
      event.previousPageIndex != undefined &&
      event.previousPageIndex > event.pageIndex
    ) {
      // Esse if detecta se o usuário clicou para ir para a primeira página

      await carregarTabela(1);
    } else if (
      event.previousPageIndex != undefined &&
      event.previousPageIndex < event.pageIndex
    ) {
      // Esse if detecta se o usuário clicou para ir para a última página

      await carregarTabela(this.ultimaPagina);
    }

    this.pageEvent = event;
    this.pageIndex = event.pageIndex;
  }

  // Função que recarrega a tabela
  recarregarTabela() {
    new Promise((resolve, reject) => {
      this.erroTabela = false;
      this.carregandoTabela = true;
      this.loadingCount.emit(true);
      if (this.service !== null) {
        this.service
          .listarTabela(
            `${this.conteudo}?page=1&per_page=${this.rowsPerPage}&${this.request}`
          )
          .subscribe({
            next: (value) => {
              this.dataTabela = value.records;
              this.dataSource = new MatTableDataSource(this.dataTabela);
              if (value.columns) this.displayedColumns = value.columns;
              this.length = value._metadata.total_count;
              this.ultimaPagina = value._metadata.total_pages;

              this.countData.emit(value._metadata.total_count);

              if (value.columns.length == 0) {
                this.tabelaVazia = true;
              } else {
                this.tabelaVazia = false;
              }
              resolve(true);
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            },
          });
      }
    })
      .catch((error: HttpErrorResponse) => {
        this.erroTabela = true;
        captureException(error);
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao recarregar os dados da tabela',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
        throw error;
      })
      .finally(() => {
        this.carregandoTabela = false;
        this.loadingCount.emit(false);
      });
  }

  // Funções para abrir os diálogos de acordo com o tipo de arquivo
  openDialogAudio(
    conteudo: number,
    keywords: string[],
    midia: string,
    link: string,
    data: string,
    veiculo: string,
    titulo: string,
    arquivo: string,
    transcricao: string,
    programa: string,
    classificacao: string,
    autoridade: string,
    partido: string
  ) {
    const dialogRef = this.dialog.open(DialogAudioComponent, {
      width: '900px',
      data: {
        conteudo: conteudo,
        keywords: keywords,
        midia: midia ? midia : null,
        link: link,
        data: data,
        veiculo: veiculo,
        titulo: titulo,
        arquivo: arquivo,
        transcricao: transcricao ? transcricao : null,
        programa: programa,
        classificacao: classificacao,
        autoridade: autoridade ? autoridade : null,
        partido: partido ? partido : null,
      },
    });

    dialogRef.afterClosed().subscribe();
  }

  openDialogPdf(arquivo: string) {
    // Abre o site em uma nova aba do navegador
    window.open(arquivo.substring(5), '_blank');
  }

  openDialogImg(
    conteudo: number,
    keywords: string[],
    midia: string,
    link: string,
    data: string,
    veiculo: string,
    titulo: string,
    arquivo: string,
    transcricao: string,
    programa: string,
    classificacao: string,
    autoridade: string,
    partido: string
  ) {
    const dialogRef = this.dialog.open(DialogImgComponent, {
      width: '900px',
      data: {
        conteudo: conteudo,
        keywords: keywords,
        midia: midia ? midia : null,
        link: link,
        data: data,
        veiculo: veiculo,
        titulo: titulo,
        arquivo: arquivo,
        transcricao: transcricao ? transcricao : null,
        programa: programa,
        classificacao: classificacao,
        autoridade: autoridade ? autoridade : null,
        partido: partido ? partido : null,
      },
    });

    dialogRef.afterClosed().subscribe();
  }

  openDialogVideo(
    conteudo: number,
    keywords: string[],
    midia: string,
    link: string,
    data: string,
    veiculo: string,
    titulo: string,
    arquivo: string,
    transcricao: string,
    programa: string,
    classificacao: string,
    autoridade: string,
    partido: string
  ) {
    const dialogRef = this.dialog.open(DialogVideoComponent, {
      width: '900px',
      data: {
        conteudo: conteudo,
        keywords: keywords,
        midia: midia ? midia : null,
        link: link,
        data: data,
        veiculo: veiculo,
        titulo: titulo,
        arquivo: arquivo,
        transcricao: transcricao ? transcricao : null,
        programa: programa,
        classificacao: classificacao,
        autoridade: autoridade ? autoridade : null,
        partido: partido ? partido : null,
      },
    });

    dialogRef.afterClosed().subscribe();
  }

  openDialogTranscricao(
    conteudo: number,
    keywords: string[],
    midia: string,
    link: string,
    data: string,
    veiculo: string,
    titulo: string,
    transcricao: string,
    programa: string,
    classificacao: string,
    autoridade: string,
    partido: string
  ) {
    const dialogRef = this.dialog.open(DialogTranscricaoComponent, {
      width: '900px',
      data: {
        conteudo: conteudo,
        keywords: keywords,
        midia: midia ? midia : null,
        link: link,
        data: data,
        veiculo: veiculo,
        titulo: titulo,
        transcricao: transcricao ? transcricao : null,
        programa: programa,
        classificacao: classificacao,
        autoridade: autoridade ? autoridade : null,
        partido: partido ? partido : null,
      },
    });

    dialogRef.afterClosed().subscribe();
  }

  // Funções para verificar o tipo de arquivo
  isAudioFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('audio: ');
  }

  isPdfFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('pdf: ');
  }

  isImageFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('image: ');
  }

  isVideoFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('video: ');
  }

  isTextFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('texto: ');
  }

  isArqNull(fileName: string): boolean {
    if (fileName == null) return true;
    return false;
  }

  // Função para abrir o link em uma nova aba
  // A função só é executada quando a linha da tabela não possui um arquivo, mas possui um link
  OpenLinkInNewTab(url: string) {
    window.open(url, '_blank');
  }

  // Função para verificar se o arquivo e o link são nulos
  isArqAndLinkNull(fileName: string, link: string): boolean {
    if (fileName == null && link == null) return true;
    return false;
  }

  gerarCSV() {
    Swal.fire({
      background: '#ffffff00',
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          const request = this.request;
          if (this.service === null) return;
          await this.service.gerarCSV(request);

          Swal.close();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'houve um erro ao gerar o pdf',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
          Swal.close();
        }
      },
    });
  }

  gerarPlanilha() {
    Swal.fire({
      background: '#ffffff00',
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          const request = this.request;
          if (this.service === null) return;
          await this.service.gerarPlanilha(request);

          Swal.close();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'houve um erro ao gerar o pdf',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
          Swal.close();
        }
      },
    });
  }
}
