import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Color, type EChartsOption } from 'echarts';
import * as echarts from 'echarts/core';
import { EChartTheme } from 'src/assets/echarts-theme';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent implements OnInit, OnChanges {
  @Input() data: number | null = 0;
  // As cores são passadas como um array de strings
  @Input() color: Color[] = [];

  @Input() title: string = '';

  @Output() chartImage = new EventEmitter<{
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }>();

  @ViewChild('chart1', { static: true }) chartElement!: ElementRef;

  options: EChartsOption = {};
  mergeOptions: EChartsOption = {};
  tema: EChartsOption = EChartTheme.theme;

  constructor() {}

  ngOnInit(): void {
    this.options = {
      series: [
        {
          animation: true,
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', '85%'],
          radius: '90%',
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 70,
              color: [
                [0.2, '#DD6B7F'],
                [0.4, '#E49B73'],
                [0.6, '#F5C869'],
                [0.8, '#BECA89'],
                [1, '#77C4A8'],
              ],
            },
          },
          pointer: {
            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
            length: '75%',
            width: 20,
            offsetCenter: [0, '5%'],
            itemStyle: {
              color: 'gray',
            },
          },
          axisTick: {
            splitNumber: 3,
            length: 70,
            distance: -70,
            lineStyle: {
              color: 'auto',
              width: 2,
            },
          },
          splitLine: {
            length: 70,
            distance: -70,
            lineStyle: {
              color: 'auto',
              width: 5,
            },
          },
          axisLabel: {
            color: '#464646',
            fontSize: 20,
            distance: -20,
            rotate: 'tangential',
            formatter: function (value) {
              return '';
            },
          },
          title: {
            offsetCenter: [0, '-180%'],
            fontSize: 20,
            fontWeight: 600,
          },
          detail: {
            fontSize: 60,
            offsetCenter: [0, '-135%'],
            valueAnimation: false,
            formatter: function (value) {
              return Math.round(value * 100) + '%';
            },
            color: 'inherit',
          },
          data: [
            {
              value: this.data ?? 0,
              name: this.title,
            },
          ],
        },
      ],
    };
  }

  ngOnChanges(): void {
    this.alterarDados();
  }

  alterarDados() {
    this.mergeOptions = {
      series: [
        {
          animation: true,
          animationDuration: 1000,
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', '85%'],
          radius: '90%',
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 30,
              color: [
                [0.2, '#DD6B7F'],
                [0.4, '#E49B73'],
                [0.6, '#F5C869'],
                [0.8, '#BECA89'],
                [1, '#77C4A8'],
              ],
            },
          },
          pointer: {
            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
            length: '75%',
            width: 10,
            offsetCenter: [0, '5%'],
            itemStyle: {
              color: 'gray',
            },
          },
          axisTick: {
            splitNumber: 3,
            length: 0,
            distance: -70,
            lineStyle: {
              color: 'auto',
              width: 2,
            },
          },
          splitLine: {
            length: 0,
            distance: -70,
            lineStyle: {
              color: 'auto',
              width: 5,
            },
          },
          axisLabel: {
            color: '#464646',
            fontSize: 20,
            distance: -20,
            rotate: 'tangential',
            formatter: function (value) {
              return '';
            },
          },
          title: {
            offsetCenter: [0, '-170%'],
            fontSize: 15,
            fontWeight: 600,
          },
          detail: {
            fontSize: 25,
            offsetCenter: [0, '-120%'],
            valueAnimation: false,
            formatter: function (value) {
              return Math.round(value * 100) + '%';
            },
            color: 'inherit',
          },
          data: [
            {
              value: this.data ?? 0,
              name: this.title,
            },
          ],
        },
      ],
    };
  }

  async imageOutput() {
    return new Promise<void>(async (resolve) => {
      const chartContainer = document.createElement('div');
      chartContainer.style.width = '1000px'; // largura desejada
      chartContainer.style.height = '500px'; // altura desejada
      chartContainer.style.position = 'absolute';
      chartContainer.style.left = '-9999px'; // movendo para fora da tela para renderizar invisivelmente

      document.body.appendChild(chartContainer);

      echarts.registerTheme('meuTema', this.tema);

      const chart = echarts.init(chartContainer, 'meuTema');

      let auxOptions = this.options;
      auxOptions.series = [
        {
          animation: true,
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', '85%'],
          radius: '90%',
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 90,
              color: [
                [0.2, '#DD6B7F'],
                [0.4, '#E49B73'],
                [0.6, '#F5C869'],
                [0.8, '#BECA89'],
                [1, '#77C4A8'],
              ],
            },
          },
          pointer: {
            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
            length: '85%',
            width: 25,
            offsetCenter: [0, '5%'],
            itemStyle: {
              color: 'gray',
            },
          },
          axisTick: {
            splitNumber: 3,
            length: 0,
            distance: -70,
            lineStyle: {
              color: 'auto',
              width: 2,
            },
          },
          splitLine: {
            length: 0,
            distance: -70,
            lineStyle: {
              color: 'auto',
              width: 5,
            },
          },
          axisLabel: {
            color: '#464646',
            fontSize: 20,
            distance: -20,
            rotate: 'tangential',
            formatter: function (value) {
              return '';
            },
          },
          title: {
            offsetCenter: [0, '-160%'],
            fontSize: 20,
            fontWeight: 600,
          },
          detail: {
            fontSize: 60,
            offsetCenter: [0, '-125%'],
            valueAnimation: false,
            formatter: function (value) {
              return Math.round(value * 100) + '%';
            },
            color: 'inherit',
          },
          data: [
            {
              value: this.data ?? 0,
              name: this.title,
            },
          ],
        },
      ];

      if (this.color.length != 0) {
        auxOptions.color = this.color;
      }

      // Renderizar o gráfico dentro do contêiner
      chart.setOption(auxOptions);

      setTimeout(() => {
        const imageDataURL = chart.getDataURL({
          type: 'jpeg', // Use 'jpeg' para reduzir o tamanho
          pixelRatio: 2, // Reduza o pixelRatio
          backgroundColor: '#fff', // cor de fundo opcional
          excludeComponents: ['toolbox'], // componentes a serem excluídos, se necessário
        });

        // Remover o contêiner após a exportação
        document.body.removeChild(chartContainer);

        const imgWidth = chart.getWidth(); // largura da imagem no PDF
        const imgHeight = chart.getHeight(); // altura proporcional

        const chartImageObj = {
          dataURL: imageDataURL,
          imgWidth: imgWidth,
          imgHeight: imgHeight,
        };

        this.chartImage.emit(chartImageObj);
        resolve();
      }, 1000);
    });
  }
}
