import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Color, type EChartsOption } from 'echarts';
import * as echarts from 'echarts/core';
import { EChartTheme } from 'src/assets/echarts-theme';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() data: any[][] = [];
  @Input() color: Color[] | string = [];
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() colorfull: boolean = false;
  @Input() useCurrency: boolean = false;
  @Input() maxValueX?: number;

  @Output() chartImage = new EventEmitter<{
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }>();

  @ViewChild('chart1', { static: true }) chartElement!: ElementRef;

  options: EChartsOption = {};
  mergeOptions: EChartsOption = {};
  tema: EChartsOption = EChartTheme.theme;
  total: string = '';

  constructor() {}

  ngOnInit(): void {
    this.options = {
      title: {
        text: this.title,
        subtext: this.subTitle,
        left: 'center',
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            title: 'Salvar',
            pixelRatio: 4,
          },
        },
      },
      grid: {
        left: '130px',
      },
      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
        show: this.colorfull,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params: any) => {
          return (
            params[0].axisValueLabel +
            '<br/>' +
            params[0].marker +
            ' ' +
            params[0].value[1].toLocaleString('pt-BR', {
              style: this.useCurrency ? 'currency' : 'decimal',
              currency: 'BRL',
            })
          );
        },
      },
      dataset: {
        source: this.data,
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value: number) =>
            this.useCurrency ? this.formatNumber(value) : value.toString(),
        },
      },

      yAxis: {
        type: 'category',
        axisLabel: {
          fontSize: 10,
          color: '#444',
          formatter: (value: string) => {
            if (value.length > 8) {
              return value.slice(0, 20) + '...';
            } else {
              return value;
            }
          },
        },
        z: 10,
      },

      color: this.color,
      series: [
        {
          colorBy: this.colorfull ? 'data' : 'series',
          type: 'bar',
          label: {
            show: true,
            position: 'right',
            formatter: (params: any) => {
              if (!this.useCurrency) return params.value[1];
              else {
                return this.formatNumber(params.value[1]);
              }
            },
          },
        },
      ],
    };
  }

  ngOnChanges(): void {
    this.alterarDados();
  }

  alterarDados() {
    this.mergeOptions = {
      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
        show: this.colorfull,
      },
      color: this.color,
      dataset: {
        source: this.data,
      },
      xAxis: {
        type: 'value',
        max: this.maxValueX ? this.maxValueX : undefined,
        axisLabel: {
          formatter: (value: number) =>
            this.useCurrency ? this.formatNumber(value) : value.toString(),
        },
      },
    };
  }

  formatNumber(value: number): string {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(2).replace('.', ',') + 'bi';
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(2).replace('.', ',') + 'mi';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2).replace('.', ',') + 'mil';
    } else {
      return value.toFixed(2).replace('.', ',');
    }
  }

  async imageOutput() {
    const chartContainer = document.createElement('div');
    chartContainer.style.width = '1000px';
    chartContainer.style.height = '600px';
    chartContainer.style.position = 'absolute';
    chartContainer.style.left = '-9999px';

    document.body.appendChild(chartContainer);

    echarts.registerTheme('meuTema', this.tema);

    const chart = echarts.init(chartContainer, 'meuTema');

    let auxOptions = this.options;
    auxOptions.legend = {
      orient: 'horizontal',
      bottom: 'bottom',
      show: this.colorfull,
    };
    auxOptions.color = this.color;
    auxOptions.dataset = {
      source: this.data,
    };
    auxOptions.animation = false;

    chart.setOption(auxOptions);

    const imageDataURL = chart.getDataURL({
      type: 'jpeg',
      pixelRatio: 2,
      backgroundColor: '#fff',
      excludeComponents: ['toolbox'],
    });

    document.body.removeChild(chartContainer);

    const imgWidth = chart.getWidth();
    const imgHeight = chart.getHeight();

    const chartImageObj = {
      dataURL: imageDataURL,
      imgWidth: imgWidth,
      imgHeight: imgHeight,
    };
    this.chartImage.emit(chartImageObj);
    return;
  }
}
