import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environment/environments';
import { Midia } from '../model/midia';

@Injectable({
  providedIn: 'root',
})
export class MidiaService {
  private apiUrl: string = environment.apiUrl;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  listarMidias(conteudo: number, filtroRequest: any): Observable<Midia[]> {
    let request = `${this.apiUrl}/midia/${conteudo}?`;

    if (filtroRequest != '') {
      request = request.concat(`${filtroRequest}`);
    }

    return this.http
      .get<Midia[]>(request, {
        headers: {
          authorization:
            (localStorage.getItem('tokenGrupo')
              ? localStorage.getItem('tokenGrupo')
              : localStorage.getItem('token')) ?? '',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  cancelarChamada() {
    this.ngUnsubscribe.next(); // Emitir um valor para cancelar a subscrição
  }
}
