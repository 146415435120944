import { Injectable } from '@angular/core';
import Cryptograf from 'crypto-js';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environment/environments';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject = new BehaviorSubject<any | null>(null);

  constructor(private tokenService: TokenService) {
    if (this.tokenService.possuiToken()) {
      this.decodificarJWT();
    }
  }

  private decodificarJWT() {
    const token = this.tokenService.retornarToken();
    const user = jwt_decode(token) as any;
    this.userSubject.next(user);
  }

  retornarUser() {
    return this.userSubject.asObservable();
  }

  salvarToken(token: string) {
    this.tokenService.salvarToken(token);
    this.decodificarJWT();
  }

  logout() {
    this.tokenService.excluirToken();
    this.userSubject.next(null);
  }

  estaLogado() {
    return this.tokenService.possuiToken();
  }

  isAdmin(): boolean {
    const bytes = Cryptograf.AES.decrypt(
      JSON.parse(localStorage.getItem('userType') as string),
      environment.cryptoKey
    );
    const usertype: boolean = JSON.parse(bytes.toString(Cryptograf.enc.Utf8));

    return usertype;
  }
}
