import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environment/environments';

Sentry.init({
  dsn: 'https://71dafc1fca9ca458dc40d2a0d976c437@o4507097851756544.ingest.us.sentry.io/4507097865912320',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://api.clipei.descubra.tech',
    'https://apidash.clipei.com.br',
    'https://dashboard.clipei.com.br',
    'https://cliente.clipei.com.br',
    'https://staging-cliente.clipei.com.br',
    'https://clipei.descubra.tech',
  ],
  environment: environment.sentryEnv,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
