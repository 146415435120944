import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';
import { AppRoutingModule } from './app-routing.module';

// Importações dos componentes
import { AppComponent } from './app.component';
import { NoSpaceDirective } from './no-space.directive';
import { HomeComponent } from './pages/home/home.component';
import { ImprensaComponent } from './pages/imprensa/imprensa.component';
import { JuridicoComponent } from './pages/juridico/juridico.component';
import { LoginComponent } from './pages/login/login.component';
import { BarChartLineChartComponent } from './shared/echarts/bar-chart-line-chart/bar-chart-line-chart.component';
import { DoughnutChartComponent } from './shared/echarts/doughnut-chart/doughnut-chart.component';
import { SubDoughnutChartComponent } from './shared/echarts/sub-doughnut-chart/sub-doughnut-chart.component';
import { WordCloudComponent } from './shared/echarts/word-cloud/word-cloud.component';

// Importações do Material

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ScrollingModule } from '@angular/cdk/scrolling';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { ColunasComponent } from './pages/colunas/colunas.component';
import { GraficosComponent } from './pages/graficos/graficos.component';
import { HomeAdminComponent } from './pages/home-admin/home-admin.component';
import { LegislativoComponent } from './pages/legislativo/legislativo.component';
import { DialogAudioComponent } from './shared/dialogs/dialog-audio/dialog-audio.component';
import { DialogImgComponent } from './shared/dialogs/dialog-img/dialog-img.component';
import { DialogPdfComponent } from './shared/dialogs/dialog-pdf/dialog-pdf.component';
import { DialogTranscricaoComponent } from './shared/dialogs/dialog-transcricao/dialog-transcricao.component';
import { DialogVideoComponent } from './shared/dialogs/dialog-video/dialog-video.component';
import { BarChartComponent } from './shared/echarts/bar-chart/bar-chart.component';
import { StackedBarChartComponent } from './shared/echarts/stacked-bar-chart/stacked-bar-chart.component';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { AuthInterceptor } from './core/guard/auth.interceptor';
import { SharedService } from './core/services/shared.service';
import { ComunidadeComponent } from './pages/comunidade/comunidade.component';
import { EsqueciSenhaComponent } from './pages/esqueci-senha/esqueci-senha.component';
import { SolicitarRedefinicaoSenhaComponent } from './pages/solicitar-redefinicao-senha/solicitar-redefinicao-senha.component';
import { TrocarSenhaComponent } from './pages/trocar-senha/trocar-senha.component';
import { VerificacaoIdentidadeComponent } from './pages/verificacao-identidade/verificacao-identidade.component';
import { GaugeChartComponent } from './shared/echarts/gauge-chart/gauge-chart.component';
import { TabelaComponent } from './shared/tabela/tabela.component';

class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    if (style === 'long') {
      return [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ];
    } else if (style === 'short') {
      return ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    } else {
      return ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    }
  }
}

const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    JuridicoComponent,
    ImprensaComponent,
    WordCloudComponent,
    DoughnutChartComponent,
    SubDoughnutChartComponent,
    BarChartLineChartComponent,
    BarChartComponent,
    StackedBarChartComponent,
    LegislativoComponent,
    NoSpaceDirective,
    DialogAudioComponent,
    DialogVideoComponent,
    DialogPdfComponent,
    DialogTranscricaoComponent,
    DialogImgComponent,
    HomeAdminComponent,
    ColunasComponent,
    GraficosComponent,
    TrocarSenhaComponent,
    EsqueciSenhaComponent,
    SolicitarRedefinicaoSenhaComponent,
    GaugeChartComponent,
    ComunidadeComponent,
    TabelaComponent,
    VerificacaoIdentidadeComponent,
  ],
  imports: [
    ScrollingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgIf,
    NgFor,
    AsyncPipe,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    // Importações do Material
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatGridListModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatDialogModule,
    MatSliderModule,
    MatCheckboxModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pt-BR',
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    SharedService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
