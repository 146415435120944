<div class="tela">
  <mat-drawer-container class="drawer-container">
    <mat-drawer #drawer class="drawer" mode="side" [opened]="true">
      <div class="container-drawer">
        <div class="div-logo-clipei">
          <img
            class="logo-clipei"
            src="../../../assets/logo_clipei.png"
            alt=""
          />
        </div>
        <hr class="divider" />

        <mat-button-toggle-group
          [formControl]="relatorio"
          (change)="navigateTo()"
        >
          <mat-button-toggle
            [checked]="navegacao == 4 ? true : false"
            value="admin/colunas"
          >
            <mat-icon matPrefix class="std_gray">view_column</mat-icon>
            Gerir Colunas
          </mat-button-toggle>

          <mat-button-toggle
            [checked]="navegacao == 5 ? true : false"
            value="admin/graficos"
          >
            <mat-icon matPrefix class="std_gray">query_stats</mat-icon>
            Gerir Gráficos
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <img
        src="../../../assets/logo_descubra.svg"
        class="logo-descubra"
        alt=""
      />
    </mat-drawer>

    <mat-toolbar class="toolbar" color="primary">
      <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <!---Adiciona um espaço flexível para empurrar os elementos para a direita--->
      <span class="flex-space"></span>

      <div class="user-profile">
        <div class="user-avatar">
          <!---Insira a imagem do usuário aqui--->
          <img src="../../../assets/pessoa_icone.svg" alt="Imagem do usuário" />
          <!---<img *ngIf="avatar != 'null'"  src={{avatar}} alt="" />
          <img *ngIf="avatar == 'null'"  src="../../../assets/pessoa_icone.svg" alt="" />--->
        </div>
        <div class="user-info">
          <span>{{ usuario }}</span>
          <mat-icon [matMenuTriggerFor]="userMenu">arrow_drop_down</mat-icon>
        </div>
      </div>
    </mat-toolbar>

    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="deslogar()">Sair</button>
      <button mat-menu-item (click)="trocarSenha()" *ngIf="admin">
        Alterar Senha
      </button>
      <button mat-menu-item (click)="paineis()" *ngIf="admin">
        Paineis de Visualização
      </button>
    </mat-menu>

    <router-outlet></router-outlet>
  </mat-drawer-container>
</div>
