import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { captureException } from '@sentry/angular-ivy';
import Cryptograf from 'crypto-js';
import { EMPTY, Observable, Subject, catchError, takeUntil } from 'rxjs';
import { environment } from 'src/environment/environments';

@Injectable({
  providedIn: 'root',
})
export class DashboardJuridicoService {
  private apiUrl: string = environment.apiUrl;
  private usertype: boolean = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  sentimentoPorCategoria(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/categoria/2?${filterRequest}`,
        {
          headers: {
            authorization:
              (localStorage.getItem('tokenGrupo')
                ? localStorage.getItem('tokenGrupo')
                : localStorage.getItem('token')) ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  noticiaPorSentimento(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/2?${filterRequest}`, {
        headers: {
          authorization:
            (localStorage.getItem('tokenGrupo')
              ? localStorage.getItem('tokenGrupo')
              : localStorage.getItem('token')) ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  nuvemDePalavras(filtrado: boolean, filterRequest?: string): Observable<any> {
    let bytes = Cryptograf.AES.decrypt(
      JSON.parse(localStorage.getItem('userType') as string),
      environment.cryptoKey
    );
    this.usertype = JSON.parse(bytes.toString(Cryptograf.enc.Utf8));

    if (this.usertype && !filtrado && !localStorage.getItem('tokenGrupo')) {
      return EMPTY;
    } else {
      return this.http
        .get<any>(`${this.apiUrl}/clipping/count/words/2?${filterRequest}`, {
          headers: {
            authorization:
              (localStorage.getItem('tokenGrupo')
                ? localStorage.getItem('tokenGrupo')
                : localStorage.getItem('token')) ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          catchError((error) => {
            throw error;
          })
        );
    }
  }

  listarTabela(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/clipping/paginate/${filterRequest}`, {
        headers: {
          authorization:
            (localStorage.getItem('tokenGrupo')
              ? localStorage.getItem('tokenGrupo')
              : localStorage.getItem('token')) ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  sentimentoPorTempo(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/temporal/2?${filterRequest}`, {
        headers: {
          authorization:
            (localStorage.getItem('tokenGrupo')
              ? localStorage.getItem('tokenGrupo')
              : localStorage.getItem('token')) ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  noticiaPorSubCategoria(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/subcategoria/2?${filterRequest}`,
        {
          headers: {
            authorization:
              (localStorage.getItem('tokenGrupo')
                ? localStorage.getItem('tokenGrupo')
                : localStorage.getItem('token')) ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  noticiaPorSentimentoDiario(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/diario/2?${filterRequest}`, {
        headers: {
          authorization:
            (localStorage.getItem('tokenGrupo')
              ? localStorage.getItem('tokenGrupo')
              : localStorage.getItem('token')) ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  orgaosPorTempo(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/clipping/count/orgao/temporal/2?${filterRequest}`,
        {
          headers: {
            authorization:
              (localStorage.getItem('tokenGrupo')
                ? localStorage.getItem('tokenGrupo')
                : localStorage.getItem('token')) ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  sentimentoPorOrgao(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/orgaos/2?${filterRequest}`, {
        headers: {
          authorization:
            (localStorage.getItem('tokenGrupo')
              ? localStorage.getItem('tokenGrupo')
              : localStorage.getItem('token')) ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  async gerarCSV(filterRequest?: string): Promise<any> {
    try {
      const data: Blob = await new Promise<Blob>((resolve, reject) => {
        this.http
          .get(`${this.apiUrl}/clipping/download-csv/2?${filterRequest}`, {
            headers: {
              authorization:
                (localStorage.getItem('tokenGrupo')
                  ? localStorage.getItem('tokenGrupo')
                  : localStorage.getItem('token')) ?? '',
              'Access-Control-Allow-Origin': '*',
            },
            responseType: 'blob',
          })
          .subscribe({
            next: (response: Blob) => {
              resolve(response);
            },
            error: (error: HttpErrorResponse) => {
              captureException(error);
              reject(error);
            },
          });
      }).catch(() => {
        return new Blob();
      });

      const blob = new Blob([data as Blob], { type: 'text/csv' });
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `Relatorio_Juridico:${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}-${new Date().getHours()}h${new Date().getMinutes()}.csv`;
      link.click();
      window.URL.revokeObjectURL(downloadURL);

      return 'ok';
    } catch (error) {
      throw error;
    }
  }

  async gerarPlanilha(filterRequest?: string): Promise<any> {
    try {
      const data: Blob = await new Promise<Blob>((resolve, reject) => {
        this.http
          .get(`${this.apiUrl}/clipping/download-xls/2?${filterRequest}`, {
            headers: {
              authorization:
                (localStorage.getItem('tokenGrupo')
                  ? localStorage.getItem('tokenGrupo')
                  : localStorage.getItem('token')) ?? '',
              'Access-Control-Allow-Origin': '*',
            },
            responseType: 'blob',
          })
          .subscribe({
            next: (response: Blob) => {
              resolve(response);
            },
            error: (error: HttpErrorResponse) => {
              captureException(error);
              reject(error);
            },
          });
      }).catch(() => {
        return new Blob();
      });

      const blob = new Blob([data as Blob], { type: '' });
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `Relatorio_Juridico:${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}-${new Date().getHours()}h${new Date().getMinutes()}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(downloadURL);

      return 'ok';
    } catch (error) {
      throw error;
    }
  }

  cancelarChamada() {
    this.ngUnsubscribe.next(); // Emitir um valor para cancelar a subscrição
  }
}
