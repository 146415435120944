<h1>Tabela de dados</h1>

<mat-card *ngIf="carregandoTabela" class="card-tabela">
  <mat-card-content class="content">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="tabelaVazia && !carregandoTabela" class="card-tabela">
  <mat-card-content class="content">
    <b>Não há dados para exibir</b>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="erroTabela && !carregandoTabela" class="card-tabela">
  <mat-card-content class="error-container">
    <b>Ocorreu um erro ao carregar os dados</b>
    <button
      mat-raised-button
      (click)="recarregarTabela()"
      class="button-reload"
      color="primary"
    >
      Recarregar Dados
    </button>
  </mat-card-content>
</mat-card>

<mat-card
  *ngIf="!carregandoTabela && !tabelaVazia && !erroTabela"
  class="card-tabela"
>
  <div style="padding: 10px">
    <button
      mat-raised-button
      (click)="gerarCSV()"
      class="generate-button"
      color="primary"
    >
      Gerar CSV
    </button>
    <button
      mat-raised-button
      (click)="gerarPlanilha()"
      class="generate-button"
      color="primary"
    >
      Gerar planilha
    </button>
  </div>

  <mat-card-content style="height: fit-content">
    <div class="mat-elevation-z8">
      <section class="table-container mat-elevation-z8" tabindex="0">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="table-container"
        >
          <!---ID Column--->

          <ng-container
            [matColumnDef]="column"
            *ngFor="let column of displayedColumns"
          >
            <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
            <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
          </ng-container>

          <!---arquivo Column--->
          <ng-container matColumnDef="arquivo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivo</th>
            <td mat-cell *matCellDef="let row">
              <button
                mat-button
                *ngIf="
                  isArqNull(row.arquivo) &&
                  !isArqAndLinkNull(row.arquivo, row.link)
                "
                (click)="OpenLinkInNewTab(row.link)"
              >
                <mat-icon>link</mat-icon>
              </button>

              <b *ngIf="isArqAndLinkNull(row.arquivo, row.link)">
                Não há arquivo
              </b>

              <button
                mat-button
                *ngIf="isAudioFile(row.arquivo)"
                (click)="
                  openDialogAudio(
                    conteudo,
                    row.keywords,
                    row['Mídia'],
                    row.link,
                    row['Data'],
                    row['Veículo'] || row['Órgão'] || row['Encontro'],
                    row['Título'],
                    row.arquivo,
                    row.transcricao,
                    row['Programa'] || row['Sessão'] || row['Reuniões'],
                    row['Classificação'],
                    row['Autoridade'] || row['Liderança'],
                    row['Partido']
                  )
                "
              >
                <mat-icon>audiotrack</mat-icon>
              </button>

              <button
                mat-button
                *ngIf="isPdfFile(row.arquivo)"
                (click)="openDialogPdf(row.arquivo)"
              >
                <mat-icon>picture_as_pdf</mat-icon>
              </button>

              <button
                mat-button
                *ngIf="isImageFile(row.arquivo)"
                (click)="
                  openDialogImg(
                    conteudo,
                    row.keywords,
                    row['Mídia'],
                    row.link,
                    row['Data'],
                    row['Veículo'] || row['Órgão'] || row['Encontro'],
                    row['Título'],
                    row.arquivo,
                    row.transcricao,
                    row['Programa'] || row['Sessão'] || row['Reuniões'],
                    row['Classificação'],
                    row['Autoridade'] || row['Liderança'],
                    row['Partido']
                  )
                "
              >
                <mat-icon>image</mat-icon>
              </button>

              <button
                mat-button
                *ngIf="isVideoFile(row.arquivo)"
                (click)="
                  openDialogVideo(
                    conteudo,
                    row.keywords,
                    row['Mídia'],
                    row.link,
                    row['Data'],
                    row['Veículo'] || row['Órgão'] || row['Encontro'],
                    row['Título'],
                    row.arquivo,
                    row.transcricao,
                    row['Programa'] || row['Sessão'] || row['Reuniões'],
                    row['Classificação'],
                    row['Autoridade'] || row['Liderança'],
                    row['Partido']
                  )
                "
              >
                <mat-icon>ondemand_video</mat-icon>
              </button>

              <button
                mat-button
                *ngIf="conteudo !== 4 && isTextFile(row.arquivo)"
                (click)="
                  openDialogTranscricao(
                    conteudo,
                    row.keywords,
                    row['Mídia'],
                    row.link,
                    row['Data'],
                    row['Veículo'] || row['Órgão'] || row['Encontro'],
                    row['Título'],
                    row.transcricao,
                    row['Programa'] || row['Sessão'] || row['Reuniões'],
                    row['Classificação'],
                    row['Autoridade'] || row['Liderança'],
                    row['Partido']
                  )
                "
              >
                <mat-icon>article</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns.concat('arquivo')"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns.concat('arquivo')"
          ></tr>

          <!---Row shown when there is no matching data.--->
          <tr class="mat-row" *matNoDataRow></tr>
        </table>

        <mat-paginator
          [pageSizeOptions]="[10, 50, 100]"
          (page)="eventosTabela($event)"
          [length]="length"
          [pageSize]="rowsPerPage"
          [pageIndex]="paginaAtual - 1"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>
      </section>
    </div>
  </mat-card-content>
</mat-card>
