import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import type { Color, EChartsOption } from 'echarts';
import 'echarts-wordcloud';
import * as echarts from 'echarts/core';
import { EChartTheme } from 'src/assets/echarts-theme';

interface WordCloudData {
  name: string;
  value: number;
}
@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss'],
})
export class WordCloudComponent implements OnInit {
  @Input() data: WordCloudData[] = [];
  // As cores são passadas como um array de strings
  @Input() color: Color[] = [];

  @Input() title: string = '';

  @Input() hasCentimetragem: boolean = true;

  @Output() chartImage = new EventEmitter<{
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }>();

  options: EChartsOption = {};
  mergeOptions: EChartsOption = {};
  tema: EChartsOption = EChartTheme.theme;

  constructor() {}

  ngOnInit(): void {
    this.options = {
      animationDelay: () => 0,
      animationDuration: 0,
      title: {
        text: this.title,
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            title: 'Salvar',
            pixelRatio: 4,
          },
        },
      },
      visualMap: {
        show: false,
        min: 0,
        max: 30000,
        calculable: true,
        inRange: {
          color: this.tema['visualMapColor'],
        },
      },
      series: [
        {
          type: 'wordCloud',
          gridSize: 2,
          sizeRange: this.hasCentimetragem ? [12, 50] : [15, 60],
          rotationRange: [-90, 90],
          shape: 'circle',
          width: 600,
          height: 400,
          drawOutOfBound: true,
          data: this.data,
        },
      ],
    };
  }

  ngOnChanges(): void {
    this.alterarDados();
  }

  async alterarDados() {
    this.mergeOptions = {
      visualMap: {
        show: false,
        min: 0,
        max: this.data[0].value,
        calculable: true,
        inRange: {
          color: this.tema['visualMapColor'],
        },
      },
      series: [
        {
          type: 'wordCloud',
          gridSize: 2,
          sizeRange: this.hasCentimetragem ? [12, 50] : [15, 60],
          rotationRange: [-90, 90],
          shape: 'circle',
          width: this.hasCentimetragem ? 600 : 1000,
          height: 400,
          drawOutOfBound: true,
          data: this.data,
        },
      ],
    };
  }

  async imageOutput(): Promise<void> {
    return new Promise(async (resolve) => {
      const chartContainer = document.createElement('div');
      chartContainer.style.width = '1000px';
      chartContainer.style.height = '600px';
      chartContainer.style.position = 'absolute';
      chartContainer.style.left = '-9999px';

      document.body.appendChild(chartContainer);

      echarts.registerTheme('meuTema', this.tema);

      const chart = echarts.init(chartContainer, 'meuTema');

      let auxOptions = this.options;

      auxOptions.visualMap = {
        show: false,
        min: 0,
        max: this.data[0].value,
        calculable: true,
        inRange: {
          color: this.tema['visualMapColor'],
        },
      };

      auxOptions.series = [
        {
          type: 'wordCloud',
          gridSize: 7,
          sizeRange: [12, 60],
          rotationRange: [-90, 90],
          shape: 'circle',
          width: 570,
          height: 400,
          drawOutOfBound: true,
          data: this.data,
        },
      ];

      auxOptions.animation = false;

      chart.setOption(auxOptions);

      setTimeout(() => {
        const imageDataURL = chart.getDataURL({
          type: 'jpeg', // Use 'jpeg' para reduzir o tamanho
          pixelRatio: 2, // Reduza o pixelRatio
          backgroundColor: '#fff',
          excludeComponents: ['toolbox'],
        });

        const imgWidth = chart.getWidth();
        const imgHeight = chart.getHeight();

        const chartImageObj = {
          dataURL: imageDataURL,
          imgWidth: imgWidth,
          imgHeight: imgHeight,
        };

        document.body.removeChild(chartContainer);
        this.chartImage.emit(chartImageObj);
        resolve();
      }, 1000);
    });
  }
}
