import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environment/environments';

@Injectable({
  providedIn: 'root',
})
export class ColunasService {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  listarColunas(grupo: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/colunas/?id_grupo=${grupo}`, {
      headers: {
        authorization: localStorage.getItem('token') ?? '',
      },
    });
  }

  salvarColuna(coluna: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/colunas`, coluna, {
      headers: {
        authorization: localStorage.getItem('token') ?? '',
      },
    });
  }

  // deletarColuna(ids: {ids: Array<number>}): Observable<any> {
  //   return this.http.delete<any>(`${this.apiUrl}/colunas`, {
  //     headers: {
  //       authorization: localStorage.getItem('token') ?? '',
  //     },
  //     body: ids,
  //   });
  // }
}
