import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environment/environments';
import { Grupo } from '../model/grupo';

@Injectable({
  providedIn: 'root',
})
export class PartidoService {
  private apiUrl: string = environment.apiUrl;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  listarPartidos(
    conteudo: number,
    filtroRequest: string = ''
  ): Observable<Grupo[]> {
    let request = `${this.apiUrl}/partido/${conteudo}?`;

    if (filtroRequest != '') {
      request = request.concat(`${filtroRequest}`);
    }

    return this.http
      .get<Grupo[]>(request, {
        headers: {
          authorization:
            (localStorage.getItem('tokenGrupo')
              ? localStorage.getItem('tokenGrupo')
              : localStorage.getItem('token')) ?? '',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  cancelarChamada() {
    this.ngUnsubscribe.next(); // Emitir um valor para cancelar a subscrição
  }
}
