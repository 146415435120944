<div class="dialog-container">
  <p class="dialog-title">Visualização de Arquivo</p>
  <div class="divider"></div>
  <div class="dialog-content">
    <p><strong>Data:</strong> {{ data.data }}</p>

    <div *ngIf="data.conteudo == 1">
      <p><strong>Veículo:</strong> {{ data.veiculo }}</p>
      <p><strong>Programa:</strong> {{ data.programa }}</p>
    </div>

    <div *ngIf="data.conteudo == 2 || data.conteudo == 3">
      <p><strong>Órgão:</strong> {{ data.veiculo }}</p>
      <p><strong>Sessão:</strong> {{ data.programa }}</p>
    </div>

    <div *ngIf="data.conteudo == 3">
      <p><strong>Autoridade:</strong> {{ data.autoridade }}</p>
      <p><strong>Partido:</strong> {{ data.partido }}</p>
    </div>

    <div *ngIf="data.conteudo == 4">
      <p><strong>Encontro:</strong> {{ data.veiculo }}</p>
      <p><strong>Reuniões:</strong> {{ data.programa }}</p>
      <p><strong>Liderança:</strong> {{ data.autoridade }}</p>
    </div>

    <p><strong>Título:</strong> {{ data.titulo }}</p>
    <p><strong>Classificação:</strong> {{ data.classificacao }}</p>

    <button
      mat-raised-button
      *ngIf="data.midia && data.midia == 'Site' && data.link"
      color="primary"
      (click)="acessar()"
    >
      Acessar site
    </button>

    <hr class="divider-line" />
    <audio controls style="width: 100%">
      <source src="{{ audio }}" type="audio/mp3" />
      Seu navegador não suporta o elemento de áudio.
    </audio>

    <div
      class="espacamento"
      *ngIf="data.transcricao != null && data.transcricao != ''"
    >
      <strong>Transcricao:</strong>
      <div class="transcription" [innerHTML]="formatarTexto()"></div>
    </div>
  </div>

  <button mat-raised-button color="primary" (click)="closeDialog()">
    Fechar
  </button>
</div>
