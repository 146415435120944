<div class="tela">
  <div class="half"></div>

  <div class="half centralize">
    <div class="form-login">
      <img class="logo-clipei" src="../../../assets/logo_clipei.png" alt="" />

      <form class="form-login" [formGroup]="loginForm">
        <mat-form-field appearance="outline" class="input-login">
          <mat-label>Usuário</mat-label>
          <input
            matInput
            formControlName="login"
            placeholder="Usuário"
            required="true"
          />
          <mat-icon matSuffix class="std_gray">person_outline</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-login">
          <mat-label>Senha</mat-label>
          <input
            matInput
            formControlName="senha"
            [type]="hidePassword ? 'password' : 'text'"
            placeholder="Senha"
            required="true"
          />
          <mat-icon matSuffix class="std_gray" (click)="visibilidadeSenha()">
            {{ hidePassword ? "visibility_off" : "visibility" }}
          </mat-icon>
        </mat-form-field>

        <button
          routerLink="/home"
          (click)="login()"
          mat-raised-button
          class="button-login"
          color="primary"
          [disabled]="!loginForm.valid"
        >
          Entrar
        </button>

        <mat-error *ngIf="erroLogin">{{ mensagemErro }}</mat-error>

        <p><a href="/solicitar-redefinicao-senha">Esqueci minha Senha</a></p>
      </form>
    </div>
  </div>
</div>
