import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { captureException } from '@sentry/angular-ivy';
import { Observable, map, startWith, take } from 'rxjs';
import { Empresa } from 'src/app/core/model/empresa';
import { Grupo } from 'src/app/core/model/grupo';
import { AutoridadeService } from 'src/app/core/services/autoridade.service';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { ColunasService } from 'src/app/core/services/colunas.service';
import { DashboardComunidadeService } from 'src/app/core/services/dashboard-comunidade.service';
import { DashboardImprensaService } from 'src/app/core/services/dashboard-imprensa.service';
import { DashboardJuridicoService } from 'src/app/core/services/dashboard-juridico.service';
import { DashboardLegislativoService } from 'src/app/core/services/dashboard-legislativo.service';
import { EmpresaService } from 'src/app/core/services/empresa.service';
import { GrupoService } from 'src/app/core/services/grupo.service';
import { HomeService } from 'src/app/core/services/home.service';
import { MidiaService } from 'src/app/core/services/midia.service';
import { PautaService } from 'src/app/core/services/pauta.service';
import { ProgramaService } from 'src/app/core/services/programa.service';
import { SentimentoService } from 'src/app/core/services/sentimento.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { SubGrupoService } from 'src/app/core/services/subgrupo.service';
import { TipoInformacaoService } from 'src/app/core/services/tipo-informacao.service';
import { VeiculoService } from 'src/app/core/services/veiculo.service';
import Swal from 'sweetalert2';

interface filterSelected {
  grupo: number;
}

interface coluna {
  col_id: number;
  col_nome: string;
  id_conteudo: number;
  id_permissao: number;
  id_tipo_informacao: number;
  permissao: boolean;
}

@Component({
  selector: 'app-colunas',
  templateUrl: './colunas.component.html',
  styleUrls: ['./colunas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColunasComponent implements OnInit {
  @ViewChild('completeGrupo') completeGrupo: MatSelect = <any>{};
  @ViewChild('completeEmpresa') completeEmpresa: MatSelect = <any>{};

  grupos: Array<Grupo> = [];
  empresas: Array<Empresa> = [];

  filteredOptionsGrupos?: Observable<Grupo[]>;
  filteredOptionsEmpresas?: Observable<Empresa[]>;

  colunas = {
    imprensa1: [] as coluna[],
    juridico1: [] as coluna[],
    sessoes1: [] as coluna[],
    comunidades1: [] as coluna[],
    imprensa2: [] as coluna[],
    juridico2: [] as coluna[],
    sessoes2: [] as coluna[],
    comunidades2: [] as coluna[],
  };

  colunasAlteradas = [] as coluna[];

  hasSelecao: boolean = false;
  selectedGrupo?: number;
  salvando: boolean = false;
  // hasAlteracaoFiltroGrupo: boolean = false;
  // hasAlteracaoFiltroEmpresa: boolean = false;
  carregandoFiltros: boolean = false;
  erroCarregamentoFiltros: boolean = false;
  carregandoColunas: boolean = false;
  erroCarregamentoColunas: boolean = false;

  filterSelected: filterSelected = {} as filterSelected;
  filterTrigger: filterSelected = {} as filterSelected;

  filterForm = new FormGroup({
    grupos: new FormControl(),
    empresas: new FormControl(),
    searchTextboxGrupo: new FormControl(''),
    searchTextboxEmpresa: new FormControl(''),
  });

  constructor(
    private cdr: ChangeDetectorRef,
    private colunasService: ColunasService,
    private homeService: HomeService,
    private _sharedService: SharedService,

    // dashboards
    private dashboardComunidadeService: DashboardComunidadeService,
    private dashboardImprensaService: DashboardImprensaService,
    private dashboardJuridicoService: DashboardJuridicoService,
    private dashboardLegislativoService: DashboardLegislativoService,

    // filtros
    private grupoService: GrupoService,
    private tipoInformacaoService: TipoInformacaoService,
    private empresaService: EmpresaService,
    private midiaService: MidiaService,
    private veiculoService: VeiculoService,
    private categoriaService: CategoriaService,
    private sentimentoService: SentimentoService,
    private autoridadeService: AutoridadeService,
    private subGrupoService: SubGrupoService,
    private programaService: ProgramaService,
    private pautaService: PautaService
  ) {
    // o código abaixo registra as alterações feitas no componente pai
    // e chama a função modalConfirmation() para o usuário poder salvar as alterações
    _sharedService.changeEmitted$.pipe(take(2)).subscribe((change) => {
      // as condições abaixo verificam se houve alteração e se o usuário já está salvando as informações
      if (change && this.colunasAlteradas.length > 0 && !this.salvando) {
        this._sharedService.emitChange(false);
        this.modalConfirmation();
      }
    });
  }

  ngOnInit(): void {
    this.homeService.enviarVariavel(4);
    this.dashboardComunidadeService.cancelarChamada();
    this.dashboardImprensaService.cancelarChamada();
    this.dashboardJuridicoService.cancelarChamada();
    this.dashboardLegislativoService.cancelarChamada();
    this.cancelarCarregamentoFiltros();

    Promise.all([this.listar_grupos()]).then(() => {
      this.enableFiltrosFunc();
    });
  }

  listarColunas() {
    this.carregandoColunas = true;
    this.erroCarregamentoColunas = false;

    this.colunas = {
      imprensa1: [] as coluna[],
      juridico1: [] as coluna[],
      sessoes1: [] as coluna[],
      comunidades1: [] as coluna[],
      imprensa2: [] as coluna[],
      juridico2: [] as coluna[],
      sessoes2: [] as coluna[],
      comunidades2: [] as coluna[],
    };

    this.colunasService.listarColunas(this.filterSelected.grupo).subscribe({
      next: (value: Array<coluna>) => {
        value.forEach((coluna: coluna) => {
          // Lookup para identificar a coluna que foi retornada no response
          const tipoMap: {
            [key: number]: {
              [key: number]: coluna[];
            };
          } = {
            1: {
              1: this.colunas.imprensa1,
              2: this.colunas.juridico1,
              3: this.colunas.sessoes1,
              4: this.colunas.comunidades1,
            },
            2: {
              1: this.colunas.imprensa2,
              2: this.colunas.juridico2,
              3: this.colunas.sessoes2,
              4: this.colunas.comunidades2,
            },
          };

          const targetArray =
            tipoMap[coluna.id_tipo_informacao]?.[coluna.id_conteudo];
          if (targetArray) {
            targetArray.push(coluna);
          }
        });

        this.hasSelecao = true;
        this.carregandoColunas = false;
        this.cdr.detectChanges();
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.carregandoColunas = false;
        this.erroCarregamentoColunas = true;
        this.cdr.detectChanges();

        if (error.status === 401) {
          Swal.fire({
            title: 'Sessão expirada',
            icon: 'error',
            text: 'Por favor, faça login novamente',
            confirmButtonColor: 'orange',
          });
        } else {
          Swal.fire({
            title: 'Houve um erro ao carregar as colunas',
            icon: 'error',
            text: 'Por favor, tente novamente',
            confirmButtonColor: 'orange',
          });
        }
      },
    });
  }

  alteraColunas(event: boolean, coluna: coluna) {
    if (event) {
      coluna.permissao = true;
    } else {
      coluna.permissao = false;
    }

    if (this.colunasAlteradas.find((element) => element == coluna)) {
      this.colunasAlteradas.splice(this.colunasAlteradas.indexOf(coluna), 1);
    } else {
      this.colunasAlteradas.push(coluna);
    }
  }

  salvarColunas() {
    this.salvando = true;

    const colunasAdicionar = this.colunasAlteradas.filter(
      (coluna) => coluna.permissao == true
    );

    const colunasRemover = this.colunasAlteradas.filter(
      (coluna) => coluna.permissao == false
    );

    const colunasIdsRemover: Array<number> = [];

    colunasRemover.forEach((coluna) => {
      colunasIdsRemover.push(coluna.id_permissao);
    });

    const colunasFormatAdicionar: {
      id_coluna: Array<number>;
      id_conteudo: Array<number>;
      id_grupo: Array<number>;
      id_tipo_informacao: Array<number>;
      ids: Array<number>;
    } = {
      id_coluna: [],
      id_conteudo: [],
      id_grupo: [],
      id_tipo_informacao: [],
      ids: colunasIdsRemover,
    };
    colunasAdicionar.forEach((coluna) => {
      colunasFormatAdicionar.id_coluna.push(coluna.col_id);
      colunasFormatAdicionar.id_conteudo.push(coluna.id_conteudo);
      colunasFormatAdicionar.id_grupo.push(this.filterSelected.grupo);
      colunasFormatAdicionar.id_tipo_informacao.push(coluna.id_tipo_informacao);
    });

    this.colunasService.salvarColuna(colunasFormatAdicionar).subscribe({
      next: (response) => {
        if (response.ids && response.ids.length > 0) {
          let obj: coluna | undefined;

          // Lookup para identificar a coluna que foi retornada no response
          const lookup: {
            [key: number]: {
              [key: number]: coluna[];
            };
          } = {
            1: {
              1: this.colunas.imprensa1,
              2: this.colunas.juridico1,
              3: this.colunas.sessoes1,
              4: this.colunas.comunidades1,
            },
            2: {
              1: this.colunas.imprensa2,
              2: this.colunas.juridico2,
              3: this.colunas.sessoes2,
              4: this.colunas.comunidades2,
            },
          };

          for (let i = 0; i < response.ids.length; i++) {
            const tipo = response.id_tipo_informacao[i];
            const conteudo = response.id_conteudo[i];
            const colunas = lookup[tipo]?.[conteudo];

            if (colunas) {
              obj = colunas.find(
                (item) => item.col_id === response.id_coluna[i]
              );
              if (obj) obj.id_permissao = response.ids[i];
            }
          }
          this.cdr.detectChanges();
        }

        Swal.fire({
          title: 'Alterações salvas com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        });
        // Emitir a mudança para o componente pai dizendo que já pode trocar de rota
        this._sharedService.emitChange(true);
        this.colunasAlteradas = [];
        this.salvando = false;
        this.cdr.detectChanges();
      },
      error: () => {
        Swal.fire({
          title: 'Erro ao salvar alterações!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        this.salvando = false;
        this.cdr.detectChanges();
      },
    });
  }

  async addFiltroSelect(event: any, nome: keyof filterSelected, id: any) {
    if (this.colunasAlteradas.length > 0) {
      this.modalConfirmation();
    }

    // SE NÃO FOR UMA AÇÃO DO USUÁRIO, NÃO FAZER NADA
    if (event.isUserInput == false) return;

    // SE O FILTRO FOR O DE CATEGORIA
    if (event.source.selected == true) {
      this.filterSelected[nome] = id;
    }

    this.listarColunas();
  }

  modalConfirmation() {
    Swal.fire({
      title: `Deseja salvar as alterações feitas no grupo ${this.selectedGrupo}`,
      showDenyButton: true,
      confirmButtonText: 'Salvar',
      denyButtonText: 'Não salvar',
      confirmButtonColor: 'green',
      denyButtonColor: '#d33',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.salvando = true;
        this.salvarColunas();
      } else if (result.isDenied) {
        this.colunasAlteradas = [];
        // Emitir a mudança para o componente pai dizendo que já pode trocar de rota
        this._sharedService.emitChange(true);
      }
    });
  }

  criarStringRequest(grupo: boolean = false) {
    let request: string = '';

    if (grupo && this.filterSelected.grupo != undefined) {
      request = request.concat(`id_grupo=`);

      request = request.concat(`${this.filterSelected.grupo},`);

      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    // Remover o último '&', se existir
    if (request.endsWith('&')) {
      request = request.slice(0, -1);
    }

    return request;
  }

  // ----------------------------------Grupo----------------------------------------------

  filterGrupo(name: string): Grupo[] {
    const filterValue = name.toLowerCase();

    // if (this.filterTrigger.grupo) {
    //   this.filterForm.controls.grupos.patchValue(
    //     this.filterTrigger.grupo.split(', ')
    //   );
    // }

    let filteredList = this.grupos.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_grupos(filtroRequest: string = '') {
    this.disableFiltrosFunc();
    this.carregandoFiltros = true;
    this.erroCarregamentoFiltros = false;

    return new Promise((resolve, reject) => {
      this.grupoService.listarGrupos(filtroRequest).subscribe({
        next: (value) => {
          this.enableFiltrosFunc();
          this.carregandoFiltros = false;
          this.erroCarregamentoFiltros = false;

          value.forEach((element) => {
            this.grupos.push(element);
          });

          this.filteredOptionsGrupos =
            this.filterForm.controls.searchTextboxGrupo.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterGrupo(name || '')) // Handle null value by providing an empty string
            );

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          this.enableFiltrosFunc();
          this.erroCarregamentoFiltros = true;
          this.carregandoFiltros = false;

          Swal.fire({
            title: 'Houve um erro ao carregar os grupos',
            icon: 'error',
            text: 'Por favor, tente novamente',
            confirmButtonColor: 'orange',
          });

          captureException(error);
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      captureException(error);
      throw error;
    });
  }

  trackByColId(item: coluna): number {
    return item.col_id;
  }

  disableFiltrosFunc() {
    this.filterForm.controls.grupos.disable();
    this.filterForm.controls.empresas.disable();
  }

  enableFiltrosFunc() {
    this.filterForm.controls.grupos.enable();
    this.filterForm.controls.empresas.enable();
  }

  // ----------------------------------Cancelamento de promisses ----------------------------------------------
  cancelarCarregamentoFiltros() {
    this.tipoInformacaoService.cancelarChamada();
    this.grupoService.cancelarChamada();
    this.empresaService.cancelarChamada();
    this.midiaService.cancelarChamada();
    this.veiculoService.cancelarChamada();
    this.categoriaService.cancelarChamada();
    this.sentimentoService.cancelarChamada();
    this.autoridadeService.cancelarChamada();
    this.subGrupoService.cancelarChamada();
    this.programaService.cancelarChamada();
    this.pautaService.cancelarChamada();
    this.programaService.cancelarChamada();
  }
}
