import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { captureException } from '@sentry/angular-ivy';
import Cryptograf from 'crypto-js';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environment/environments';
import Swal from 'sweetalert2';

function mascararEmail(email: string): string {
  const [nomeUsuario, dominio] = email.split('@');
  const [dominioNome, dominioTld] = dominio.split('.');

  // Mascara parte do nome de usuário e do domínio
  const nomeMascarado =
    nomeUsuario.slice(0, 2) + '*'.repeat(nomeUsuario.length - 2);
  const dominioMascarado = dominioNome[0] + '*'.repeat(dominioNome.length - 1);

  return `${nomeMascarado}@${dominioMascarado}.${dominioTld}`;
}

@Component({
  selector: 'app-verificacao-identidade',
  templateUrl: './verificacao-identidade.component.html',
  styleUrls: ['./verificacao-identidade.component.scss'],
})
export class VerificacaoIdentidadeComponent implements OnInit {
  desativaBotao = true;
  isLoading = false;
  email: string = '';
  emailCriptografado: string = '';
  errorMessage: string = '';
  code: string | null = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.getTokenErrorMessage();
  }

  ngOnInit(): void {
    const emailCriptografado = Cryptograf.AES.decrypt(
      JSON.parse(localStorage.getItem('email') as string),
      environment.cryptoKey
    );
    this.email = JSON.parse(emailCriptografado.toString(Cryptograf.enc.Utf8));

    this.emailCriptografado = mascararEmail(this.email);

    this.route.paramMap.subscribe((params) => {
      this.code = params.get('code');
    });

    if (this.code) {
      this.codeForm.get('code')?.setValue(this.code);
    }
  }

  codeForm = new FormGroup({
    code: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9]{6}$/),
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
  });

  verificarIdentidade() {
    this.isLoading = true;
    const codigo = this.codeForm.get('code')?.value!;

    this.authService.verificarToken(codigo, this.email).subscribe({
      next: () => {
        this.isLoading = false;
        this.router.navigate(['/home']);
      },
      error: (error: HttpErrorResponse) => {
        this.desativaBotao = true;
        if (error.status === 404) {
          this.errorMessage = 'Token inválido.';
        } else {
          this.errorMessage =
            'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.';
          captureException(error);
        }
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: this.errorMessage,
          confirmButtonColor: 'orange',
        });
        this.isLoading = false;
        throw error;
      },
    });
  }

  getTokenErrorMessage() {
    if (this.codeForm.get('code')?.hasError('required')) {
      this.desativaBotao = true;
      return 'Código é obrigatório';
    } else if (
      this.codeForm.get('code')?.hasError('pattern') ||
      this.codeForm.get('code')?.hasError('maxlength') ||
      this.codeForm.get('code')?.hasError('minlength')
    ) {
      this.desativaBotao = true;
      return 'Código inválido';
    } else {
      this.desativaBotao = this.codeForm.get('code')?.invalid!;
      return '';
    }
  }
}
