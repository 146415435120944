import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environment/environments';
import { TipoInformacao } from '../model/tipoInformacao';

@Injectable({
  providedIn: 'root',
})
export class TipoInformacaoService {
  private apiUrl: string = environment.apiUrl;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  listarTiposInformacao(conteudo: number): Observable<TipoInformacao[]> {
    return this.http
      .get<TipoInformacao[]>(`${this.apiUrl}/tipo-informacao/${conteudo}`, {
        headers: {
          authorization:
            (localStorage.getItem('tokenGrupo')
              ? localStorage.getItem('tokenGrupo')
              : localStorage.getItem('token')) ?? '',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  cancelarChamada() {
    this.ngUnsubscribe.next(); // Emitir um valor para cancelar a subscrição
  }
}
