import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import Cryptograf from 'crypto-js';
import { Observable, map, startWith } from 'rxjs';
import { environment } from 'src/environment/environments';

import { Color } from 'echarts';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';

import { CategoriaService } from 'src/app/core/services/categoria.service';
import { DashboardImprensaService } from 'src/app/core/services/dashboard-imprensa.service';
import { EmpresaService } from 'src/app/core/services/empresa.service';
import { GrupoService } from 'src/app/core/services/grupo.service';
import { MidiaService } from 'src/app/core/services/midia.service';
import { PautaService } from 'src/app/core/services/pauta.service';
import { SentimentoService } from 'src/app/core/services/sentimento.service';
import { VeiculoService } from 'src/app/core/services/veiculo.service';

import { Categoria } from 'src/app/core/model/categoria';
import { Empresa } from 'src/app/core/model/empresa';
import { Grupo } from 'src/app/core/model/grupo';
import { Midia } from 'src/app/core/model/midia';
import { Pauta } from 'src/app/core/model/pauta';
import { Sentimento } from 'src/app/core/model/sentimento';
import { Veiculo } from 'src/app/core/model/veiculo';

import { SubCategoria } from 'src/app/core/model/subcategoria';
import { SubGrupo } from 'src/app/core/model/subgrupo';
import { TipoInformacao } from 'src/app/core/model/tipoInformacao';

import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { captureException } from '@sentry/angular-ivy';
import _moment from 'moment';
import { AutoridadeService } from 'src/app/core/services/autoridade.service';
import { DashboardComunidadeService } from 'src/app/core/services/dashboard-comunidade.service';
import { DashboardJuridicoService } from 'src/app/core/services/dashboard-juridico.service';
import { DashboardLegislativoService } from 'src/app/core/services/dashboard-legislativo.service';
import { HomeService } from 'src/app/core/services/home.service';
import { PartidoService } from 'src/app/core/services/partido.service';
import { SubGrupoService } from 'src/app/core/services/subgrupo.service';
import { TipoInformacaoService } from 'src/app/core/services/tipo-informacao.service';

interface filterSelected {
  categoria: any;
  subCategoria: any;
  empresa: any;
  grupo: any;
  subGrupo: any;
  tipoInformacao: any;
  sentimento: any;
  pauta: any;
  midia: any;
  veiculo: any;
  dataInicial: any;
  dataFinal: any;
  titulo: any;
}
interface MesesAssociados {
  [numero: number]: string;
}

interface chartData {
  dataURL: string;
  imgWidth: number;
  imgHeight: number;
}

interface ISentimento {
  sentimento: string;
  quantidade: number;
}

@Component({
  selector: 'app-imprensa',
  templateUrl: './imprensa.component.html',
  styleUrls: ['./imprensa.component.scss'],
})
export class ImprensaComponent implements OnInit {
  moment = _moment;

  //----------------------------------Variaveis de dados dos gráficos------------------------------------

  @ViewChildren('charts') charts: QueryList<any> = <any>[];
  chartDataPDF: chartData = <any>{}; //variavel que vai receber o dataURL do gráfico e o tamanho

  permissaoSuperintendencia = false;
  permissionCharts: Array<string> = [];

  dataNoticiaPorSentimento: Array<any> | null = null;
  dataNoticiaGauge: number | null = null;
  hasNoticiasGauche: boolean = true;

  dataNoticiaPorMidia: Array<any> | null = null;
  dataNoticiaPorVeiculo: { veiculos: [] } | null = null;
  dataCentrimetragemPorMidiaPositiva: Array<any> | null = null;
  dataCentrimetragemPorMidiaNeutra: Array<any> | null = null;
  dataCentrimetragemPorVeiculoPositiva: Array<any> | null = null;
  dataCentrimetragemPorVeiculoNeutra: Array<any> | null = null;
  maxValueCentimetragem: number = 0;
  dataSentimentoPorCategoria: { categorias: [] } | null = null;
  dataSentimentoPorDuracao: { midias: [] } | null = null;
  dataSentimentoPorEmpresa: {
    empresas: string[];
    quantidades: ISentimento[];
    colors: string[];
  } | null = null;
  qtdGrupoSentimentoPorEmpresa: boolean = true;
  qtdEmpresaSentimentoPorEmpresa: boolean = false;
  dataNoticiaPorSubCategoria: { subcategorias: [] } | null = null;
  dataNoticiaPorPauta: Array<any> | null = null;
  sentimentoSentimentoPorTempo: Array<any> | null = null;
  // dataSentimentoPorTempo: Array<any> | null = null;
  dataSentimentoPorTempoMes: {
    meses: [];
    quantidades: {
      sentimento: string;
      quantidade: number[];
    }[];
  } | null = null;
  midiaMidiaPorTempo: Array<any> | null = null;
  // dataMidiaPorTempo: Array<any> | null = null;
  dataMidiaPorTempoMes: {
    meses: [];
    quantidades: {
      midia: string;
      quantidade: number[];
    }[];
  } | null = null;
  dataNuvemDePalavras: Array<any> | null = null;
  dataNoticiaPorSentimentoDiario: { datas: [] } | null = null;
  dataDetratores: Array<any> | null = null;
  dataEvangelizadores: Array<any> | null = null;

  erroNoticiaPorSentimento: boolean = false;
  erroNoticiaPorSentimentoDiario: boolean = false;
  erroNoticiaPorMidia: boolean = false;
  erroNoticiaPorVeiculo: boolean = false;
  erroCentrimetragemPorMidiaPositiva: boolean = false;
  erroCentrimetragemPorMidiaNeutra: boolean = false;
  erroCentrimetragemPorVeiculoPositiva: boolean = false;
  erroCentrimetragemPorVeiculoNeutra: boolean = false;

  erroNoticiaPorCategoria: boolean = false;
  erroNoticiaPorDuracao: boolean = false;
  erroNoticiaPorEmpresa: boolean = false;
  erroNoticiaPorSubCategoria: boolean = false;
  erroNoticiaPorPauta: boolean = false;
  // erroSentimentoPorTempo: boolean = false;
  erroSentimentoPorTempoMes: boolean = false;
  // erroMidiaPorTempo: boolean = false;
  erroMidiaPorTempoMes: boolean = false;
  erroNuvemDePalavras: boolean = false;
  erroDetratores: boolean = false;
  erroEvangelizadores: boolean = false;

  coresSentimento = ['#77C4A8', '#F5C869', '#DD6B7F'];
  colorSentimento = <any>[];
  colorSentimentoTempo = <any>[];
  colorMidia = <any>[];
  colorMidiaTempo = <any>[];
  colorPauta = <any>[];
  colorCentrimetragemPositiva = <any>[];
  colorCentrimetragemNeutra = <any>[];
  colorDetratores = this.coresSentimento[2];
  colorEvangelizadores = this.coresSentimento[0];
  colorCentrimetragemVeiculosPositiva = this.coresSentimento[0];
  colorCentrimetragemVeiculosNeutra = this.coresSentimento[1];
  request: string = '';

  typeSelectedNoticiasPorMidia: string = 'bar';
  typeSelectedNoticiasPorClassificacao: string = 'bar';

  //----------------------------------Variaveis da Tabela------------------------------------

  @ViewChild(MatPaginator) paginator: MatPaginator = <any>{};
  @ViewChild(MatSort) sort: MatSort = <any>{};

  carregandoLength = true;
  carregandoSent = true;

  positiva = 0;
  negativa = 0;
  neutra = 0;
  length = 0;
  carregandoTabela = true;

  meses: MesesAssociados = {
    1: 'JANEIRO',
    2: 'FEVEREIRO',
    3: 'MARÇO',
    4: 'ABRIL',
    5: 'MAIO',
    6: 'JUNHO',
    7: 'JULHO',
    8: 'AGOSTO',
    9: 'SETEMBRO',
    10: 'OUTUBRO',
    11: 'NOVEMBRO',
    12: 'DEZEMBRO',
  };

  grupospdf: string = '';
  empresaspdf: string = '';

  //----------------------------------Variaveis dos Filtros------------------------------------

  @ViewChild('completeCategoria') completeCategoria: MatSelect = <any>{};
  @ViewChild('completeGrupo') completeGrupo: MatSelect = <any>{};
  @ViewChild('completeEmpresa') completeEmpresa: MatSelect = <any>{};
  @ViewChild('completePauta') completePauta: MatSelect = <any>{};
  @ViewChild('completeMidia') completeMidia: MatSelect = <any>{};
  @ViewChild('completeVeiculo') completeVeiculo: MatSelect = <any>{};
  @ViewChild('completeSentimento') completeSentimento: MatSelect = <any>{};
  @ViewChild('completeSubGrupo') completeSubGrupo: MatSelect = <any>{};
  @ViewChild('completeTipoInformacao') completeTipoInformacao: MatSelect = <
    any
  >{};

  disableFilterButton: boolean = false;

  panelExpansion: boolean = false;
  erroCarregamentoFiltros: boolean = false;

  disableFiltros: boolean = false;
  carregandoFiltros: boolean = false;
  carregandoFiltrosInit: boolean = false;

  hasAlteracaoFiltroInformacao: boolean = false;
  hasAlteracaoFiltroGrupo: boolean = false;
  hasAlteracaoFiltroEmpresa: boolean = false;
  hasAlteracaoFiltroMidia: boolean = false;
  hasAlteracaoFiltroVeiculo: boolean = false;
  hasAlteracaoFiltroCategoria: boolean = false;
  hasAlteracaoFiltroSentimento: boolean = false;
  hasAlteracaoFiltroPauta: boolean = false;
  hasAlteracaoFiltroSubGrupo: boolean = false;

  needFilterWordCloud: boolean = false;
  hasFiltroParaNuvem: boolean = false;

  categorias: Array<Categoria> = [];
  grupos: Array<Grupo> = [];
  empresas: Array<Empresa> = [];
  sentimentos: Array<Sentimento> = [];
  pautas: Array<Pauta> = [];
  midias: Array<Midia> = [];
  veiculos: Array<Veiculo> = [];

  subCategorias: Array<SubCategoria> = [];
  subGrupos: Array<SubGrupo> = [];
  tiposInformacao: Array<TipoInformacao> = [];

  filteredOptionsCategorias?: Observable<Categoria[]>;
  filteredOptionsGrupos?: Observable<Grupo[]>;
  filteredOptionsEmpresas?: Observable<Empresa[]>;
  filteredOptionsSentimentos?: Observable<Sentimento[]>;
  filteredOptionsPautas?: Observable<Pauta[]>;
  filteredOptionsMidias?: Observable<Midia[]>;
  filteredOptionsVeiculos?: Observable<Veiculo[]>;
  filteredOptionsSubGrupos?: Observable<SubGrupo[]>;
  filteredOptionsTiposInformacao?: Observable<TipoInformacao[]>;

  filterSelected: filterSelected = {} as filterSelected;
  filterTrigger: filterSelected = {} as filterSelected;

  chipShow = <any>[];
  minDate = <Date>{};
  maxDate = new Date();
  todayDate = new Date();
  thirtyDaysAgo = new Date(new Date().setDate(this.todayDate.getDate() - 30));

  filterForm = new FormGroup({
    categorias: new FormControl(),
    grupos: new FormControl(),
    empresas: new FormControl(),
    sentimentos: new FormControl(),
    pautas: new FormControl(),
    midias: new FormControl(),
    veiculos: new FormControl(),
    subGrupos: new FormControl({ value: '', disabled: true }),
    tipoInformacao: new FormControl(),

    searchTextboxCategoria: new FormControl(''),
    searchTextboxGrupo: new FormControl(''),
    searchTextboxEmpresa: new FormControl(''),
    searchTextboxSentimento: new FormControl(''),
    searchTextboxMidia: new FormControl(''),
    searchTextboxVeiculo: new FormControl(''),
    searchTextboxSubGrupo: new FormControl(''),
    searchTextboxTipoInformacao: new FormControl(''),
    searchTextboxPauta: new FormControl(''),

    dataInicial: new FormControl<any | null>({
      disabled: true,
      value: this.thirtyDaysAgo,
    }),
    dataFinal: new FormControl<any | null>({
      disabled: true,
      value: this.todayDate,
    }),
    titulo: new FormControl(null),
  });

  difDays: number = 0;

  //----------------------------------Variaveis de Responsividade------------------------------------
  isTelaMenorQue500px: number = 4;
  numbergraphs: number = 2;
  numbertitle: number = 3;
  responsiveCentimetragem: number = 1;
  responsiveCards: number = 2;

  constructor(
    private cdr: ChangeDetectorRef,
    private categoriaService: CategoriaService,
    private grupoService: GrupoService,
    private empresaService: EmpresaService,
    private sentimentoService: SentimentoService,
    public dashboardImprensaService: DashboardImprensaService,
    private dashboardLegislativoService: DashboardLegislativoService,
    private dashboardJuridicoService: DashboardJuridicoService,
    private dashboardComunidadeService: DashboardComunidadeService,
    private pautaService: PautaService,
    private midiaService: MidiaService,
    private veiculoService: VeiculoService,
    private subGrupoService: SubGrupoService,
    private tipoInformacaoService: TipoInformacaoService,
    private homeService: HomeService,
    private partidoService: PartidoService,
    private autoridadeService: AutoridadeService,
    public dialog: MatDialog
  ) {
    // Verifica a flag de recarregamento ao inicializar
    if (sessionStorage.getItem('isReloading')) {
      // Remove a flag para que ela não persista após o recarregamento
      sessionStorage.removeItem('isReloading');
    } else {
      // Caso contrário, isso significa que a aba foi fechada e reaberta
      this.clearGrupoInfo ();
    }
  }

  // Função para detectar o fechamento ou recarregamento da aba
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(_: Event): void {
    sessionStorage.setItem('isReloading', 'true');
    return;
  }

  // Função para limpar as informações do grupo do localStorage
  clearGrupoInfo (): void {
    localStorage.removeItem('conteudosGrupo');
    localStorage.removeItem('tokenGrupo');
    localStorage.removeItem('permissionSubgrupoGrupo');
    localStorage.removeItem('permissionChartsGrupo');
  }

  async ngOnInit(): Promise<void> {
    const permSuperin = Cryptograf.AES.decrypt(
      JSON.parse(
        (localStorage.getItem('permissionSubgrupoGrupo')
          ? localStorage.getItem('permissionSubgrupoGrupo')
          : localStorage.getItem('permissionSubgrupo')) || 'null'
      ),
      environment.cryptoKey
    );

    if (permSuperin.toString(Cryptograf.enc.Utf8) == 'null') {
      this.permissaoSuperintendencia = false;
    } else {
      this.permissaoSuperintendencia = JSON.parse(
        permSuperin.toString(Cryptograf.enc.Utf8)
      ).includes(1);
    }

    const permCharts = Cryptograf.AES.decrypt(
      JSON.parse(
        (localStorage.getItem('permissionChartsGrupo')
          ? localStorage.getItem('permissionChartsGrupo')
          : localStorage.getItem('permissionCharts')) || 'null'
      ),
      environment.cryptoKey
    );

    if (permCharts.toString(Cryptograf.enc.Utf8) == 'null') {
      this.permissionCharts = [];
    } else {
      this.permissionCharts = JSON.parse(
        permCharts.toString(Cryptograf.enc.Utf8)
      ).find(
        (chart: { id_conteudo: number; nome: Array<string> }) =>
          chart.id_conteudo === 1
      ).nome;
    }

    this.homeService.enviarVariavel(1);
    this.dashboardJuridicoService.cancelarChamada();
    this.dashboardLegislativoService.cancelarChamada();
    this.dashboardComunidadeService.cancelarChamada();
    this.cancelarCarregamentoFiltros();

    this.onResize();

    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);
    this.filterSelected.tipoInformacao = 1;
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.showchips();

    // atualizando o valor da variável para controle de exibiçao dos gráficos
    this.difDays =
      (new Date(this.filterForm.controls.dataFinal.value).getTime() -
        new Date(this.filterForm.controls.dataInicial.value).getTime()) /
      (1000 * 3600 * 24);

    this.responsiveCentimetragem = this.formatarNuvemDePalavrasLargura();

    this.request = this.criarStringRequest();

    //-------------Carregamento dos gráficos----------------
    this.carregandoFiltros = true;
    this.carregandoFiltrosInit = true;
    this.needFilterWordCloud = false;
    this.carregandoLength = true;
    this.hasNoticiasGauche = true;
    this.carregandoSent = true;

    await Promise.all([
      Promise.all([
        this.listar_tipoInformacao(),
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ])
        .then(() => {
          this.erroCarregamentoFiltros = false;
          this.carregandoFiltros = false;
          this.carregandoFiltrosInit = false;
          this.onResize();

          setTimeout(() => {
            this.completeTipoInformacao.options.forEach((element) => {
              if (element.value == 'Notícias/Publicações') {
                element.select();
              }
            });
          }, 100);
        })
        .catch((error: HttpErrorResponse) => {
          this.erroCarregamentoFiltros = true;
          if (error.status !== 401) {
            Swal.fire({
              icon: 'error',
              title: 'Houve um erro ao carregar os filtros',
              text: 'Por favor, tente novamente.',
              confirmButtonColor: 'orange',
            });
          }
          throw error;
        }),

      Promise.all([
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .sentimentoDetratores(this.request)
            .subscribe({
              next: (value) => {
                this.dataDetratores = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroDetratores = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .sentimentoEvangelizadores(this.request)
            .subscribe({
              next: (value) => {
                this.dataEvangelizadores = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroEvangelizadores = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .noticiaPorSentimento(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSentimento = value;
                this.colorSentimento = this.setColorSentimento(
                  this.dataNoticiaPorSentimento
                );
                this.positiva = 0;
                this.negativa = 0;
                this.neutra = 0;
                value.forEach((element: any) => {
                  if (element[0] == 'POSITIVA') this.positiva = element[1];
                  if (element[0] == 'NEGATIVA') this.negativa = element[1];
                  if (element[0] == 'NEUTRA') this.neutra = element[1];
                });
                if (this.positiva + this.negativa > 0) {
                  this.dataNoticiaGauge =
                    this.positiva / (this.positiva + this.negativa);
                } else if (this.neutra > 0) {
                  this.hasNoticiasGauche = false;
                }
                this.carregandoSent = false;

                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.carregandoSent = false;
                this.erroNoticiaPorSentimento = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .noticiaPorMidia(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorMidia = value;
                this.colorMidia = this.setColorMidia(this.dataNoticiaPorMidia);
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorMidia = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .noticiaPorVeiculo(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorVeiculo = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorVeiculo = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          if (
            !this.permissionCharts.includes(
              'Centimetragem Positiva por Tipos de Mídia'
            )
          ) {
            resolve(true);
            return;
          } else if (
            this.filterSelected.sentimento !== undefined &&
            this.filterSelected.sentimento.length !== 0 &&
            !this.filterSelected.sentimento.includes('POSITIVA')
          ) {
            this.dataCentrimetragemPorMidiaPositiva = [];
            resolve(true);
            return;
          } else {
            let newRequest = this.request;

            newRequest = this.request.concat(`&sentimento=POSITIVA`);

            this.dashboardImprensaService
              .centrimetragemPorMidia(newRequest)
              .subscribe({
                next: (value) => {
                  this.dataCentrimetragemPorMidiaPositiva = value;

                  value.forEach((element: any) => {
                    if (element[0] == 'Total') {
                      if (this.maxValueCentimetragem < element[1]) {
                        this.maxValueCentimetragem = element[1];
                      }
                    }
                  });
                  this.colorCentrimetragemPositiva = this.setColorMidia(
                    this.dataCentrimetragemPorMidiaPositiva
                  );
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroCentrimetragemPorMidiaPositiva = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          if (
            !this.permissionCharts.includes(
              'Centimetragem Neutra por Tipos de Mídia'
            )
          ) {
            resolve(true);
            return;
          } else if (
            this.filterSelected.sentimento !== undefined &&
            this.filterSelected.sentimento.length !== 0 &&
            !this.filterSelected.sentimento.includes('NEUTRA')
          ) {
            this.dataCentrimetragemPorMidiaNeutra = [];
            resolve(true);
            return;
          } else {
            let newRequest = this.request;

            newRequest = this.request.concat(`&sentimento=NEUTRA`);

            this.dashboardImprensaService
              .centrimetragemPorMidia(newRequest)
              .subscribe({
                next: (value) => {
                  this.dataCentrimetragemPorMidiaNeutra = value;
                  value.forEach((element: any) => {
                    if (element[0] == 'Total') {
                      if (this.maxValueCentimetragem < element[1]) {
                        this.maxValueCentimetragem = element[1];
                      }
                    }
                  });
                  this.colorCentrimetragemNeutra = this.setColorMidia(
                    this.dataCentrimetragemPorMidiaNeutra
                  );
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroCentrimetragemPorMidiaNeutra = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          if (
            !this.permissionCharts.includes(
              'Centimetragem Positiva por Veículo'
            )
          ) {
            resolve(true);
            return;
          } else {
            let newRequest = this.request;

            newRequest = this.request.concat(`&sentimento=POSITIVA`);

            this.dashboardImprensaService
              .centrimetragemPorVeiculoPositiva(newRequest)
              .subscribe({
                next: (value) => {
                  this.dataCentrimetragemPorVeiculoPositiva = value;

                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroCentrimetragemPorVeiculoPositiva = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          if (
            !this.permissionCharts.includes('Centimetragem Neutra por Veículo')
          ) {
            resolve(true);
            return;
          } else {
            let newRequest = this.request;

            newRequest = this.request.concat(`&sentimento=NEUTRA`);

            this.dashboardImprensaService
              .centrimetragemPorVeiculoNeutra(newRequest)
              .subscribe({
                next: (value) => {
                  this.dataCentrimetragemPorVeiculoNeutra = value;

                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroCentrimetragemPorVeiculoNeutra = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .sentimentoPorDuracao(this.request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorDuracao = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorDuracao = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .sentimentoPorCategoria(this.request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorCategoria = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorCategoria = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          let newRequest = this.request;
          // Os filtros de data são removidos, pois o gráfico de sentimento por tempo não é restringido pelos filtros de data
          // Entretanto essa funcionalidade é apenas do relatório de Imprensa, então esse ajuste é feito apenas para ele
          // Além disso caso o ano da data inicial seja menor que o ano atual, os filtros de data são mantidos
          if (
            // this.difDays > 30 &&
            newRequest.includes('data_inicio') &&
            newRequest.includes('data_fim') &&
            !(
              new Date(this.filterSelected.dataInicial).getFullYear() <
              new Date().getFullYear()
            )
          ) {
            newRequest = newRequest.replace(
              `data_inicio=${this.filterSelected.dataInicial}&`,
              ''
            );
            newRequest = newRequest.replace(
              `data_fim=${this.filterSelected.dataFinal}`,
              ''
            );
          }
          this.dashboardImprensaService
            .sentimentoPorTempo(newRequest)
            .subscribe({
              next: (value) => {
                // if (value.sentimentos) {
                //   this.sentimentoSentimentoPorTempo = value.sentimentos;
                //   this.dataSentimentoPorTempo = value.datas;
                //   this.colorSentimentoTempo = this.setColorSentimento(
                //     this.sentimentoSentimentoPorTempo
                //   );
                // } else {
                this.dataSentimentoPorTempoMes = value;
                // }
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroSentimentoPorTempoMes = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          let newRequest = this.request;
          if (
            // this.difDays > 31 &&
            newRequest.includes('data_inicio') &&
            newRequest.includes('data_fim') &&
            !(
              new Date(this.filterSelected.dataInicial).getFullYear() <
              new Date().getFullYear()
            )
          ) {
            newRequest = newRequest.replace(
              `data_inicio=${this.filterSelected.dataInicial}&`,
              ''
            );
            newRequest = newRequest.replace(
              `data_fim=${this.filterSelected.dataFinal}`,
              ''
            );
          }
          this.dashboardImprensaService.midiaPorTempo(newRequest).subscribe({
            next: (value) => {
              // if (value.midias) {
              //   this.midiaMidiaPorTempo = value.midias;
              //   this.dataMidiaPorTempo = value.datas;
              //   this.colorMidiaTempo = this.setColorMidia(
              //     this.midiaMidiaPorTempo
              //   );
              // } else {
              this.dataMidiaPorTempoMes = value;
              this.colorMidiaTempo = this.setColorMidiaMensal(
                this.dataMidiaPorTempoMes?.quantidades
              );
              // }
              resolve(true);
            },
            error: (error: HttpErrorResponse) => {
              this.erroMidiaPorTempoMes = true;
              reject(error);
            },
          });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .noticiaPorSubCategoria(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSubCategoria = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorSubCategoria = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          if (!this.permissionCharts.includes('Notícias por Pauta')) {
            resolve(true);
            return;
          } else {
            this.dashboardImprensaService
              .noticiaPorPauta(this.request)
              .subscribe({
                next: (value) => {
                  this.dataNoticiaPorPauta = value;

                  this.colorPauta = this.setColorPauta(
                    this.dataNoticiaPorPauta
                  );
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroNoticiaPorPauta = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          if (this.difDays < 28) {
            this.dashboardImprensaService
              .noticiaPorSentimentoDiario(this.request)
              .subscribe({
                next: (value) => {
                  this.dataNoticiaPorSentimentoDiario = value;
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroNoticiaPorSentimentoDiario = true;
                  reject(error);
                },
              });
          } else {
            this.dataNoticiaPorSentimentoDiario = null;
            resolve(true);
            return;
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardImprensaService
            .nuvemDePalavras(this.hasFiltroParaNuvem, this.request)
            .subscribe({
              next: (value) => {
                this.dataNuvemDePalavras = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                if (error.status !== 401) {
                  this.erroNuvemDePalavras = true;
                  reject(error);
                  return;
                }
                reject(error);
              },
              complete: () => {
                this.needFilterWordCloud = true;
              },
            });
        }).catch((error: HttpErrorResponse) => {
          captureException(error);
          throw error;
        }),
      ]),
    ]);
  }

  //------------------------------Responsividade dos filtros-------------------------------------
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth <= 768) {
      this.isTelaMenorQue500px = 1;
      this.numbergraphs = 1;
      this.numbertitle = 1;
      this.responsiveCentimetragem = 1;
      this.responsiveCards = 1;
    } else {
      this.isTelaMenorQue500px = 4;
      this.numbergraphs = 2;
      this.responsiveCards = 2;
      this.responsiveCentimetragem = this.formatarNuvemDePalavrasLargura();

      if (this.permissaoSuperintendencia) {
        this.numbertitle = 4;
      } else {
        this.numbertitle = 4;
      }
    }
  }

  // -------------------------------exibir e esconder os chips----------------------------------------
  showchips() {
    this.chipShow = [];

    if (
      this.filterTrigger.grupo !== undefined &&
      this.filterTrigger.grupo.length > 0 &&
      this.filterForm.get('grupos')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.grupo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.grupo.split(', ')[i]);
      }
    }

    if (this.filterForm.controls.dataInicial.value !== null) {
      let datainicial = this.moment(
        this.filterForm.controls.dataInicial.value
      ).toDate();
      this.chipShow.push(
        `${datainicial.getDate()}/${
          datainicial.getMonth() + 1
        }/${datainicial.getFullYear()}`
      );
    }

    if (this.filterForm.controls.dataFinal.value !== null) {
      let datafinal = this.moment(
        this.filterForm.controls.dataFinal.value
      ).toDate();
      this.chipShow.push(
        `${datafinal.getDate()}/${
          datafinal.getMonth() + 1
        }/${datafinal.getFullYear()}`
      );
    }

    if (
      this.filterTrigger.empresa !== undefined &&
      this.filterTrigger.empresa.length > 0 &&
      this.filterForm.get('empresas')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.empresa.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.empresa.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.midia !== undefined &&
      this.filterTrigger.midia.length > 0 &&
      this.filterForm.get('midia')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.midia.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.midia.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.veiculo !== undefined &&
      this.filterTrigger.veiculo.length > 0 &&
      this.filterForm.get('veiculo')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.veiculo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.veiculo.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.categoria !== undefined &&
      this.filterTrigger.categoria.length > 0 &&
      this.filterForm.get('categorias')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.categoria.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.categoria.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.sentimento !== undefined &&
      this.filterTrigger.sentimento.length > 0 &&
      this.filterForm.get('sentimento')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.sentimento.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.sentimento.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.pauta !== undefined &&
      this.filterTrigger.pauta.length > 0 &&
      this.filterForm.get('pauta')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.pauta.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.pauta.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.subGrupo !== undefined &&
      this.filterTrigger.subGrupo.length > 0 &&
      this.filterForm.get('subGrupos')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.subGrupo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.subGrupo.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.tipoInformacao !== undefined &&
      this.filterTrigger.tipoInformacao.length > 0 &&
      this.filterForm.get('tipoInformacao')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.tipoInformacao.split(', ').length;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.tipoInformacao.split(', ')[i]);
      }
    }

    if (
      this.filterForm.controls.titulo.value !== '' &&
      this.filterForm.controls.titulo.value !== null
    )
      this.chipShow.push(this.filterForm.controls.titulo.value);
  }

  // -------------------------------funçoes relacionadas aos filtros------------------------
  async setDate(_: any, onInitiate: boolean = false) {
    const dataInicial = this.moment(
      this.filterForm.controls.dataInicial.value
    ).toDate();

    const datafinal = this.moment(
      this.filterForm.controls.dataFinal.value
    ).toDate();
    if (dataInicial !== null) {
      this.minDate = new Date(
        dataInicial.getFullYear(),
        dataInicial.getMonth(),
        dataInicial.getDate()
      );
      this.filterSelected.dataInicial = `${dataInicial.getFullYear()}-${
        dataInicial.getMonth() + 1
      }-${dataInicial.getDate()}`;
    }

    if (datafinal !== null) {
      this.maxDate = new Date(
        datafinal.getFullYear(),
        datafinal.getMonth(),
        datafinal.getDate()
      );
      this.filterSelected.dataFinal = `${datafinal.getFullYear()}-${
        datafinal.getMonth() + 1
      }-${datafinal.getDate()}`;
    }

    if (dataInicial && datafinal) {
      this.difDays =
        (datafinal.getTime() - dataInicial.getTime()) / (1000 * 3600 * 24);
    }

    if (onInitiate == false) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.pautas = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.carregandoFiltros = false;
    }
  }

  validClearFilter() {
    if (
      (this.filterSelected.categoria == undefined ||
        this.filterSelected.categoria.length == 0) &&
      (this.filterSelected.grupo == undefined ||
        this.filterSelected.grupo.length == 0) &&
      (this.filterSelected.empresa == undefined ||
        this.filterSelected.empresa.length == 0) &&
      (this.filterSelected.sentimento == undefined ||
        this.filterSelected.sentimento.length == 0) &&
      (this.filterSelected.subCategoria == undefined ||
        this.filterSelected.subCategoria.length == 0) &&
      (this.filterSelected.subGrupo == undefined ||
        this.filterSelected.subGrupo.length == 0) &&
      (this.filterSelected.pauta == undefined ||
        this.filterSelected.pauta.length == 0) &&
      (this.filterSelected.midia == undefined ||
        this.filterSelected.midia.length == 0) &&
      (this.filterSelected.veiculo == undefined ||
        this.filterSelected.veiculo.length == 0) &&
      (this.filterSelected.tipoInformacao == undefined ||
        this.filterSelected.tipoInformacao == 1) &&
      (this.filterForm.controls.titulo.value == '' ||
        this.filterForm.controls.titulo.value == null) &&
      this.filterForm.controls.dataInicial.value == this.thirtyDaysAgo &&
      this.filterForm.controls.dataFinal.value == this.todayDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  async limparFiltros() {
    this.filterSelected = {} as filterSelected;

    this.filterSelected.tipoInformacao = 1;
    this.minDate = <Date>{};
    this.maxDate = new Date();

    this.completeTipoInformacao.options.forEach((element) => {
      if (element.value == 'Notícias/Publicações') {
        element.select();
      } else {
        element.deselect();
      }
    });

    this.completeGrupo.options.forEach((element) => {
      element.deselect();
    });
    this.completeEmpresa.options.forEach((element) => {
      element.deselect();
    });
    this.completeMidia.options.forEach((element) => {
      element.deselect();
    });
    this.completeVeiculo.options.forEach((element) => {
      element.deselect();
    });
    this.completeCategoria.options.forEach((element) => {
      element.deselect();
    });
    this.completeSentimento.options.forEach((element) => {
      element.deselect();
    });
    this.completePauta.options.forEach((element) => {
      element.deselect();
    });
    if (this.permissaoSuperintendencia) {
      this.completeSubGrupo.options.forEach((element) => {
        element.deselect();
      });
    }
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.filterTrigger.categoria = '';
    this.filterTrigger.grupo = '';
    this.filterTrigger.empresa = '';
    this.filterTrigger.sentimento = '';
    this.filterTrigger.subCategoria = '';
    this.filterTrigger.subGrupo = '';
    this.filterTrigger.pauta = '';
    this.filterTrigger.midia = '';
    this.filterTrigger.veiculo = '';

    this.filterForm.controls.dataInicial.setValue(this.thirtyDaysAgo);
    this.filterForm.controls.dataFinal.setValue(this.todayDate);
    this.filterForm.controls.titulo.setValue(null);
    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);

    this.disableFiltrosFunc();
    this.carregandoFiltros = true;

    this.grupos = [];
    this.empresas = [];
    this.midias = [];
    this.veiculos = [];
    this.categorias = [];
    this.sentimentos = [];
    this.pautas = [];
    this.subGrupos = [];

    this.showchips();
    this.request = this.criarStringRequest();
    await Promise.all([
      this.listar_grupos(this.request),
      this.listar_empresas(this.request),
      this.listar_midias(this.request),
      this.listar_veiculos(this.request),
      this.listar_categorias(this.request),
      this.listar_sentimentos(this.request),
      this.listar_pautas(this.request),
      this.listar_subGrupos(this.request),
      this.filtrar(),
    ]).then(() => {
      this.enableFiltrosFunc();
      this.carregandoFiltros = false;
    });
  }

  criarStringRequest(
    opcoes: {
      remove?: boolean;
      grupo?: boolean;
      empresa?: boolean;
      midia?: boolean;
      veiculo?: boolean;
      categoria?: boolean;
      sentimento?: boolean;
      pauta?: boolean;
      subCategoria?: boolean;
      subGrupo?: boolean;
    } = {}
  ) {
    const {
      remove = false,
      grupo = false,
      empresa = false,
      midia = false,
      veiculo = false,
      categoria = false,
      sentimento = false,
      pauta = false,
      subCategoria = false,
      subGrupo = false,
    } = opcoes;

    let request: string = '';
    this.hasFiltroParaNuvem = false;

    if (
      categoria == false &&
      this.filterSelected.categoria !== undefined &&
      this.filterSelected.categoria.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_categoria=`);
      for (let i = 0; i < this.filterSelected.categoria.length; i++) {
        request = request.concat(`${this.filterSelected.categoria[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      grupo == false &&
      this.filterSelected.grupo !== undefined &&
      this.filterSelected.grupo.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_grupo=`);
      for (let i = 0; i < this.filterSelected.grupo.length; i++) {
        request = request.concat(`${this.filterSelected.grupo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      empresa == false &&
      this.filterSelected.empresa !== undefined &&
      this.filterSelected.empresa.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_empresa=`);
      for (let i = 0; i < this.filterSelected.empresa.length; i++) {
        request = request.concat(`${this.filterSelected.empresa[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      sentimento == false &&
      this.filterSelected.sentimento !== undefined &&
      this.filterSelected.sentimento.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`sentimento=`);
      for (let i = 0; i < this.filterSelected.sentimento.length; i++) {
        request = request.concat(`${this.filterSelected.sentimento[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      pauta == false &&
      this.filterSelected.pauta !== undefined &&
      this.filterSelected.pauta.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_pauta=`);
      for (let i = 0; i < this.filterSelected.pauta.length; i++) {
        request = request.concat(`${this.filterSelected.pauta[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      midia == false &&
      this.filterSelected.midia !== undefined &&
      this.filterSelected.midia.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_midia=`);
      for (let i = 0; i < this.filterSelected.midia.length; i++) {
        request = request.concat(`${this.filterSelected.midia[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      veiculo == false &&
      this.filterSelected.veiculo !== undefined &&
      this.filterSelected.veiculo.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_veiculo=`);
      for (let i = 0; i < this.filterSelected.veiculo.length; i++) {
        request = request.concat(`${this.filterSelected.veiculo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      subCategoria == false &&
      this.filterSelected.subCategoria !== undefined &&
      this.filterSelected.subCategoria.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_subcategoria=`);
      for (let i = 0; i < this.filterSelected.subCategoria.length; i++) {
        request = request.concat(`${this.filterSelected.subCategoria[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      subGrupo == false &&
      this.filterSelected.subGrupo !== undefined &&
      this.filterSelected.subGrupo.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_sub_grupo=`);
      for (let i = 0; i < this.filterSelected.subGrupo.length; i++) {
        request = request.concat(`${this.filterSelected.subGrupo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (this.filterSelected.tipoInformacao !== undefined) {
      request = request.concat(`informacao=`);
      request = request.concat(`${this.filterSelected.tipoInformacao}`);
      request = request.concat(`&`);
    }

    if (
      this.filterSelected.titulo !== undefined &&
      this.filterSelected.titulo !== '' &&
      this.filterSelected.titulo !== null
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`titulo=${this.filterSelected.titulo}&`);
    }
    if (this.filterSelected.dataInicial !== undefined && remove == false)
      request = request.concat(
        `data_inicio=${this.filterSelected.dataInicial}&`
      );
    if (this.filterSelected.dataFinal !== undefined && remove == false)
      request = request.concat(`data_fim=${this.filterSelected.dataFinal}`);

    // Remover o último '&', se existir
    if (request.endsWith('&')) {
      request = request.slice(0, -1);
    }

    return request;
  }

  filtrar() {
    this.showchips();
    this.request = this.criarStringRequest();
    this.dataNoticiaPorSentimento = null;
    this.dataNoticiaPorMidia = null;
    this.dataNoticiaPorVeiculo = null;
    this.maxValueCentimetragem = 0;
    this.dataCentrimetragemPorMidiaPositiva = null;
    this.dataCentrimetragemPorMidiaNeutra = null;
    this.dataCentrimetragemPorVeiculoPositiva = null;
    this.dataCentrimetragemPorVeiculoNeutra = null;
    this.dataSentimentoPorCategoria = null;
    this.dataSentimentoPorDuracao = null;
    this.dataSentimentoPorEmpresa = null;
    this.dataNoticiaPorSubCategoria = null;
    this.dataNoticiaPorPauta = null;
    this.sentimentoSentimentoPorTempo = null;
    // this.dataSentimentoPorTempo = null;
    this.dataSentimentoPorTempoMes = null;
    this.midiaMidiaPorTempo = null;
    // this.dataMidiaPorTempo = null;
    this.dataMidiaPorTempoMes = null;
    this.dataNuvemDePalavras = null;
    this.dataNoticiaPorSentimentoDiario = null;
    this.carregandoTabela = true;
    this.carregandoSent = true;
    this.dataDetratores = null;
    this.dataEvangelizadores = null;
    this.hasNoticiasGauche = true;
    this.needFilterWordCloud = false;
    this.dashboardImprensaService.cancelarChamada();
    // atualizando o valor da variável para controle de exibiçao dos gráficos
    this.difDays =
      (new Date(this.filterForm.controls.dataFinal.value).getTime() -
        new Date(this.filterForm.controls.dataInicial.value).getTime()) /
      (1000 * 3600 * 24);
    this.responsiveCentimetragem = this.formatarNuvemDePalavrasLargura();

    if (this.filterTrigger.grupo !== undefined)
      this.grupospdf = this.filterTrigger.grupo;
    if (this.filterTrigger.empresa !== undefined)
      this.empresaspdf = this.filterTrigger.empresa;
    try {
      Promise.all([
        new Promise((resolve, reject) => {
          this.erroNuvemDePalavras = false;
          this.dashboardImprensaService
            .nuvemDePalavras(this.hasFiltroParaNuvem, this.request)
            .subscribe({
              next: (value) => {
                this.dataNuvemDePalavras = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                if (error.status !== 401) {
                  this.erroNuvemDePalavras = true;
                  reject(error);
                  return;
                }
                reject(error);
              },
              complete: () => {
                this.needFilterWordCloud = true;
              },
            });
        }).catch((error: HttpErrorResponse) => {
          captureException(error);
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorSentimento = false;
          this.dashboardImprensaService
            .noticiaPorSentimento(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSentimento = value;
                this.colorSentimento = this.setColorSentimento(
                  this.dataNoticiaPorSentimento
                );
                this.positiva = 0;
                this.negativa = 0;
                this.neutra = 0;
                value.forEach((element: any) => {
                  if (element[0] == 'POSITIVA') this.positiva = element[1];
                  if (element[0] == 'NEGATIVA') this.negativa = element[1];
                  if (element[0] == 'NEUTRA') this.neutra = element[1];
                });
                if (this.positiva + this.negativa > 0) {
                  this.dataNoticiaGauge =
                    this.positiva / (this.positiva + this.negativa);
                } else if (this.neutra > 0) {
                  this.hasNoticiasGauche = false;
                }
                this.carregandoSent = false;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.carregandoSent = false;
                this.erroNoticiaPorSentimento = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorMidia = false;
          this.dashboardImprensaService
            .noticiaPorMidia(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorMidia = value;
                this.colorMidia = this.setColorMidia(this.dataNoticiaPorMidia);
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorMidia = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorVeiculo = false;
          this.dashboardImprensaService
            .noticiaPorVeiculo(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorVeiculo = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorVeiculo = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroCentrimetragemPorMidiaPositiva = false;
          if (
            !this.permissionCharts.includes(
              'Centimetragem Positiva por Tipos de Mídia'
            )
          ) {
            resolve(true);
            return;
          } else if (
            this.filterSelected.sentimento !== undefined &&
            this.filterSelected.sentimento.length !== 0 &&
            !this.filterSelected.sentimento.includes('POSITIVA')
          ) {
            this.dataCentrimetragemPorMidiaPositiva = [];
            resolve(true);
            return;
          } else {
            let newRequest = this.request;

            newRequest = this.request.concat(`&sentimento=POSITIVA`);

            this.dashboardImprensaService
              .centrimetragemPorMidia(newRequest)
              .subscribe({
                next: (value) => {
                  this.dataCentrimetragemPorMidiaPositiva = value;
                  value.forEach((element: any) => {
                    if (element[0] == 'Total') {
                      if (this.maxValueCentimetragem < element[1]) {
                        this.maxValueCentimetragem = element[1];
                      }
                    }
                  });
                  this.colorCentrimetragemPositiva = this.setColorMidia(
                    this.dataCentrimetragemPorMidiaPositiva
                  );
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroCentrimetragemPorMidiaPositiva = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroCentrimetragemPorMidiaNeutra = false;
          if (
            !this.permissionCharts.includes(
              'Centimetragem Neutra por Tipos de Mídia'
            )
          ) {
            resolve(true);
            return;
          } else if (
            this.filterSelected.sentimento !== undefined &&
            this.filterSelected.sentimento.length !== 0 &&
            !this.filterSelected.sentimento.includes('NEUTRA')
          ) {
            this.dataCentrimetragemPorMidiaNeutra = [];
            resolve(true);
            return;
          } else {
            let newRequest = this.request;

            newRequest = this.request.concat(`&sentimento=NEUTRA`);

            this.dashboardImprensaService
              .centrimetragemPorMidia(newRequest)
              .subscribe({
                next: (value) => {
                  this.dataCentrimetragemPorMidiaNeutra = value;
                  value.forEach((element: any) => {
                    if (element[0] == 'Total') {
                      if (this.maxValueCentimetragem < element[1]) {
                        this.maxValueCentimetragem = element[1];
                      }
                    }
                  });
                  this.colorCentrimetragemNeutra = this.setColorMidia(
                    this.dataCentrimetragemPorMidiaNeutra
                  );
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroCentrimetragemPorMidiaNeutra = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroCentrimetragemPorVeiculoPositiva = false;
          if (
            !this.permissionCharts.includes(
              'Centimetragem Positiva por Veículo'
            )
          ) {
            resolve(true);
            return;
          } else {
            let newRequest = this.request;

            newRequest = this.request.concat(`&sentimento=POSITIVA`);

            this.dashboardImprensaService
              .centrimetragemPorVeiculoPositiva(newRequest)
              .subscribe({
                next: (value) => {
                  this.dataCentrimetragemPorVeiculoPositiva = value;
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroCentrimetragemPorVeiculoPositiva = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroCentrimetragemPorVeiculoNeutra = false;
          if (
            !this.permissionCharts.includes('Centimetragem Neutra por Veículo')
          ) {
            resolve(true);
            return;
          } else {
            let newRequest = this.request;

            newRequest = this.request.concat(`&sentimento=NEUTRA`);

            this.dashboardImprensaService
              .centrimetragemPorVeiculoNeutra(newRequest)
              .subscribe({
                next: (value) => {
                  this.dataCentrimetragemPorVeiculoNeutra = value;
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroCentrimetragemPorVeiculoNeutra = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorCategoria = false;
          this.dashboardImprensaService
            .sentimentoPorCategoria(this.request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorCategoria = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorCategoria = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorDuracao = false;
          this.dashboardImprensaService
            .sentimentoPorDuracao(this.request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorDuracao = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorDuracao = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorEmpresa = false;
          this.qtdEmpresaSentimentoPorEmpresa = false;

          if (!this.permissionCharts.includes('Notícias por Empresa')) {
            resolve(true);
            return;
          }
          let id_grupo: number[] = [];
          // Adicionando os grupos no array
          this.request.split('&').forEach((element) => {
            if (element.includes('id_grupo')) {
              const number = element.replace('id_grupo=', '').split(',');
              number.forEach((element) => {
                id_grupo.push(parseInt(element));
              });
            }
          });

          // Verificando se existe mais de um grupo selecionado
          if (id_grupo.length === 0 || id_grupo.length > 1) {
            this.qtdGrupoSentimentoPorEmpresa = true;
            resolve(true);
            return;
          }

          this.qtdGrupoSentimentoPorEmpresa = false;
          // Removendo o id_grupo da request
          const request = this.request.replace(`id_grupo=${id_grupo}`, '');

          this.dashboardImprensaService
            .sentimentoPorEmpresa(id_grupo[0], request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorEmpresa = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                if (error.error.message.includes('Não há')) {
                  this.qtdEmpresaSentimentoPorEmpresa = true;
                  resolve(true);
                  return;
                }
                this.erroNoticiaPorEmpresa = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
        }),
        new Promise((resolve, reject) => {
          this.erroSentimentoPorTempoMes = false;
          let newRequest = this.request;
          if (
            // this.difDays > 31 &&
            newRequest.includes('data_inicio') &&
            newRequest.includes('data_fim') &&
            !(
              new Date(this.filterSelected.dataInicial).getFullYear() <
              new Date().getFullYear()
            )
          ) {
            newRequest = newRequest.replace(
              `data_inicio=${this.filterSelected.dataInicial}&`,
              ''
            );
            newRequest = newRequest.replace(
              `data_fim=${this.filterSelected.dataFinal}`,
              ''
            );
          }
          this.dashboardImprensaService
            .sentimentoPorTempo(newRequest)
            .subscribe({
              next: (value) => {
                // if (value.sentimentos) {
                //   this.sentimentoSentimentoPorTempo = value.sentimentos;
                //   this.dataSentimentoPorTempo = value.datas;
                //   this.colorSentimentoTempo = this.setColorSentimento(
                //     this.sentimentoSentimentoPorTempo
                //   );
                // } else {
                // this.erroSentimentoPorTempo = false;
                this.dataSentimentoPorTempoMes = value;
                // }
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroSentimentoPorTempoMes = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroMidiaPorTempoMes = false;
          let newRequest = this.request;
          if (
            // this.difDays > 31 &&
            newRequest.includes('data_inicio') &&
            newRequest.includes('data_fim') &&
            !(
              new Date(this.filterSelected.dataInicial).getFullYear() <
              new Date().getFullYear()
            )
          ) {
            newRequest = newRequest.replace(
              `data_inicio=${this.filterSelected.dataInicial}&`,
              ''
            );
            newRequest = newRequest.replace(
              `data_fim=${this.filterSelected.dataFinal}`,
              ''
            );
          }
          this.dashboardImprensaService.midiaPorTempo(newRequest).subscribe({
            next: (value) => {
              // if (value.midias) {
              //   this.midiaMidiaPorTempo = value.midias;
              //   this.dataMidiaPorTempo = value.datas;
              //   this.colorMidiaTempo = this.setColorMidia(
              //     this.midiaMidiaPorTempo
              //   );
              // } else {
              // this.erroMidiaPorTempo = false;
              this.dataMidiaPorTempoMes = value;
              this.colorMidiaTempo = this.setColorMidiaMensal(
                this.dataMidiaPorTempoMes?.quantidades
              );
              // }
              resolve(true);
            },
            error: (error: HttpErrorResponse) => {
              this.erroMidiaPorTempoMes = true;
              reject(error);
            },
          });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorSubCategoria = false;
          this.dashboardImprensaService
            .noticiaPorSubCategoria(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSubCategoria = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorSubCategoria = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorPauta = false;
          if (!this.permissionCharts.includes('Notícias por Pauta')) {
            resolve(true);
            return;
          } else {
            this.dashboardImprensaService
              .noticiaPorPauta(this.request)
              .subscribe({
                next: (value) => {
                  this.dataNoticiaPorPauta = value;

                  this.colorPauta = this.setColorPauta(
                    this.dataNoticiaPorPauta
                  );
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroNoticiaPorPauta = true;
                  reject(error);
                },
              });
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          if (this.difDays < 28) {
            this.erroNoticiaPorSentimentoDiario = false;
            this.dashboardImprensaService
              .noticiaPorSentimentoDiario(this.request)
              .subscribe({
                next: (value) => {
                  this.dataNoticiaPorSentimentoDiario = value;
                  resolve(true);
                },
                error: (error: HttpErrorResponse) => {
                  this.erroNoticiaPorSentimentoDiario = true;
                  reject(error);
                },
              });
          } else {
            this.dataNoticiaPorSentimentoDiario = null;
            resolve(true);
            return;
          }
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroDetratores = false;
          this.dashboardImprensaService
            .sentimentoDetratores(this.request)
            .subscribe({
              next: (value) => {
                this.dataDetratores = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroDetratores = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroEvangelizadores = false;
          this.dashboardImprensaService
            .sentimentoEvangelizadores(this.request)
            .subscribe({
              next: (value) => {
                this.dataEvangelizadores = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroEvangelizadores = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
      ]);
      Swal.close();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Houve um erro ao carregar os filtros',
        text: 'Por favor, tente novamente.',
        confirmButtonColor: 'orange',
      });
    }
  }

  addFilterTrigger(event: any, nome: keyof filterSelected) {
    if (event.isUserInput == false) return;
    if (event.source.selected == true) {
      if (this.filterTrigger[nome] == undefined) this.filterTrigger[nome] = '';

      this.filterTrigger[nome] = this.filterTrigger[nome].concat(
        `${event.source.value}, `
      );
    } else {
      this.filterTrigger[nome] = this.filterTrigger[nome].replace(
        `${event.source.value}, `,
        ''
      );
    }
  }

  async addFiltroSelect(
    event: any,
    nome: keyof filterSelected,
    id: any,
    categoriaPai: any = null
  ) {
    this.addFilterTrigger(event, nome);

    // SE O FILTRO NÃO EXISTIR, CRIAR
    if (this.filterSelected[nome] == undefined) this.filterSelected[nome] = [];

    // SE NÃO FOR UMA AÇÃO DO USUÁRIO, NÃO FAZER NADA
    if (event.isUserInput == false) return;

    this.disableFilterButton = true;
    // SE O FILTRO FOR O DE CATEGORIA
    if (nome == 'categoria') {
      this.hasAlteracaoFiltroCategoria = true;
      // SE FOR UM EVENTO DE SELEÇÃO
      if (event.source.selected == true) {
        if (categoriaPai == null) {
          this.filterSelected.categoria.push(id);
        } else {
          if (this.filterSelected.subCategoria == undefined)
            this.filterSelected.subCategoria = [];
          this.filterSelected.subCategoria.push(id);
        }
      }

      // SE FOR UM EVENTO DE DESELEÇÃO
      else {
        if (categoriaPai == null) {
          this.filterSelected.categoria.splice(
            this.filterSelected.categoria.indexOf(id),
            1
          );
        } else {
          this.filterSelected.subCategoria.splice(
            this.filterSelected.subCategoria.indexOf(id),
            1
          );
        }
      }
    } else {
      if (nome == 'grupo') this.hasAlteracaoFiltroGrupo = true;

      if (nome == 'empresa') this.hasAlteracaoFiltroEmpresa = true;

      if (nome == 'veiculo') this.hasAlteracaoFiltroVeiculo = true;

      if (nome == 'midia') this.hasAlteracaoFiltroMidia = true;

      if (nome == 'sentimento') this.hasAlteracaoFiltroSentimento = true;

      if (nome == 'pauta') this.hasAlteracaoFiltroPauta = true;

      if (nome == 'subGrupo') this.hasAlteracaoFiltroSubGrupo = true;

      if (event.source.selected == true) {
        if (nome == 'tipoInformacao') {
          this.hasAlteracaoFiltroInformacao = true;
          this.filterSelected.tipoInformacao = id;
          this.filterTrigger.tipoInformacao = event.source.value;
        } else this.filterSelected[nome].push(id);
      } else {
        this.filterSelected[nome].splice(
          this.filterSelected[nome].indexOf(id),
          1
        );
      }
    }
  }

  addFiltroInput(input: string) {
    if (
      this.filterForm.controls[input as keyof typeof this.filterForm.controls]
        .value == ''
    ) {
      this.filterForm.controls[
        input as keyof typeof this.filterForm.controls
      ].setValue(null);

      if (input == 'dataInicial') {
        this.minDate = <Date>{};
      }
      if (input == 'dataFinal') {
        this.maxDate = new Date();
      }

      this.filterSelected[input as keyof filterSelected] = undefined;
    } else {
      if (input == 'titulo') {
        this.filterSelected[input as keyof filterSelected] =
          this.filterForm.controls[
            input as keyof typeof this.filterForm.controls
          ].value;
      }
    }
  }

  async interfiltragem(filtro: keyof typeof this.filterForm.controls) {
    this.filterForm.controls.searchTextboxCategoria.setValue(null);
    this.filterForm.controls.searchTextboxGrupo.setValue(null);
    this.filterForm.controls.searchTextboxEmpresa.setValue(null);
    this.filterForm.controls.searchTextboxMidia.setValue(null);
    this.filterForm.controls.searchTextboxVeiculo.setValue(null);
    this.filterForm.controls.searchTextboxSentimento.setValue(null);
    this.filterForm.controls.searchTextboxSubGrupo.setValue(null);
    this.filterForm.controls.searchTextboxPauta.setValue(null);

    if (filtro == 'tipoInformacao' && this.hasAlteracaoFiltroInformacao) {
      this.filterSelected.categoria = [];
      this.filterSelected.grupo = [];
      this.filterSelected.empresa = [];
      this.filterSelected.sentimento = [];
      this.filterSelected.subCategoria = [];
      this.filterSelected.subGrupo = [];
      this.filterSelected.pauta = [];
      this.filterSelected.midia = [];
      this.filterSelected.veiculo = [];

      this.completeGrupo.options.forEach((element) => {
        element.deselect();
      });
      this.completeEmpresa.options.forEach((element) => {
        element.deselect();
      });
      this.completeMidia.options.forEach((element) => {
        element.deselect();
      });
      this.completeVeiculo.options.forEach((element) => {
        element.deselect();
      });
      this.completeCategoria.options.forEach((element) => {
        element.deselect();
      });
      this.completeSentimento.options.forEach((element) => {
        element.deselect();
      });
      this.completePauta.options.forEach((element) => {
        element.deselect();
      });
      if (this.permissaoSuperintendencia) {
        this.completeSubGrupo.options.forEach((element) => {
          element.deselect();
        });
      }

      this.filterTrigger.categoria = '';
      this.filterTrigger.grupo = '';
      this.filterTrigger.empresa = '';
      this.filterTrigger.sentimento = '';
      this.filterTrigger.subCategoria = '';
      this.filterTrigger.subGrupo = '';
      this.filterTrigger.pauta = '';
      this.filterTrigger.midia = '';
      this.filterTrigger.veiculo = '';

      this.disableFiltrosFunc();

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.pautas = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroInformacao = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'grupos' && this.hasAlteracaoFiltroGrupo) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.pautas = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroGrupo = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'empresas' && this.hasAlteracaoFiltroEmpresa) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.pautas = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroEmpresa = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'midias' && this.hasAlteracaoFiltroMidia) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.pautas = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroMidia = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'veiculos' && this.hasAlteracaoFiltroVeiculo) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.categorias = [];
      this.sentimentos = [];
      this.pautas = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroVeiculo = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'categorias' && this.hasAlteracaoFiltroCategoria) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.sentimentos = [];
      this.pautas = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroCategoria = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'sentimentos' && this.hasAlteracaoFiltroSentimento) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.pautas = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroSentimento = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'pautas' && this.hasAlteracaoFiltroPauta) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroPauta = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'subGrupos' && this.hasAlteracaoFiltroSubGrupo) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.pautas = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_pautas(this.criarStringRequest({ pauta: true })),
      ]).catch((error: HttpErrorResponse) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
        throw error;
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroSubGrupo = false;
      this.carregandoFiltros = false;
    }
    this.cdr.detectChanges();
  }

  desabilitarFiltro(filterForm: FormControl) {
    filterForm.disable();
  }

  habilitarFiltro(filterForm: FormControl) {
    filterForm.enable();
  }

  // ----------------------------------Categoria------------------------------------------------

  filterCategoria(name: string): Categoria[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.categoria) {
      this.filterForm.controls.categorias.patchValue(
        this.filterTrigger.categoria.split(', ')
      );
    }

    let filteredList = this.categorias.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_categorias(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.categoriaService.listarCategorias(1, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.categorias.push(element);
          });

          this.filteredOptionsCategorias =
            this.filterForm.controls.searchTextboxCategoria.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterCategoria(name || '')) // Handle null value by providing an empty string
            );

          if (this.categorias.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.categorias);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.categorias);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      this.erroCarregamentoFiltros = true;
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // ----------------------------------Grupo----------------------------------------------

  filterGrupo(name: string): Grupo[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.grupo) {
      this.filterForm.controls.grupos.patchValue(
        this.filterTrigger.grupo.split(', ')
      );
    }

    let filteredList = this.grupos.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_grupos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.grupoService.listarGrupos(filtroRequest, 1).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.grupos.push(element);
          });
          this.filteredOptionsGrupos =
            this.filterForm.controls.searchTextboxGrupo.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterGrupo(name || '')) // Handle null value by providing an empty string
            );

          if (this.grupos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.grupos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.grupos);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      this.erroCarregamentoFiltros = true;
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // ----------------------------------Empresa-----------------------------------------------

  filterEmpresa(name: string): Empresa[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.empresa) {
      this.filterForm.controls.empresas.patchValue(
        this.filterTrigger.empresa.split(', ')
      );
    }

    let filteredList = this.empresas.filter((option) =>
      option.nomefantasia.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_empresas(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.empresaService.listarEmpresas(1, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.empresas.push(element);
          });

          this.filteredOptionsEmpresas =
            this.filterForm.controls.searchTextboxEmpresa.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterEmpresa(name || '')) // Handle null value by providing an empty string
            );

          if (this.empresas.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.empresas);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.empresas);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      this.erroCarregamentoFiltros = true;
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // ----------------------------------Sentimento-----------------------------------------------

  filterSentimento(name: string): Sentimento[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.sentimento) {
      this.filterForm.controls.sentimentos.patchValue(
        this.filterTrigger.sentimento.split(', ')
      );
    }

    let filteredList = this.sentimentos.filter((option) =>
      option.sentimento.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_sentimentos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.sentimentoService.listarSentimentos(1, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.sentimentos.push(element);
          });

          this.filteredOptionsSentimentos =
            this.filterForm.controls.searchTextboxSentimento.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterSentimento(name || '')) // Handle null value by providing an empty string
            );

          if (this.sentimentos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.sentimentos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.sentimentos);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      this.erroCarregamentoFiltros = true;
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // ----------------------------------Pauta-----------------------------------------------

  filterPauta(name: string): Pauta[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.pauta) {
      this.filterForm.controls.pautas.patchValue(
        this.filterTrigger.pauta.split(', ')
      );
    }

    let filteredList = this.pautas.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_pautas(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.pautaService.listarPautas(1, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.pautas.push(element);
          });

          this.filteredOptionsPautas =
            this.filterForm.controls.searchTextboxPauta.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterPauta(name || '')) // Handle null value by providing an empty string
            );

          if (this.pautas.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.pautas);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.pautas);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      this.erroCarregamentoFiltros = true;
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // ----------------------------------Midia-----------------------------------------------

  filterMidia(name: string): Midia[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.midia) {
      this.filterForm.controls.midias.patchValue(
        this.filterTrigger.midia.split(', ')
      );
    }

    let filteredList = this.midias.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_midias(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.midiaService.listarMidias(1, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.midias.push(element);
          });

          this.filteredOptionsMidias =
            this.filterForm.controls.searchTextboxMidia.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterMidia(name || '')) // Handle null value by providing an empty string
            );

          if (this.midias.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.midias);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.midias);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      this.erroCarregamentoFiltros = true;
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // ----------------------------------Veiculo-----------------------------------------------

  filterVeiculo(name: string): Veiculo[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.veiculo) {
      this.filterForm.controls.veiculos.patchValue(
        this.filterTrigger.veiculo.split(', ')
      );
    }

    let filteredList = this.veiculos.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_veiculos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.veiculoService.listarVeiculos(1, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.veiculos.push(element);
          });

          this.filteredOptionsVeiculos =
            this.filterForm.controls.searchTextboxVeiculo.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterVeiculo(name || '')) // Handle null value by providing an empty string
            );

          if (this.veiculos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.veiculos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.veiculos);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      this.erroCarregamentoFiltros = true;
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // ----------------------------------SubGrupo------------------------------------------------

  filterSubGrupo(name: string): SubGrupo[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.subGrupo) {
      this.filterForm.controls.subGrupos.patchValue(
        this.filterTrigger.subGrupo.split(', ')
      );
    }

    let filteredList = this.subGrupos.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_subGrupos(grupos: string = '') {
    if (!grupos.includes('id_grupo')) {
      return;
    } else {
      return new Promise((resolve, reject) => {
        this.subGrupoService.listarSubGrupos(1, grupos).subscribe({
          next: (value) => {
            value.forEach((element) => {
              this.subGrupos.push(element);
            });

            this.filteredOptionsSubGrupos =
              this.filterForm.controls.searchTextboxSubGrupo.valueChanges.pipe(
                startWith<string | null>(''),
                map((name: string | null) => this.filterSubGrupo(name || '')) // Handle null value by providing an empty string
              );

            if (this.subGrupos.length > 0) {
              this.habilitarFiltro(this.filterForm.controls.subGrupos);
            } else {
              this.desabilitarFiltro(this.filterForm.controls.subGrupos);
            }

            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
      }).catch((error: HttpErrorResponse) => {
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          captureException(error);
        }
        throw error;
      });
    }
  }

  // ----------------------------------Tipo Informação------------------------------------------------

  filterTipoInformacao(name: string): TipoInformacao[] {
    const filterValue = name.toLowerCase();

    let filteredList = this.tiposInformacao.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_tipoInformacao() {
    return new Promise((resolve, reject) => {
      this.tipoInformacaoService.listarTiposInformacao(1).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.tiposInformacao.push(element);
          });

          this.filteredOptionsTiposInformacao =
            this.filterForm.controls.searchTextboxTipoInformacao.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) =>
                this.filterTipoInformacao(name || '')
              ) // Handle null value by providing an empty string
            );

          if (this.tiposInformacao.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.tipoInformacao);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.tipoInformacao);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      this.erroCarregamentoFiltros = true;
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // ----------------------------------Cores------------------------------------------------
  setColorSentimento(matriz: any[][] | null): Color[] {
    const colors: Color[] = [];
    if (matriz == null) return colors;
    matriz.forEach((element) => {
      if (element[0] === 'POSITIVA') {
        colors.push(this.coresSentimento[0]);
      }
      if (element[0] === 'NEUTRA') {
        colors.push(this.coresSentimento[1]);
      }
      if (element[0] === 'NEGATIVA') {
        colors.push(this.coresSentimento[2]);
      }
      if (element[0] === 'PUBLICIDADE') {
        colors.push(this.coresSentimento[3]);
      }
    });
    return colors;
  }

  setColorMidia(matriz: any[][] | null): Color[] {
    const colors: Color[] = [];
    if (matriz == null) return colors;
    matriz.forEach((element) => {
      if (element[0] === 'Site') {
        colors.push('#9a7fd1');
      }
      if (element[0] === 'TV') {
        colors.push('#e28fab');
      }
      if (element[0] === 'Rádio') {
        colors.push('#6E9CD2');
      }
      if (element[0] === 'Impresso') {
        colors.push('#b4897f');
      }
      if (element[0] === 'Total') {
        colors.push('#EB858B');
      }
    });
    return colors;
  }

  setColorMidiaMensal(
    midias:
      | {
          midia: string;
          quantidade: number[];
        }[]
      | undefined
  ): Color[] {
    const colors: Color[] = [];
    if (midias == null) return colors;
    midias.forEach((midia) => {
      if (midia.midia === 'Site') {
        colors.push('#9a7fd1');
      }
      if (midia.midia === 'TV') {
        colors.push('#e28fab');
      }
      if (midia.midia === 'Rádio') {
        colors.push('#6E9CD2');
      }
      if (midia.midia === 'Impresso') {
        colors.push('#b4897f');
      }
    });
    return colors;
  }

  setColorPauta(matriz: any[][] | null): Color[] {
    const colors: Color[] = [];
    if (matriz == null) return colors;
    matriz.forEach((element) => {
      if (element[0] === 'Espontânea') {
        colors.push('#fb8351');
      }
      if (element[0] === 'Provocada') {
        colors.push('#493d3f');
      }
      if (element[0] === 'Produzida') {
        colors.push('#c0b6ac');
      }
    });
    return colors;
  }

  // ----------------------------------PDF Charts------------------------------------------------
  async gerarPDF() {
    Swal.fire({
      background: '#ffffff00',
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          let doc: jsPDF = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [1020, 640],
          });

          // Adicionar Capa
          const capaImage = '../../assets/logo_clipei_novo.png'; // Caminho para a imagem da capa
          const tituloRelatorio = 'RELATÓRIO DE IMPRENSA'; // Título do Relatório
          let grupos = '';

          if (this.empresaspdf !== undefined && this.empresaspdf !== '') {
            grupos = this.empresaspdf.slice(0, -2);
          } else if (this.grupospdf !== undefined && this.grupospdf !== '') {
            grupos = this.grupospdf.slice(0, -2);
          } else {
            this.filteredOptionsGrupos
              ?.pipe(
                map((grupos: Grupo[]) => {
                  return grupos.map((element: Grupo) => element.descricao);
                })
              )
              .subscribe((descricaoGrupos: string[]) => {
                grupos = descricaoGrupos.join(', ');
              });
          }

          const datafinal = this.moment(
            this.filterForm.controls.dataFinal.value
          ).toDate();
          const datainicial = this.moment(
            this.filterForm.controls.dataInicial.value
          ).toDate();
          const dataRelatorio = `${datainicial.getDate()}.${
            this.meses[datainicial.getMonth() + 1]
          }.${datainicial.getFullYear()} A ${datafinal.getDate()}.${
            this.meses[datafinal.getMonth() + 1]
          }.${datafinal.getFullYear()}`;
          // Data do Relatório

          doc.addImage(capaImage, 'PNG', 50, 200, 400, 176);

          // Adicionar título do relatório
          doc.setFont('helvetica');
          doc.setFontSize(40);

          doc.text(tituloRelatorio, doc.internal.pageSize.width - 30, 400, {
            align: 'right',
          });

          doc.setFont('helvetica', 'bold');
          doc.setFontSize(30);
          doc.setTextColor('#000000'); // cor preta
          // Dividindo o texto em várias linhas
          const textoDividido = doc.splitTextToSize(grupos, 700);
          let alturagrupo = 410;
          // Desenhando cada linha individualmente
          for (let i = 0; i < textoDividido.length; i++) {
            alturagrupo += 25;
            doc.text(
              textoDividido[i].normalize('NFC'),
              doc.internal.pageSize.width - 30,
              alturagrupo,
              { align: 'right' }
            ); // Incremento de 25 para o espaçamento entre as linhas
          }

          // Adicionar data
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(30);
          doc.setTextColor('#000000'); // cor preta
          doc.text(
            dataRelatorio,
            doc.internal.pageSize.width - 30,
            alturagrupo + 35,
            {
              align: 'right',
            }
          );

          let chartsArray = this.charts.toArray();

          if (
            this.difDays >= 28 &&
            chartsArray.some(
              (item) =>
                item.title === 'Notícias por Classificação dos Últimos 7 dias'
            )
          ) {
            chartsArray = chartsArray.filter(
              (item) =>
                item.title !== 'Notícias por Classificação dos Últimos 7 dias'
            );
          }

          for (let index = 0; index < chartsArray.length; index++) {
            // Convert QueryList to an array
            await chartsArray[index].imageOutput();
            if (
              index == 1 &&
              this.hasNoticiasGauche &&
              !(
                !this.dataNoticiaPorSentimento && !this.erroNoticiaPorSentimento
              )
            ) {
              doc.addImage(
                '../../assets/Info_gauge.jpg',
                'PNG',
                160,
                500,
                700,
                123
              );
            }
            doc.addPage();

            if (doc) {
              doc.addImage(
                this.chartDataPDF.dataURL,
                'PNG',
                10,
                10,
                this.chartDataPDF.imgWidth,
                this.chartDataPDF.imgHeight
              );
              doc.addImage(
                '../../assets/logo_clipei.png',
                'PNG',
                895,
                585,
                100,
                50
              );
            }
          }

          setTimeout(() => {
            if (doc) {
              doc.save('Relatorio_de_Imprensa.pdf');
            }
            Swal.close();
          }, 2000);
        } catch (error) {
          captureException(error);
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao gerar o PDF',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      },
    });
  }

  handleChartImage(chartImageObj: {
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }): void {
    const { dataURL, imgWidth, imgHeight } = chartImageObj;
    this.chartDataPDF.dataURL = dataURL;
    this.chartDataPDF.imgWidth = imgWidth;
    this.chartDataPDF.imgHeight = imgHeight;
  }

  handleCountData(countData: number): void {
    this.length = countData;
  }

  handleLoadingCount(loadindLength: boolean): void {
    this.carregandoLength = loadindLength;
  }

  cancelarCarregamentoFiltros() {
    this.tipoInformacaoService.cancelarChamada();
    this.grupoService.cancelarChamada();
    this.empresaService.cancelarChamada();
    this.midiaService.cancelarChamada();
    this.veiculoService.cancelarChamada();
    this.categoriaService.cancelarChamada();
    this.sentimentoService.cancelarChamada();
    this.pautaService.cancelarChamada();
    this.subGrupoService.cancelarChamada();
    this.partidoService.cancelarChamada();
    this.autoridadeService.cancelarChamada();
  }

  async recarregarFiltros() {
    this.panelExpansion = true;
    this.cancelarCarregamentoFiltros();

    this.erroCarregamentoFiltros = false;
    this.carregandoFiltros = true;
    this.carregandoFiltrosInit = true;

    this.filterSelected = {} as filterSelected;

    this.filterSelected.tipoInformacao = 1;
    this.minDate = <Date>{};
    this.maxDate = new Date();

    this.completeTipoInformacao.options.forEach((element) => {
      if (element.value == 'Notícias/Publicações') {
        element.select();
      } else {
        element.deselect();
      }
    });

    this.completeGrupo.options.forEach((element) => {
      element.deselect();
    });
    this.completeEmpresa.options.forEach((element) => {
      element.deselect();
    });
    this.completeMidia.options.forEach((element) => {
      element.deselect();
    });
    this.completeVeiculo.options.forEach((element) => {
      element.deselect();
    });
    this.completeCategoria.options.forEach((element) => {
      element.deselect();
    });
    this.completeSentimento.options.forEach((element) => {
      element.deselect();
    });
    this.completePauta.options.forEach((element) => {
      element.deselect();
    });
    if (this.permissaoSuperintendencia) {
      this.completeSubGrupo.options.forEach((element) => {
        element.deselect();
      });
    }
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.filterTrigger.categoria = '';
    this.filterTrigger.grupo = '';
    this.filterTrigger.empresa = '';
    this.filterTrigger.sentimento = '';
    this.filterTrigger.subCategoria = '';
    this.filterTrigger.subGrupo = '';
    this.filterTrigger.pauta = '';
    this.filterTrigger.midia = '';
    this.filterTrigger.veiculo = '';

    this.filterForm.controls.dataInicial.setValue(this.thirtyDaysAgo);
    this.filterForm.controls.dataFinal.setValue(this.todayDate);
    this.filterForm.controls.titulo.setValue(null);
    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);

    this.tiposInformacao = [];
    this.grupos = [];
    this.empresas = [];
    this.midias = [];
    this.veiculos = [];
    this.categorias = [];
    this.sentimentos = [];
    this.pautas = [];
    this.subGrupos = [];

    return Promise.all([
      this.listar_tipoInformacao(),
      this.listar_grupos(this.criarStringRequest({ grupo: true })),
      this.listar_empresas(this.criarStringRequest({ empresa: true })),
      this.listar_midias(this.criarStringRequest({ midia: true })),
      this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
      this.listar_categorias(this.criarStringRequest({ categoria: true })),
      this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
      this.listar_pautas(this.criarStringRequest({ pauta: true })),
      this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
    ])
      .then(() => {
        this.carregandoFiltros = false;
        this.carregandoFiltrosInit = false;
        this.onResize();

        this.completeTipoInformacao.options.forEach((element) => {
          if (element.value == 'Notícias/Publicações') {
            element.select();
          }
        });
      })
      .catch(() => {
        this.erroCarregamentoFiltros = true;
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao carregar os filtros',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
      });
  }

  // Disable the filter controls
  disableFiltrosFunc() {
    this.filterForm.controls.tipoInformacao.disable();
    this.filterForm.controls.categorias.disable();
    this.filterForm.controls.grupos.disable();
    this.filterForm.controls.empresas.disable();
    this.filterForm.controls.sentimentos.disable();
    this.filterForm.controls.pautas.disable();
    this.filterForm.controls.midias.disable();
    this.filterForm.controls.veiculos.disable();
    this.filterForm.controls.subGrupos.disable();
    this.filterForm.controls.titulo.disable();
    this.disableFiltros = true;
  }

  enableFiltrosFunc() {
    if (this.categorias.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.categorias);
    }
    if (this.grupos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.grupos);
    }
    if (this.empresas.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.empresas);
    }
    if (this.sentimentos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.sentimentos);
    }
    if (this.pautas.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.pautas);
    }
    if (this.midias.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.midias);
    }
    if (this.veiculos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.veiculos);
    }
    if (this.tiposInformacao.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.tipoInformacao);
    }
    // this.filterForm.controls.subGrupos.enable();
    this.filterForm.controls.titulo.enable();
    this.disableFiltros = false;
  }

  recarregarNuvemDePalavras() {
    this.dataNuvemDePalavras = null;
    this.erroNuvemDePalavras = false;
    this.needFilterWordCloud = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .nuvemDePalavras(this.hasFiltroParaNuvem, this.request)
        .subscribe({
          next: (value) => {
            this.dataNuvemDePalavras = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            this.erroNuvemDePalavras = true;
            reject(error);
          },
          complete: () => {
            this.needFilterWordCloud = true;
          },
        });
    }).catch((error: HttpErrorResponse) => {
      captureException(error);
      throw error;
    });
  }

  recarregarNoticiaPorSentimento() {
    this.carregandoSent = true;
    this.dataNoticiaPorSentimento = null;
    this.erroNoticiaPorSentimento = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .noticiaPorSentimento(this.request)
        .subscribe({
          next: (value) => {
            this.dataNoticiaPorSentimento = value;
            this.colorSentimento = this.setColorSentimento(
              this.dataNoticiaPorSentimento
            );
            this.positiva = 0;
            this.negativa = 0;
            this.neutra = 0;
            value.forEach((element: any) => {
              if (element[0] == 'POSITIVA') this.positiva = element[1];
              if (element[0] == 'NEGATIVA') this.negativa = element[1];
              if (element[0] == 'NEUTRA') this.neutra = element[1];
            });
            if (this.positiva + this.negativa > 0) {
              this.dataNoticiaGauge =
                this.positiva / (this.positiva + this.negativa);
            } else if (this.neutra > 0) {
              this.hasNoticiasGauche = false;
            }
            this.carregandoSent = false;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            this.carregandoSent = false;
            captureException(error);
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorSentimento = true;
    });
  }

  recarregarNoticiaPorMidia() {
    this.dataNoticiaPorMidia = null;
    this.erroNoticiaPorMidia = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService.noticiaPorMidia(this.request).subscribe({
        next: (value) => {
          this.dataNoticiaPorMidia = value;
          this.colorMidia = this.setColorMidia(this.dataNoticiaPorMidia);
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch(() => {
      this.erroNoticiaPorMidia = true;
    });
  }

  recarregarNoticiaPorVeiculo() {
    this.dataNoticiaPorVeiculo = null;
    this.erroNoticiaPorVeiculo = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService.noticiaPorVeiculo(this.request).subscribe({
        next: (value) => {
          this.dataNoticiaPorVeiculo = value;
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch(() => {
      this.erroNoticiaPorVeiculo = true;
    });
  }

  recarregarCentrimetragemPorMidiaPositiva() {
    this.dataCentrimetragemPorMidiaPositiva = null;
    this.erroCentrimetragemPorMidiaPositiva = false;
    new Promise((resolve, reject) => {
      if (
        !this.permissionCharts.includes(
          'Centimetragem Positiva por Tipos de Mídia'
        )
      ) {
        resolve(true);
        return;
      } else if (
        this.filterSelected.sentimento !== undefined &&
        this.filterSelected.sentimento.length !== 0 &&
        !this.filterSelected.sentimento.includes('POSITIVA')
      ) {
        this.dataCentrimetragemPorMidiaPositiva = [];
        resolve(true);
        return;
      } else {
        let newRequest = this.request;

        newRequest = this.request.concat(`&sentimento=POSITIVA`);

        this.dashboardImprensaService
          .centrimetragemPorMidia(newRequest)
          .subscribe({
            next: (value) => {
              this.dataCentrimetragemPorMidiaPositiva = value;
              value.forEach((element: any) => {
                if (element[0] == 'Total') {
                  if (this.maxValueCentimetragem < element[1]) {
                    this.maxValueCentimetragem = element[1];
                  }
                }
              }),
                (this.colorCentrimetragemPositiva = this.setColorMidia(
                  this.dataCentrimetragemPorMidiaPositiva
                ));
              resolve(true);
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            },
          });
      }
    }).catch(() => {
      this.erroCentrimetragemPorMidiaPositiva = true;
    });
  }

  recarregarCentrimetragemPorMidiaNeutra() {
    this.dataCentrimetragemPorMidiaNeutra = null;
    this.erroCentrimetragemPorMidiaNeutra = false;
    new Promise((resolve, reject) => {
      let newRequest = this.request;

      newRequest = this.request.concat(`&sentimento=NEUTRA`);

      this.dashboardImprensaService
        .centrimetragemPorMidia(newRequest)
        .subscribe({
          next: (value) => {
            this.dataCentrimetragemPorMidiaNeutra = value;
            value.forEach((element: any) => {
              if (element[0] == 'Total') {
                if (this.maxValueCentimetragem < element[1]) {
                  this.maxValueCentimetragem = element[1];
                }
              }
            });
            this.colorCentrimetragemNeutra = this.setColorMidia(
              this.dataCentrimetragemPorMidiaNeutra
            );
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroCentrimetragemPorMidiaNeutra = true;
    });
  }

  recarregarCentrimetragemPorVeiculoPositiva() {
    this.dataCentrimetragemPorVeiculoPositiva = null;
    this.erroCentrimetragemPorVeiculoPositiva = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .centrimetragemPorVeiculoPositiva(this.request)
        .subscribe({
          next: (value) => {
            this.dataCentrimetragemPorVeiculoPositiva = value;

            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroCentrimetragemPorVeiculoPositiva = true;
    });
  }

  recarregarCentrimetragemPorVeiculoNeutra() {
    this.dataCentrimetragemPorVeiculoNeutra = null;
    this.erroCentrimetragemPorVeiculoNeutra = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .centrimetragemPorVeiculoNeutra(this.request)
        .subscribe({
          next: (value) => {
            this.dataCentrimetragemPorVeiculoNeutra = value;

            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroCentrimetragemPorVeiculoNeutra = true;
    });
  }

  recarregarSentimentoPorCategoria() {
    this.dataSentimentoPorCategoria = null;
    this.erroNoticiaPorCategoria = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .sentimentoPorCategoria(this.request)
        .subscribe({
          next: (value) => {
            this.dataSentimentoPorCategoria = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorCategoria = true;
    });
  }

  recarregarSentimentoPorDuracao() {
    this.dataSentimentoPorDuracao = null;
    this.erroNoticiaPorDuracao = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .sentimentoPorDuracao(this.request)
        .subscribe({
          next: (value) => {
            this.dataSentimentoPorDuracao = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorDuracao = true;
    });
  }

  recarregarSentimentoPorEmpresa() {
    this.dataSentimentoPorEmpresa = null;
    this.erroNoticiaPorEmpresa = false;
    this.qtdGrupoSentimentoPorEmpresa = false;
    this.qtdEmpresaSentimentoPorEmpresa = false;

    let id_grupo: number[] = [];
    // Adicionando os grupos no array
    this.request.split('&').forEach((element) => {
      if (element.includes('id_grupo')) {
        const number = element.replace('id_grupo=', '').split(',');
        number.forEach((element) => {
          id_grupo.push(parseInt(element));
        });
      }
    });
    // Verificando se existe mais de um grupo selecionado
    if (id_grupo.length === 0 || id_grupo.length > 1) {
      this.qtdGrupoSentimentoPorEmpresa = true;
      return;
    }

    // Removendo o id_grupo da request
    const request = this.request.replace(`id_grupo=${id_grupo}`, '');
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .sentimentoPorEmpresa(id_grupo[0], request)
        .subscribe({
          next: (value) => {
            this.dataSentimentoPorEmpresa = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            if (error.error.message.includes('Não há')) {
              this.qtdEmpresaSentimentoPorEmpresa = true;
              resolve(true);
              return;
            }
            reject(error);
          },
        });
    }).catch((error: HttpErrorResponse) => {
      this.erroNoticiaPorEmpresa = true;
      throw error;
    });
  }

  recarregarSentimentoPorTempo() {
    this.dataSentimentoPorTempoMes = null;
    this.erroSentimentoPorTempoMes = false;
    new Promise((resolve, reject) => {
      let newRequest = this.request;
      // Os filtros de data são removidos, pois o gráfico de sentimento por tempo não é restringido pelos filtros de data
      // Entretanto essa funcionalidade é apenas do relatório de Imprensa, então esse ajuste é feito apenas para ele
      if (
        // this.difDays > 30 &&
        newRequest.includes('data_inicio') &&
        newRequest.includes('data_fim') &&
        !(
          new Date(this.filterSelected.dataInicial).getFullYear() <
          new Date().getFullYear()
        )
      ) {
        newRequest = newRequest.replace(
          `data_inicio=${this.filterSelected.dataInicial}&`,
          ''
        );
        newRequest = newRequest.replace(
          `data_fim=${this.filterSelected.dataFinal}`,
          ''
        );
      }
      this.dashboardImprensaService.sentimentoPorTempo(newRequest).subscribe({
        next: (value) => {
          // if (value.sentimentos) {
          //   this.sentimentoSentimentoPorTempo = value.sentimentos;
          //   this.dataSentimentoPorTempo = value.datas;
          //   this.colorSentimentoTempo = this.setColorSentimento(
          //     this.sentimentoSentimentoPorTempo
          //   );
          // } else {
          // this.erroSentimentoPorTempo = false;
          this.dataSentimentoPorTempoMes = value;
          // }
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch(() => {
      // this.erroSentimentoPorTempo = true;
      this.erroSentimentoPorTempoMes = true;
    });
  }

  recarregarMidiaPorTempo() {
    this.dataMidiaPorTempoMes = null;
    this.erroMidiaPorTempoMes = false;
    new Promise((resolve, reject) => {
      let newRequest = this.request;
      if (
        // this.difDays > 31 &&
        newRequest.includes('data_inicio') &&
        newRequest.includes('data_fim') &&
        !(
          new Date(this.filterSelected.dataInicial).getFullYear() <
          new Date().getFullYear()
        )
      ) {
        newRequest = newRequest.replace(
          `data_inicio=${this.filterSelected.dataInicial}&`,
          ''
        );
        newRequest = newRequest.replace(
          `data_fim=${this.filterSelected.dataFinal}`,
          ''
        );
      }
      this.dashboardImprensaService.midiaPorTempo(newRequest).subscribe({
        next: (value) => {
          // if (value.midias) {
          //   this.midiaMidiaPorTempo = value.midias;
          //   this.dataMidiaPorTempo = value.datas;
          //   this.colorMidiaTempo = this.setColorMidia(
          //     this.midiaMidiaPorTempo
          //   );
          // } else {
          // this.erroMidiaPorTempo = false;
          this.dataMidiaPorTempoMes = value;
          this.colorMidiaTempo = this.setColorMidiaMensal(
            this.dataMidiaPorTempoMes?.quantidades
          );
          // }
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch(() => {
      // this.erroMidiaPorTempo = true;
      this.erroMidiaPorTempoMes = true;
    });
  }

  recarregarNoticiaPorSubCategoria() {
    this.dataNoticiaPorSubCategoria = null;
    this.erroNoticiaPorSubCategoria = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .noticiaPorSubCategoria(this.request)
        .subscribe({
          next: (value) => {
            this.dataNoticiaPorSubCategoria = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorSubCategoria = true;
    });
  }

  recarregarNoticiaPorPauta() {
    this.dataNoticiaPorPauta = null;
    this.erroNoticiaPorPauta = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService.noticiaPorPauta(this.request).subscribe({
        next: (value) => {
          this.dataNoticiaPorPauta = value;

          this.colorPauta = this.setColorPauta(this.dataNoticiaPorPauta);
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch(() => {
      this.erroNoticiaPorPauta = true;
    });
  }

  recarregarNoticiaPorSentimentoDiario() {
    this.dataNoticiaPorSentimentoDiario = null;
    this.erroNoticiaPorSentimentoDiario = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .noticiaPorSentimentoDiario(this.request)
        .subscribe({
          next: (value) => {
            this.dataNoticiaPorSentimentoDiario = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorSentimentoDiario = true;
    });
  }

  recarregarSentimentoDetratores() {
    this.dataDetratores = null;
    this.erroDetratores = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .sentimentoDetratores(this.request)
        .subscribe({
          next: (value) => {
            this.dataDetratores = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroDetratores = true;
    });
  }

  recarregarSentimentoEvangelizadores() {
    this.dataEvangelizadores = null;
    this.erroEvangelizadores = false;
    new Promise((resolve, reject) => {
      this.dashboardImprensaService
        .sentimentoEvangelizadores(this.request)
        .subscribe({
          next: (value) => {
            this.dataEvangelizadores = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroEvangelizadores = true;
    });
  }

  formatarNuvemDePalavrasLargura() {
    if (window.innerWidth <= 768) {
      return 1;
    }

    const excludeKeys = [
      'Notícias por Categoria (Top 15)',
      'Notícias por Subcategoria (Top 15)',
      'Notícias por Veículo (Top 15)',
      'Notícias por Classificação (Linhas e Barras)',
      'Notícias por Tipo de Mídia (Linhas e Barras)',
    ];

    let trueCount = this.permissionCharts.filter(
      (key) => !excludeKeys.includes(key)
    ).length;

    if (this.difDays > 28) {
      trueCount--;
    }

    // Verificar se o número de `true` é par ou ímpar
    const isEven = trueCount % 2 === 0;
    if (isEven) {
      return 1;
    } else {
      return 2;
    }
  }
}
