import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import Cryptograf from 'crypto-js';
import { Observable, map, startWith } from 'rxjs';
import { environment } from 'src/environment/environments';

import { Color } from 'echarts';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';

import { AutoridadeService } from 'src/app/core/services/autoridade.service';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { EmpresaService } from 'src/app/core/services/empresa.service';
import { GrupoService } from 'src/app/core/services/grupo.service';
import { MidiaService } from 'src/app/core/services/midia.service';
import { PartidoService } from 'src/app/core/services/partido.service';
import { SentimentoService } from 'src/app/core/services/sentimento.service';
import { TipoInformacaoService } from 'src/app/core/services/tipo-informacao.service';
import { VeiculoService } from 'src/app/core/services/veiculo.service';

import { Autoridade } from 'src/app/core/model/autoridade';
import { Categoria } from 'src/app/core/model/categoria';
import { Empresa } from 'src/app/core/model/empresa';
import { Grupo } from 'src/app/core/model/grupo';
import { Midia } from 'src/app/core/model/midia';
import { Sentimento } from 'src/app/core/model/sentimento';
import { Veiculo } from 'src/app/core/model/veiculo';

import { Partido } from 'src/app/core/model/partido';
import { SubCategoria } from 'src/app/core/model/subcategoria';
import { SubGrupo } from 'src/app/core/model/subgrupo';
import { TipoInformacao } from 'src/app/core/model/tipoInformacao';

import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { captureException } from '@sentry/angular-ivy';
import _moment from 'moment';
import { DashboardComunidadeService } from 'src/app/core/services/dashboard-comunidade.service';
import { DashboardImprensaService } from 'src/app/core/services/dashboard-imprensa.service';
import { DashboardJuridicoService } from 'src/app/core/services/dashboard-juridico.service';
import { DashboardLegislativoService } from 'src/app/core/services/dashboard-legislativo.service';
import { HomeService } from 'src/app/core/services/home.service';
import { PautaService } from 'src/app/core/services/pauta.service';
import { SubGrupoService } from 'src/app/core/services/subgrupo.service';

interface filterSelected {
  categoria: any;
  subCategoria: any;
  empresa: any;
  grupo: any;
  partido: any;
  subGrupo: any;
  tipoInformacao: any;
  sentimento: any;
  autoridade: any;
  midia: any;
  veiculo: any;
  dataInicial: any;
  dataFinal: any;
  titulo: any;
}

interface MesesAssociados {
  [numero: number]: string;
}

interface chartData {
  dataURL: string;
  imgWidth: number;
  imgHeight: number;
}

@Component({
  selector: 'app-juridico',
  templateUrl: './juridico.component.html',
  styleUrls: ['./juridico.component.scss'],
})
export class JuridicoComponent implements OnInit {
  moment = _moment;

  //----------------------------------Variaveis de dados dos gráficos------------------------------------

  @ViewChildren('charts') charts: QueryList<any> = <any>[];
  chartDataPDF: chartData = <any>{}; //variavel que vai receber o dataURL do gráfico e o tamanho

  permissaoSuperintendencia: boolean = false;
  permissionCharts: Array<string> = [];

  dataNoticiaPorCategoria: { categorias: [] } | null = null;
  dataNoticiaPorSubCategoria: { subcategorias: [] } | null = null;
  sentimentoSentimentoPorTempo: Array<any> | null = null;
  dataSentimentoPorTempo: Array<any> | null = null;
  dataNuvemDePalavras: Array<any> | null = null;
  dataNoticiaPorSentimentoDiario: { datas: [] } | null = null;
  dataSentimentoPorOrgao: { orgaos: [] } | null = null;
  orgaosOrgaoPorTempo: Array<any> | null = null;
  dataOrgaoPorTempo: Array<any> | null = null;

  erroNoticiaPorSentimento: boolean = false;
  erroOrgaosPorTempo: boolean = false;
  erroSentimentoPorOrgao: boolean = false;
  erroNoticiaPorCategoria: boolean = false;
  erroTabela: boolean = false;
  erroSentimentoPorTempo: boolean = false;
  erroNoticiaPorSubCategoria: boolean = false;
  erroNoticiaPorSentimentoDiario: boolean = false;
  erroNuvemDePalavras: boolean = false;

  typeSelectedNoticiasPorOrgao: string = 'line';
  typeSelectedNoticiasPorClassificacao: string = 'bar';

  coresSentimento = ['#77C4A8', '#F5C869', '#DD6B7F'];
  colorSentimento = <any>[];
  colorSentimentoTempo = <any>[];
  colorMidia = <any>[];
  colorMidiaTempo = <any>[];
  colorOrgaoTempo = <any>[
    '#6379A4',
    '#A97FD1',
    '#F38E5D',
    '#6F5035',
    '#A9916F',
  ];
  colorCentrimetragem = <any>[];
  colorDetratores = this.coresSentimento[2];
  colorEvangelizadores = this.coresSentimento[0];
  request: string = '';

  //----------------------------------Variaveis da Tabela------------------------------------

  @ViewChild(MatPaginator) paginator: MatPaginator = <any>{};
  @ViewChild(MatSort) sort: MatSort = <any>{};

  carregandoLength = true;
  carregandoSent = true;
  positiva = 0;
  negativa = 0;
  neutra = 0;
  length = 0;
  pageIndex = 1;
  pageEvent: PageEvent = <any>{};
  dataTabela = <any>[];
  paginaAtual = 1;
  rowsPerPage = 10;
  ultimaPagina = 1;
  carregandoTabela = true;
  tabelaVazia = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'id',
    'datapubli',
    'grupo',
    'empresa',
    'midia',
    'veiculo',
    'programa',
    'categoria',
    'subcategoria',
    'sentimento',
    'titulo',
  ];

  meses: MesesAssociados = {
    1: 'JANEIRO',
    2: 'FEVEREIRO',
    3: 'MARÇO',
    4: 'ABRIL',
    5: 'MAIO',
    6: 'JUNHO',
    7: 'JULHO',
    8: 'AGOSTO',
    9: 'SETEMBRO',
    10: 'OUTUBRO',
    11: 'NOVEMBRO',
    12: 'DEZEMBRO',
  };
  grupospdf: string = '';
  empresaspdf: string = '';

  //----------------------------------Variaveis dos Filtros------------------------------------

  @ViewChild('completeCategoria') completeCategoria: MatSelect = <any>{};
  @ViewChild('completeGrupo') completeGrupo: MatSelect = <any>{};
  @ViewChild('completePartido') completePartido: MatSelect = <any>{};
  @ViewChild('completeEmpresa') completeEmpresa: MatSelect = <any>{};
  @ViewChild('completeAutoridade') completeAutoridade: MatSelect = <any>{};
  @ViewChild('completeMidia') completeMidia: MatSelect = <any>{};
  @ViewChild('completeVeiculo') completeVeiculo: MatSelect = <any>{};
  @ViewChild('completeSentimento') completeSentimento: MatSelect = <any>{};
  @ViewChild('completeSubGrupo') completeSubGrupo: MatSelect = <any>{};
  @ViewChild('completeTipoInformacao') completeTipoInformacao: MatSelect = <
    any
  >{};

  panelExpansion: boolean = false;
  erroCarregamentoFiltros: boolean = false;

  disableFiltros: boolean = false;
  carregandoFiltros: boolean = false;
  carregandoFiltrosInit: boolean = false;

  hasAlteracaoFiltroGrupo: boolean = false;
  hasAlteracaoFiltroEmpresa: boolean = false;
  hasAlteracaoFiltroInformacao: boolean = false;
  hasAlteracaoFiltroSubGrupo: boolean = false;
  hasAlteracaoFiltroCategoria: boolean = false;
  hasAlteracaoFiltroPartido: boolean = false;
  hasAlteracaoFiltroAutoridade: boolean = false;
  hasAlteracaoFiltroVeiculo: boolean = false;
  hasAlteracaoFiltroMidia: boolean = false;
  hasAlteracaoFiltroSentimento: boolean = false;

  needFilterWordCloud: boolean = false;
  hasFiltroParaNuvem: boolean = false;

  categorias: Array<Categoria> = [];
  grupos: Array<Grupo> = [];
  partidos: Array<Partido> = [];
  empresas: Array<Empresa> = [];
  sentimentos: Array<Sentimento> = [];
  autoridades: Array<Autoridade> = [];
  midias: Array<Midia> = [];
  veiculos: Array<Veiculo> = [];

  subCategorias: Array<SubCategoria> = [];
  subGrupos: Array<SubGrupo> = [];
  tiposInformacao: Array<TipoInformacao> = [];

  filteredOptionsCategorias?: Observable<Categoria[]>;
  filteredOptionsGrupos?: Observable<Grupo[]>;
  filteredOptionsPartidos?: Observable<Partido[]>;
  filteredOptionsEmpresas?: Observable<Empresa[]>;
  filteredOptionsSentimentos?: Observable<Sentimento[]>;
  filteredOptionsAutoridades?: Observable<Autoridade[]>;
  filteredOptionsMidias?: Observable<Midia[]>;
  filteredOptionsVeiculos?: Observable<Veiculo[]>;

  filteredOptionsSubGrupos?: Observable<SubGrupo[]>;
  filteredOptionsTiposInformacao?: Observable<TipoInformacao[]>;

  filterSelected: filterSelected = {} as filterSelected;
  filterTrigger: filterSelected = {} as filterSelected;
  chipShow = <any>[];
  minDate = <Date>{};
  maxDate = new Date();
  todayDate = new Date();
  thirtyDaysAgo = new Date(new Date().setDate(this.todayDate.getDate() - 30));

  filterForm = new FormGroup({
    categorias: new FormControl(''),
    grupos: new FormControl(''),
    partidos: new FormControl(''),
    empresas: new FormControl(''),
    sentimentos: new FormControl(''),
    autoridades: new FormControl(''),
    midias: new FormControl(),
    veiculos: new FormControl(''),
    subGrupos: new FormControl({ value: '', disabled: true }),
    tipoInformacao: new FormControl(''),

    searchTextboxCategoria: new FormControl(''),
    searchTextboxGrupo: new FormControl(''),
    searchTextboxPartido: new FormControl(''),
    searchTextboxEmpresa: new FormControl(''),
    searchTextboxSentimento: new FormControl(''),
    searchTextboxAutoridade: new FormControl(''),
    searchTextboxMidia: new FormControl(''),
    searchTextboxVeiculo: new FormControl(''),
    searchTextboxSubGrupo: new FormControl(''),
    searchTextboxTipoInformacao: new FormControl(''),

    dataInicial: new FormControl<any | null>({
      disabled: true,
      value: this.thirtyDaysAgo,
    }),
    dataFinal: new FormControl<any | null>({
      disabled: true,
      value: this.todayDate,
    }),
    titulo: new FormControl(null),
  });

  //----------------------------------Variaveis de Responsividade------------------------------------
  isTelaMenorQue500px: number = 4;
  numbergraphs: number = 2;
  numbertitle: number = 3;
  responsiveCards: number = 4;
  responsiveNuvem: number = 2;

  constructor(
    private cdr: ChangeDetectorRef,
    private categoriaService: CategoriaService,
    private grupoService: GrupoService,
    private partidoService: PartidoService,
    private empresaService: EmpresaService,
    private sentimentoService: SentimentoService,
    private dashboardComunidadeService: DashboardComunidadeService,
    private dashboardImprensaService: DashboardImprensaService,
    private dashboardLegislativoService: DashboardLegislativoService,
    public dashboardJuridicoService: DashboardJuridicoService,
    private autoridadeService: AutoridadeService,
    private midiaService: MidiaService,
    private veiculoService: VeiculoService,
    private pautaService: PautaService,
    private subGrupoService: SubGrupoService,
    private tipoInformacaoService: TipoInformacaoService,
    private homeService: HomeService,
    public dialog: MatDialog
  ) {
    // Iniciando o dataSource com a tabela vazia
    this.dataSource = new MatTableDataSource(this.dataTabela);

    // Verifica a flag de recarregamento ao inicializar
    if (sessionStorage.getItem('isReloading')) {
      // Remove a flag para que ela não persista após o recarregamento
      sessionStorage.removeItem('isReloading');
    } else {
      // Caso contrário, isso significa que a aba foi fechada e reaberta
      this.clearGrupoInfo ();
    }
  }

  // Função para detectar o fechamento ou recarregamento da aba
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(_: Event): void {
    sessionStorage.setItem('isReloading', 'true');
    return;
  }

  // Função para limpar as informações do grupo do localStorage
  clearGrupoInfo (): void {
    localStorage.removeItem('conteudosGrupo');
    localStorage.removeItem('tokenGrupo');
    localStorage.removeItem('permissionSubgrupoGrupo');
    localStorage.removeItem('permissionChartsGrupo');
  }

  async ngOnInit(): Promise<void> {
    const permSuperin = Cryptograf.AES.decrypt(
      JSON.parse(
        (localStorage.getItem('permissionSubgrupoGrupo')
          ? localStorage.getItem('permissionSubgrupoGrupo')
          : localStorage.getItem('permissionSubgrupo')) || 'null'
      ),
      environment.cryptoKey
    );

    this.permissaoSuperintendencia = JSON.parse(
      permSuperin.toString(Cryptograf.enc.Utf8)
    ).includes(2);

    const permCharts = Cryptograf.AES.decrypt(
      JSON.parse(
        (localStorage.getItem('permissionChartsGrupo')
          ? localStorage.getItem('permissionChartsGrupo')
          : localStorage.getItem('permissionCharts')) || 'null'
      ),
      environment.cryptoKey
    );

    this.permissionCharts = JSON.parse(
      permCharts.toString(Cryptograf.enc.Utf8)
    ).find(
      (chart: { id_conteudo: number; nome: Array<string> }) =>
        chart.id_conteudo === 2
    ).nome;

    this.homeService.enviarVariavel(2);
    this.dashboardImprensaService.cancelarChamada();
    this.dashboardLegislativoService.cancelarChamada();
    this.dashboardComunidadeService.cancelarChamada();
    this.cancelarCarregamentoFiltros();

    this.onResize();

    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);
    this.filterSelected.tipoInformacao = 1;
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.showchips();

    this.request = this.criarStringRequest();

    //-------------Carregamento dos gráficos----------------
    this.carregandoFiltros = true;
    this.carregandoFiltrosInit = true;
    this.needFilterWordCloud = false;
    this.carregandoLength = true;
    this.carregandoSent = true;

    await Promise.all([
      Promise.all([
        this.listar_tipoInformacao(),
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ])
        .then(() => {
          this.erroCarregamentoFiltros = false;
          this.carregandoFiltros = false;
          this.carregandoFiltrosInit = false;
          this.onResize();
          setTimeout(() => {
            this.completeTipoInformacao.options.forEach((element) => {
              if (element.value == 'Notícias/Publicações') {
                element.select();
              }
            });
          }, 100);
        })
        .catch(() => {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }),

      Promise.all([
        new Promise((resolve, reject) => {
          this.dashboardJuridicoService
            .noticiaPorSentimento(this.request)
            .subscribe({
              next: (value) => {
                this.positiva = 0;
                this.negativa = 0;
                this.neutra = 0;
                value.forEach((element: any) => {
                  if (element[0] == 'POSITIVA') this.positiva = element[1];
                  if (element[0] == 'NEGATIVA') this.negativa = element[1];
                  if (element[0] == 'NEUTRA') this.neutra = element[1];
                });

                this.carregandoSent = false;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.carregandoSent = false;
                this.erroNoticiaPorSentimento = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardJuridicoService.orgaosPorTempo(this.request).subscribe({
            next: (value) => {
              this.orgaosOrgaoPorTempo = value.orgaos;
              this.dataOrgaoPorTempo = value.datas;
              resolve(true);
            },
            error: (error: HttpErrorResponse) => {
              this.erroOrgaosPorTempo = true;
              reject(error);
            },
          });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardJuridicoService
            .sentimentoPorOrgao(this.request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorOrgao = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroSentimentoPorOrgao = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardJuridicoService
            .sentimentoPorCategoria(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorCategoria = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorCategoria = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
            this.recarregarNoticiaPorCategoria();
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardJuridicoService
            .sentimentoPorTempo(this.request)
            .subscribe({
              next: (value) => {
                this.sentimentoSentimentoPorTempo = value.sentimentos;
                this.dataSentimentoPorTempo = value.datas;
                this.colorSentimentoTempo = this.setColorSentimento(
                  this.sentimentoSentimentoPorTempo
                );
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroSentimentoPorTempo = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardJuridicoService
            .noticiaPorSubCategoria(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSubCategoria = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorSubCategoria = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardJuridicoService
            .noticiaPorSentimentoDiario(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSentimentoDiario = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorSentimentoDiario = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.dashboardJuridicoService
            .nuvemDePalavras(this.hasFiltroParaNuvem, this.request)
            .subscribe({
              next: (value) => {
                this.dataNuvemDePalavras = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                if (error.status !== 401) {
                  this.erroNuvemDePalavras = true;
                  reject(error);
                  return;
                }
                reject(error);
              },
              complete: () => {
                this.needFilterWordCloud = true;
              },
            });
        }).catch((error: HttpErrorResponse) => {
          captureException(error);
          throw error;
        }),
      ]),
    ]);
  }

  //----------------------------- Responsividade dos filtros ------------------------------------
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth <= 768) {
      this.isTelaMenorQue500px = 1;
      this.numbergraphs = 1;
      this.numbertitle = 1;
      this.responsiveCards = 1;
      this.responsiveNuvem = 1;
    } else {
      this.isTelaMenorQue500px = 4;
      this.numbergraphs = 2;
      this.responsiveCards = 4;
      this.responsiveNuvem = this.formatarNuvemDePalavrasLargura();
      if (this.permissaoSuperintendencia) {
        this.numbertitle = 4;
      } else {
        this.numbertitle = 4;
      }
    }
  }

  // ------------------------------ exibir e esconder os chips ---------------------------------------

  showchips() {
    this.chipShow = [];

    if (
      this.filterTrigger.grupo !== undefined &&
      this.filterTrigger.grupo.length > 0 &&
      this.filterForm.get('grupos')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.grupo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.grupo.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.partido !== undefined &&
      this.filterTrigger.partido.length > 0 &&
      this.filterForm.get('partidos')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.partido.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.partido.split(', ')[i]);
      }
    }

    if (this.filterForm.controls.dataInicial.value !== null) {
      let datainicial = this.moment(
        this.filterForm.controls.dataInicial.value
      ).toDate();
      this.chipShow.push(
        `${datainicial.getDate()}/${
          datainicial.getMonth() + 1
        }/${datainicial.getFullYear()}`
      );
    }

    if (this.filterForm.controls.dataFinal.value !== null) {
      let datafinal = this.moment(
        this.filterForm.controls.dataFinal.value
      ).toDate();
      this.chipShow.push(
        `${datafinal.getDate()}/${
          datafinal.getMonth() + 1
        }/${datafinal.getFullYear()}`
      );
    }

    if (
      this.filterTrigger.empresa !== undefined &&
      this.filterTrigger.empresa.length > 0 &&
      this.filterForm.get('empresas')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.empresa.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.empresa.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.midia !== undefined &&
      this.filterTrigger.midia.length > 0 &&
      this.filterForm.get('midia')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.midia.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.midia.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.veiculo !== undefined &&
      this.filterTrigger.veiculo.length > 0 &&
      this.filterForm.get('veiculo')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.veiculo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.veiculo.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.categoria !== undefined &&
      this.filterTrigger.categoria.length > 0 &&
      this.filterForm.get('categorias')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.categoria.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.categoria.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.sentimento !== undefined &&
      this.filterTrigger.sentimento.length > 0 &&
      this.filterForm.get('sentimento')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.sentimento.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.sentimento.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.autoridade !== undefined &&
      this.filterTrigger.autoridade.length > 0 &&
      this.filterForm.get('autoridade')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.autoridade.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.autoridade.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.subGrupo !== undefined &&
      this.filterTrigger.subGrupo.length > 0 &&
      this.filterForm.get('subGrupos')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.subGrupo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.subGrupo.split(', ')[i]);
      }
    }
    if (
      this.filterTrigger.tipoInformacao !== undefined &&
      this.filterTrigger.tipoInformacao.length > 0 &&
      this.filterForm.get('tipoInformacao')?.errors?.['invalidWord'] !== true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.tipoInformacao.split(', ').length;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.tipoInformacao.split(', ')[i]);
      }
    }

    if (
      this.filterForm.controls.titulo.value !== '' &&
      this.filterForm.controls.titulo.value !== null
    )
      this.chipShow.push(this.filterForm.controls.titulo.value);
  }

  // ------------------------------ funçoes relacionadas aos filtros -----------------------
  async setDate(_: any, onInitiate: boolean = false) {
    const datainicial = this.moment(
      this.filterForm.controls.dataInicial.value
    ).toDate();

    const datafinal = this.moment(
      this.filterForm.controls.dataFinal.value
    ).toDate();
    if (datainicial !== null) {
      this.minDate = new Date(
        datainicial.getFullYear(),
        datainicial.getMonth(),
        datainicial.getDate()
      );
      this.filterSelected.dataInicial = `${datainicial.getFullYear()}-${
        datainicial.getMonth() + 1
      }-${datainicial.getDate()}`;
    }

    if (datafinal !== null) {
      this.maxDate = new Date(
        datafinal.getFullYear(),
        datafinal.getMonth(),
        datafinal.getDate()
      );
      this.filterSelected.dataFinal = `${datafinal.getFullYear()}-${
        datafinal.getMonth() + 1
      }-${datafinal.getDate()}`;
    }
    if (onInitiate == false) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroVeiculo = false;
      this.carregandoFiltros = false;
    }
  }

  validClearFilter() {
    if (
      (this.filterSelected.categoria == undefined ||
        this.filterSelected.categoria.length == 0) &&
      (this.filterSelected.grupo == undefined ||
        this.filterSelected.grupo.length == 0) &&
      (this.filterSelected.partido == undefined ||
        this.filterSelected.partido.length == 0) &&
      (this.filterSelected.empresa == undefined ||
        this.filterSelected.empresa.length == 0) &&
      (this.filterSelected.sentimento == undefined ||
        this.filterSelected.sentimento.length == 0) &&
      (this.filterSelected.subCategoria == undefined ||
        this.filterSelected.subCategoria.length == 0) &&
      (this.filterSelected.subGrupo == undefined ||
        this.filterSelected.subGrupo.length == 0) &&
      (this.filterSelected.autoridade == undefined ||
        this.filterSelected.autoridade.length == 0) &&
      (this.filterSelected.midia == undefined ||
        this.filterSelected.midia.length == 0) &&
      (this.filterSelected.veiculo == undefined ||
        this.filterSelected.veiculo.length == 0) &&
      (this.filterSelected.tipoInformacao == undefined ||
        this.filterSelected.tipoInformacao == 1) &&
      (this.filterForm.controls.titulo.value == '' ||
        this.filterForm.controls.titulo.value == null) &&
      this.filterForm.controls.dataInicial.value == this.thirtyDaysAgo &&
      this.filterForm.controls.dataFinal.value == this.todayDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  async limparFiltros() {
    this.filterSelected = {} as filterSelected;
    this.filterSelected.tipoInformacao = 1;
    this.minDate = <Date>{};
    this.maxDate = new Date();

    this.completeTipoInformacao.options.forEach((element) => {
      if (element.value == 'Notícias/Publicações') {
        element.select();
      } else {
        element.deselect();
      }
    });
    this.completeGrupo.options.forEach((element) => {
      element.deselect();
    });
    this.completePartido.options.forEach((element) => {
      element.deselect();
    });
    this.completeEmpresa.options.forEach((element) => {
      element.deselect();
    });
    this.completeMidia.options.forEach((element) => {
      element.deselect();
    });
    this.completeVeiculo.options.forEach((element) => {
      element.deselect();
    });
    this.completeCategoria.options.forEach((element) => {
      element.deselect();
    });
    this.completeSentimento.options.forEach((element) => {
      element.deselect();
    });
    this.completeAutoridade.options.forEach((element) => {
      element.deselect();
    });
    if (this.permissaoSuperintendencia) {
      this.completeSubGrupo.options.forEach((element) => {
        element.deselect();
      });
    }
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.filterTrigger.categoria = '';
    this.filterTrigger.grupo = '';
    this.filterTrigger.partido = '';
    this.filterTrigger.empresa = '';
    this.filterTrigger.sentimento = '';
    this.filterTrigger.subCategoria = '';
    this.filterTrigger.subGrupo = '';
    this.filterTrigger.autoridade = '';
    this.filterTrigger.midia = '';
    this.filterTrigger.veiculo = '';

    this.filterForm.controls.dataInicial.setValue(this.thirtyDaysAgo);
    this.filterForm.controls.dataFinal.setValue(this.todayDate);
    this.filterForm.controls.titulo.setValue(null);
    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);

    this.disableFiltrosFunc();
    this.carregandoFiltros = true;

    this.grupos = [];
    this.empresas = [];
    this.midias = [];
    this.veiculos = [];
    this.categorias = [];
    this.sentimentos = [];
    this.autoridades = [];
    this.subGrupos = [];
    this.partidos = [];

    this.showchips();
    this.request = this.criarStringRequest();
    await Promise.all([
      this.listar_grupos(this.request),
      this.listar_empresas(this.request),
      this.listar_midias(this.request),
      this.listar_veiculos(this.request),
      this.listar_categorias(this.request),
      this.listar_sentimentos(this.request),
      this.listar_autoridades(this.request),
      this.listar_partidos(this.request),
      this.listar_subGrupos(this.request),
      this.filtrar(),
    ]).then(() => {
      this.enableFiltrosFunc();
      this.carregandoFiltros = false;
    });
  }

  criarStringRequest(
    opcoes: {
      remove?: boolean;
      grupo?: boolean;
      empresa?: boolean;
      midia?: boolean;
      veiculo?: boolean;
      categoria?: boolean;
      sentimento?: boolean;
      autoridade?: boolean;
      subGrupo?: boolean;
      partido?: boolean;
    } = {}
  ) {
    const {
      remove = false,
      grupo = false,
      empresa = false,
      midia = false,
      veiculo = false,
      categoria = false,
      sentimento = false,
      autoridade = false,
      subGrupo = false,
      partido = false,
    } = opcoes;
    let request: string = '';
    this.hasFiltroParaNuvem = false;

    if (
      categoria == false &&
      this.filterSelected.categoria !== undefined &&
      this.filterSelected.categoria.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_categoria=`);
      for (let i = 0; i < this.filterSelected.categoria.length; i++) {
        request = request.concat(`${this.filterSelected.categoria[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      grupo == false &&
      this.filterSelected.grupo !== undefined &&
      this.filterSelected.grupo.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_grupo=`);
      for (let i = 0; i < this.filterSelected.grupo.length; i++) {
        request = request.concat(`${this.filterSelected.grupo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      partido == false &&
      this.filterSelected.partido !== undefined &&
      this.filterSelected.partido.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_partido=`);
      for (let i = 0; i < this.filterSelected.partido.length; i++) {
        request = request.concat(`${this.filterSelected.partido[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      empresa == false &&
      this.filterSelected.empresa !== undefined &&
      this.filterSelected.empresa.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_empresa=`);
      for (let i = 0; i < this.filterSelected.empresa.length; i++) {
        request = request.concat(`${this.filterSelected.empresa[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      sentimento == false &&
      this.filterSelected.sentimento !== undefined &&
      this.filterSelected.sentimento.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`sentimento=`);
      for (let i = 0; i < this.filterSelected.sentimento.length; i++) {
        request = request.concat(`${this.filterSelected.sentimento[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      autoridade == false &&
      this.filterSelected.autoridade !== undefined &&
      this.filterSelected.autoridade.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_autoridade=`);
      for (let i = 0; i < this.filterSelected.autoridade.length; i++) {
        request = request.concat(`${this.filterSelected.autoridade[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      midia == false &&
      this.filterSelected.midia !== undefined &&
      this.filterSelected.midia.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_midia=`);
      for (let i = 0; i < this.filterSelected.midia.length; i++) {
        request = request.concat(`${this.filterSelected.midia[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      veiculo == false &&
      this.filterSelected.veiculo !== undefined &&
      this.filterSelected.veiculo.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_veiculo=`);
      for (let i = 0; i < this.filterSelected.veiculo.length; i++) {
        request = request.concat(`${this.filterSelected.veiculo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      categoria == false &&
      this.filterSelected.subCategoria !== undefined &&
      this.filterSelected.subCategoria.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_subcategoria=`);
      for (let i = 0; i < this.filterSelected.subCategoria.length; i++) {
        request = request.concat(`${this.filterSelected.subCategoria[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      subGrupo == false &&
      this.filterSelected.subGrupo !== undefined &&
      this.filterSelected.subGrupo.length > 0
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`id_sub_grupo=`);
      for (let i = 0; i < this.filterSelected.subGrupo.length; i++) {
        request = request.concat(`${this.filterSelected.subGrupo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (this.filterSelected.tipoInformacao !== undefined) {
      request = request.concat(`informacao=`);
      request = request.concat(`${this.filterSelected.tipoInformacao}`);
      request = request.concat(`&`);
    }

    if (
      this.filterSelected.titulo !== undefined &&
      this.filterSelected.titulo !== '' &&
      this.filterSelected.titulo !== null
    ) {
      this.hasFiltroParaNuvem = true;
      request = request.concat(`titulo=${this.filterSelected.titulo}&`);
    }
    if (this.filterSelected.dataInicial !== undefined && remove == false)
      request = request.concat(
        `data_inicio=${this.filterSelected.dataInicial}&`
      );
    if (this.filterSelected.dataFinal !== undefined && remove == false)
      request = request.concat(`data_fim=${this.filterSelected.dataFinal}&`);

    // Remover o último '&', se existir
    if (request.endsWith('&')) {
      request = request.slice(0, -1);
    }

    return request;
  }

  filtrar() {
    this.showchips();
    this.request = this.criarStringRequest();
    this.dataNoticiaPorCategoria = null;
    this.dataNoticiaPorSubCategoria = null;
    this.sentimentoSentimentoPorTempo = null;
    this.dataSentimentoPorTempo = null;
    this.dataNuvemDePalavras = null;
    this.dataNoticiaPorSentimentoDiario = null;
    this.carregandoTabela = true;
    this.carregandoSent = true;
    this.tabelaVazia = false;
    this.dataSentimentoPorOrgao = null;
    this.dataOrgaoPorTempo = null;
    this.orgaosOrgaoPorTempo = null;
    this.needFilterWordCloud = false;

    if (this.filterTrigger.grupo !== undefined)
      this.grupospdf = this.filterTrigger.grupo;
    if (this.filterTrigger.empresa !== undefined)
      this.empresaspdf = this.filterTrigger.empresa;

    try {
      Promise.all([
        new Promise((resolve, reject) => {
          this.erroNoticiaPorSentimento = false;
          this.dashboardJuridicoService
            .noticiaPorSentimento(this.request)
            .subscribe({
              next: (value) => {
                this.positiva = 0;
                this.negativa = 0;
                this.neutra = 0;
                value.forEach((element: any) => {
                  if (element[0] == 'POSITIVA') this.positiva = element[1];
                  if (element[0] == 'NEGATIVA') this.negativa = element[1];
                  if (element[0] == 'NEUTRA') this.neutra = element[1];
                });

                this.carregandoSent = false;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.carregandoSent = false;
                this.erroNoticiaPorSentimento = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNuvemDePalavras = false;
          this.dashboardJuridicoService
            .nuvemDePalavras(this.hasFiltroParaNuvem, this.request)
            .subscribe({
              next: (value) => {
                this.dataNuvemDePalavras = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                if (error.status !== 401) {
                  this.erroNuvemDePalavras = true;
                  reject(error);
                  return;
                }
                reject(error);
              },
              complete: () => {
                this.needFilterWordCloud = true;
              },
            });
        }).catch((error: HttpErrorResponse) => {
          captureException(error);
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroSentimentoPorOrgao = false;
          this.dashboardJuridicoService
            .sentimentoPorOrgao(this.request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorOrgao = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroSentimentoPorOrgao = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorCategoria = false;
          this.dashboardJuridicoService
            .sentimentoPorCategoria(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorCategoria = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorCategoria = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroSentimentoPorTempo = false;
          this.dashboardJuridicoService
            .sentimentoPorTempo(this.request)
            .subscribe({
              next: (value) => {
                this.sentimentoSentimentoPorTempo = value.sentimentos;
                this.dataSentimentoPorTempo = value.datas;
                this.colorSentimentoTempo = this.setColorSentimento(
                  this.sentimentoSentimentoPorTempo
                );
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroSentimentoPorTempo = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroOrgaosPorTempo = false;
          this.dashboardJuridicoService.orgaosPorTempo(this.request).subscribe({
            next: (value) => {
              this.orgaosOrgaoPorTempo = value.orgaos;
              this.dataOrgaoPorTempo = value.datas;
              resolve(true);
            },
            error: (error: HttpErrorResponse) => {
              this.erroOrgaosPorTempo = true;
              reject(error);
            },
          });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorSubCategoria = false;
          this.dashboardJuridicoService
            .noticiaPorSubCategoria(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSubCategoria = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorSubCategoria = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
        new Promise((resolve, reject) => {
          this.erroNoticiaPorSentimentoDiario = false;
          this.dashboardJuridicoService
            .noticiaPorSentimentoDiario(this.request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSentimentoDiario = value;
                resolve(true);
              },
              error: (error: HttpErrorResponse) => {
                this.erroNoticiaPorSentimentoDiario = true;
                reject(error);
              },
            });
        }).catch((error: HttpErrorResponse) => {
          if (error.status !== 401) {
            captureException(error);
          }
          throw error;
        }),
      ]);
      Swal.close();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Houve um erro ao carregar os filtros',
        text: 'Por favor, tente novamente.',
        confirmButtonColor: 'orange',
      });
    }
  }

  addFilterTrigger(event: any, nome: keyof filterSelected) {
    if (event.isUserInput == false) return;
    if (event.source.selected == true) {
      if (this.filterTrigger[nome] == undefined) this.filterTrigger[nome] = '';
      this.filterTrigger[nome] = this.filterTrigger[nome].concat(
        `${event.source.value}, `
      );
    } else {
      this.filterTrigger[nome] = this.filterTrigger[nome].replace(
        `${event.source.value}, `,
        ''
      );
    }
  }

  async addFiltroSelect(
    event: any,
    nome: keyof filterSelected,
    id: any,
    categoriaPai: any = null
  ) {
    this.addFilterTrigger(event, nome);

    // SE O FILTRO NÃO EXISTIR, CRIAR
    if (this.filterSelected[nome] == undefined) this.filterSelected[nome] = [];

    // SE NÃO FOR UMA AÇÃO DO USUÁRIO, NÃO FAZER NADA
    if (event.isUserInput == false) return;

    // SE O FILTRO FOR O DE CATEGORIA
    if (nome == 'categoria') {
      this.hasAlteracaoFiltroCategoria = true;
      // SE FOR UM EVENTO DE SELEÇÃO
      if (event.source.selected == true) {
        if (categoriaPai == null) {
          this.filterSelected.categoria.push(id);
        } else {
          if (this.filterSelected.subCategoria == undefined)
            this.filterSelected.subCategoria = [];
          // this.filterSelected.categoria = categoriaPai;
          this.filterSelected.subCategoria.push(id);
        }
      }

      // SE FOR UM EVENTO DE DESELEÇÃO
      else {
        if (categoriaPai == null) {
          this.filterSelected.categoria.splice(
            this.filterSelected.categoria.indexOf(id),
            1
          );
        } else {
          this.filterSelected.subCategoria.splice(
            this.filterSelected.subCategoria.indexOf(id),
            1
          );
        }
      }
    } else {
      if (nome == 'grupo') this.hasAlteracaoFiltroGrupo = true;
      if (nome == 'empresa') this.hasAlteracaoFiltroEmpresa = true;
      if (nome == 'autoridade') this.hasAlteracaoFiltroAutoridade = true;
      if (nome == 'partido') this.hasAlteracaoFiltroPartido = true;
      if (nome == 'veiculo') this.hasAlteracaoFiltroVeiculo = true;
      if (nome == 'midia') this.hasAlteracaoFiltroMidia = true;
      if (nome == 'sentimento') this.hasAlteracaoFiltroSentimento = true;
      if (nome == 'subGrupo') this.hasAlteracaoFiltroSubGrupo = true;

      if (event.source.selected == true) {
        if (nome == 'tipoInformacao') {
          this.hasAlteracaoFiltroInformacao = true;
          this.filterSelected.tipoInformacao = id;
          this.filterTrigger.tipoInformacao = event.source.value;
        } else this.filterSelected[nome].push(id);
      } else {
        this.filterSelected[nome].splice(
          this.filterSelected[nome].indexOf(id),
          1
        );
      }
    }
  }

  addFiltroInput(input: string) {
    if (
      this.filterForm.controls[input as keyof typeof this.filterForm.controls]
        .value == ''
    ) {
      this.filterForm.controls[
        input as keyof typeof this.filterForm.controls
      ].setValue(null);

      if (input == 'dataInicial') {
        this.minDate = <Date>{};
      }
      if (input == 'dataFinal') {
        this.maxDate = new Date();
      }

      this.filterSelected[input as keyof filterSelected] = undefined;
    } else {
      if (input == 'titulo') {
        this.filterSelected[input as keyof filterSelected] =
          this.filterForm.controls[
            input as keyof typeof this.filterForm.controls
          ].value;
      }
    }
  }

  async interfiltragem(filtro: keyof typeof this.filterForm.controls) {
    this.filterForm.controls.searchTextboxCategoria.setValue(null);
    this.filterForm.controls.searchTextboxGrupo.setValue(null);
    this.filterForm.controls.searchTextboxPartido.setValue(null);
    this.filterForm.controls.searchTextboxEmpresa.setValue(null);
    this.filterForm.controls.searchTextboxMidia.setValue(null);
    this.filterForm.controls.searchTextboxVeiculo.setValue(null);
    this.filterForm.controls.searchTextboxSentimento.setValue(null);
    this.filterForm.controls.searchTextboxAutoridade.setValue(null);
    this.filterForm.controls.searchTextboxSubGrupo.setValue(null);

    if (filtro == 'tipoInformacao' && this.hasAlteracaoFiltroInformacao) {
      this.filterSelected.categoria = [];
      this.filterSelected.grupo = [];
      this.filterSelected.empresa = [];
      this.filterSelected.sentimento = [];
      this.filterSelected.subCategoria = [];
      this.filterSelected.subGrupo = [];
      this.filterSelected.partido = [];
      this.filterSelected.autoridade = [];
      this.filterSelected.midia = [];
      this.filterSelected.veiculo = [];

      this.completeGrupo.options.forEach((element) => {
        element.deselect();
      });
      this.completeEmpresa.options.forEach((element) => {
        element.deselect();
      });
      this.completeMidia.options.forEach((element) => {
        element.deselect();
      });
      this.completeVeiculo.options.forEach((element) => {
        element.deselect();
      });
      this.completeCategoria.options.forEach((element) => {
        element.deselect();
      });
      this.completeSentimento.options.forEach((element) => {
        element.deselect();
      });
      this.completeAutoridade.options.forEach((element) => {
        element.deselect();
      });
      this.completePartido.options.forEach((element) => {
        element.deselect();
      });
      if (this.permissaoSuperintendencia) {
        this.completeSubGrupo.options.forEach((element) => {
          element.deselect();
        });
      }
      this.filterTrigger.categoria = '';
      this.filterTrigger.grupo = '';
      this.filterTrigger.empresa = '';
      this.filterTrigger.sentimento = '';
      this.filterTrigger.subCategoria = '';
      this.filterTrigger.subGrupo = '';
      this.filterTrigger.autoridade = '';
      this.filterTrigger.partido = '';
      this.filterTrigger.midia = '';
      this.filterTrigger.veiculo = '';

      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroInformacao = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'grupos' && this.hasAlteracaoFiltroGrupo) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroGrupo = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'empresas' && this.hasAlteracaoFiltroEmpresa) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroEmpresa = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'midias' && this.hasAlteracaoFiltroMidia) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroMidia = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'veiculos' && this.hasAlteracaoFiltroVeiculo) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroVeiculo = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'categorias' && this.hasAlteracaoFiltroCategoria) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroCategoria = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'sentimentos' && this.hasAlteracaoFiltroSentimento) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroSentimento = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'autoridades' && this.hasAlteracaoFiltroAutoridade) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.subGrupos = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroAutoridade = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'subGrupos' && this.hasAlteracaoFiltroSubGrupo) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.partidos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroSubGrupo = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'partidos' && this.hasAlteracaoFiltroPartido) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status !== 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.hasAlteracaoFiltroPartido = false;
      this.carregandoFiltros = false;
    }

    this.cdr.detectChanges();
  }

  desabilitarFiltro(filterForm: FormControl) {
    filterForm.disable();
  }

  habilitarFiltro(filterForm: FormControl) {
    filterForm.enable();
  }

  // --------------------------------- Categoria -----------------------------------------------

  filterCategoria(name: string): Categoria[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.categoria) {
      this.filterForm.controls.categorias.patchValue(
        this.filterTrigger.categoria.split(', ')
      );
    }

    let filteredList = this.categorias.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_categorias(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.categoriaService.listarCategorias(2, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.categorias.push(element);
          });
          this.filteredOptionsCategorias =
            this.filterForm.controls.searchTextboxCategoria.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterCategoria(name || '')) // Handle null value by providing an empty string
            );

          if (this.categorias.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.categorias);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.categorias);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- Grupo----------------------------------------------

  filterGrupo(name: string): Grupo[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.grupo) {
      this.filterForm.controls.grupos.patchValue(
        this.filterTrigger.grupo.split(', ')
      );
    }

    let filteredList = this.grupos.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_grupos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.grupoService.listarGrupos(filtroRequest, 2).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.grupos.push(element);
          });
          this.filteredOptionsGrupos =
            this.filterForm.controls.searchTextboxGrupo.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterGrupo(name || '')) // Handle null value by providing an empty string
            );

          if (this.grupos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.grupos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.grupos);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- Partido----------------------------------------------

  filterPartido(name: string): Partido[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.partido) {
      this.filterForm.controls.partidos.patchValue(
        this.filterTrigger.partido.split(', ')
      );
    }

    let filteredList = this.partidos.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_partidos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.partidoService.listarPartidos(2, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.partidos.push(element);
          });
          this.filteredOptionsPartidos =
            this.filterForm.controls.searchTextboxPartido.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterPartido(name || '')) // Handle null value by providing an empty string
            );

          if (this.partidos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.partidos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.partidos);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- Empresa ----------------------------------------------

  filterEmpresa(name: string): Empresa[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.empresa) {
      this.filterForm.controls.empresas.patchValue(
        this.filterTrigger.empresa.split(', ')
      );
    }

    let filteredList = this.empresas.filter((option) =>
      option.nomefantasia.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_empresas(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.empresaService.listarEmpresas(2, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.empresas.push(element);
          });
          this.filteredOptionsEmpresas =
            this.filterForm.controls.searchTextboxEmpresa.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterEmpresa(name || '')) // Handle null value by providing an empty string
            );

          if (this.empresas.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.empresas);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.empresas);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- Sentimento ----------------------------------------------

  filterSentimento(name: string): Sentimento[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.sentimento) {
      this.filterForm.controls.sentimentos.patchValue(
        this.filterTrigger.sentimento.split(', ')
      );
    }

    let filteredList = this.sentimentos.filter((option) =>
      option.sentimento.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_sentimentos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.sentimentoService.listarSentimentos(2, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.sentimentos.push(element);
          });
          this.filteredOptionsSentimentos =
            this.filterForm.controls.searchTextboxSentimento.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterSentimento(name || '')) // Handle null value by providing an empty string
            );

          if (this.sentimentos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.sentimentos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.sentimentos);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- Autoridade ----------------------------------------------

  filterAutoridade(name: string): Autoridade[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.autoridade) {
      this.filterForm.controls.autoridades.patchValue(
        this.filterTrigger.autoridade.split(', ')
      );
    }

    let filteredList = this.autoridades.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_autoridades(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.autoridadeService.listarAutoridades(2, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.autoridades.push(element);
          });
          this.filteredOptionsAutoridades =
            this.filterForm.controls.searchTextboxAutoridade.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterAutoridade(name || '')) // Handle null value by providing an empty string
            );

          if (this.autoridades.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.autoridades);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.autoridades);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- Midia ----------------------------------------------

  filterMidia(name: string): Midia[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.midia) {
      this.filterForm.controls.midias.patchValue(
        this.filterTrigger.midia.split(', ')
      );
    }

    let filteredList = this.midias.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_midias(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.midiaService.listarMidias(2, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.midias.push(element);
          });
          this.filteredOptionsMidias =
            this.filterForm.controls.searchTextboxMidia.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterMidia(name || '')) // Handle null value by providing an empty string
            );

          if (this.midias.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.midias);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.midias);
          }
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- Veiculo ----------------------------------------------

  filterVeiculo(name: string): Veiculo[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.veiculo) {
      this.filterForm.controls.veiculos.patchValue(
        this.filterTrigger.veiculo.split(', ')
      );
    }

    let filteredList = this.veiculos.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_veiculos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.veiculoService.listarVeiculos(2, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.veiculos.push(element);
          });
          this.filteredOptionsVeiculos =
            this.filterForm.controls.searchTextboxVeiculo.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterVeiculo(name || '')) // Handle null value by providing an empty string
            );

          if (this.veiculos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.veiculos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.veiculos);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- SubGrupo -----------------------------------------------

  filterSubGrupo(name: string): SubGrupo[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.subGrupo) {
      this.filterForm.controls.subGrupos.patchValue(
        this.filterTrigger.subGrupo.split(', ')
      );
    }

    let filteredList = this.subGrupos.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_subGrupos(grupos: string = '') {
    if (!grupos.includes('id_grupo')) {
      return;
    } else {
      return new Promise((resolve, reject) => {
        this.subGrupoService.listarSubGrupos(2, grupos).subscribe({
          next: (value) => {
            value.forEach((element) => {
              this.subGrupos.push(element);
            });
            this.filteredOptionsSubGrupos =
              this.filterForm.controls.searchTextboxSubGrupo.valueChanges.pipe(
                startWith<string | null>(''),
                map((name: string | null) => this.filterSubGrupo(name || '')) // Handle null value by providing an empty string
              );

            if (this.subGrupos.length > 0) {
              this.habilitarFiltro(this.filterForm.controls.subGrupos);
            } else {
              this.desabilitarFiltro(this.filterForm.controls.subGrupos);
            }

            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
      }).catch((error: HttpErrorResponse) => {
        if (error.status !== 401) {
          captureException(error);
        }
        throw error;
      });
    }
  }

  // --------------------------------- Tipo Informação -----------------------------------------------

  filterTipoInformacao(name: string): TipoInformacao[] {
    const filterValue = name.toLowerCase();

    let filteredList = this.tiposInformacao.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_tipoInformacao() {
    return new Promise((resolve, reject) => {
      this.tipoInformacaoService.listarTiposInformacao(2).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.tiposInformacao.push(element);
          });
          this.filteredOptionsTiposInformacao =
            this.filterForm.controls.searchTextboxTipoInformacao.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) =>
                this.filterTipoInformacao(name || '')
              ) // Handle null value by providing an empty string
            );

          if (this.tiposInformacao.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.tipoInformacao);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.tipoInformacao);
          }

          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch((error: HttpErrorResponse) => {
      if (error.status !== 401) {
        captureException(error);
      }
      throw error;
    });
  }

  // --------------------------------- Cores -----------------------------------------------
  setColorSentimento(matriz: any[][] | null): Color[] {
    const colors: Color[] = [];
    if (matriz == null) return colors;
    matriz.forEach((element) => {
      if (element[0] === 'POSITIVA') {
        colors.push(this.coresSentimento[0]);
      }
      if (element[0] === 'NEUTRA') {
        colors.push(this.coresSentimento[1]);
      }
      if (element[0] === 'NEGATIVA') {
        colors.push(this.coresSentimento[2]);
      }
    });
    return colors;
  }

  setColorMidia(matriz: any[][] | null): Color[] {
    const colors: Color[] = [];
    if (matriz == null) return colors;
    matriz.forEach((element) => {
      if (element[0] === 'Site') {
        colors.push('#9a7fd1');
      }
      if (element[0] === 'TV') {
        colors.push('#e28fab');
      }
      if (element[0] === 'Rádio') {
        colors.push('#b4897f');
      }
      if (element[0] === 'Impresso') {
        colors.push('#ffd6a3');
      }
    });
    return colors;
  }

  // --------------------------------- PDF Charts -----------------------------------------------
  async gerarPDF() {
    Swal.fire({
      background: '#ffffff00',
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          let doc: jsPDF = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [1020, 640],
          });

          // Adicionar Capa
          const capaImage = '../../assets/logo_clipei_novo.png'; // Caminho para a imagem da capa
          const tituloRelatorio = 'RELATÓRIO JURÍDICO'; // Título do Relatório
          let grupos = '';

          if (this.empresaspdf !== undefined && this.empresaspdf !== '') {
            grupos = this.empresaspdf.slice(0, -2);
          } else if (this.grupospdf !== undefined && this.grupospdf !== '') {
            grupos = this.grupospdf.slice(0, -2);
          } else {
            this.filteredOptionsGrupos
              ?.pipe(
                map((grupos: Grupo[]) => {
                  return grupos.map((element: Grupo) => element.descricao);
                })
              )
              .subscribe((descricaoGrupos: string[]) => {
                grupos = descricaoGrupos.join(', ');
              });
          }

          const datafinal = this.moment(
            this.filterForm.controls.dataFinal.value
          ).toDate();
          const datainicial = this.moment(
            this.filterForm.controls.dataInicial.value
          ).toDate();
          const dataRelatorio = `${datainicial.getDate()}.${
            this.meses[datainicial.getMonth() + 1]
          }.${datainicial.getFullYear()} A ${datafinal.getDate()}.${
            this.meses[datafinal.getMonth() + 1]
          }.${datafinal.getFullYear()}`;
          // Data do Relatório

          doc.addImage(capaImage, 'PNG', 50, 200, 400, 176);

          // Adicionar título do relatório
          doc.setFont('helvetica');
          doc.setFontSize(40);

          doc.text(tituloRelatorio, doc.internal.pageSize.width - 30, 400, {
            align: 'right',
          });

          doc.setFont('helvetica', 'bold');
          doc.setFontSize(30);
          doc.setTextColor('#000000'); // cor preta
          // Dividindo o texto em várias linhas
          const textoDividido = doc.splitTextToSize(grupos, 700);
          let alturagrupo = 410;
          // Desenhando cada linha individualmente
          for (let i = 0; i < textoDividido.length; i++) {
            alturagrupo += 25;
            doc.text(
              textoDividido[i],
              doc.internal.pageSize.width - 30,
              alturagrupo,
              { align: 'right' }
            ); // Incremento de 25 para o espaçamento entre as linhas
          }

          // Adicionar data
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(30);
          doc.setTextColor('#000000'); // cor preta
          doc.text(
            dataRelatorio,
            doc.internal.pageSize.width - 30,
            alturagrupo + 35,
            {
              align: 'right',
            }
          );

          let chartsArray = this.charts.toArray();

          const difDays =
            (datafinal.getTime() - datainicial.getTime()) /
            (1000 * 60 * 60 * 24);

          if (
            difDays >= 28 &&
            chartsArray.some(
              (item) =>
                item.title === 'Notícias por Classificação dos Últimos 7 dias'
            )
          ) {
            chartsArray = chartsArray.filter(
              (item) =>
                item.title !== 'Notícias por Classificação dos Últimos 7 dias'
            );
          }

          for (let index = 0; index < chartsArray.length; index++) {
            // Convert QueryList to an array
            await chartsArray[index].imageOutput();

            doc.addPage();

            if (doc) {
              doc.addImage(
                this.chartDataPDF.dataURL,
                'PNG',
                10,
                10,
                this.chartDataPDF.imgWidth,
                this.chartDataPDF.imgHeight
              );
              doc.addImage(
                '../../assets/logo_clipei.png',
                'PNG',
                895,
                585,
                100,
                50
              );
            }
          }

          setTimeout(() => {
            if (doc) {
              doc.save('Relatorio_Juridico.pdf');
            }
            Swal.close();
          }, 2000);
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao gerar o PDF',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      },
    });
  }

  handleChartImage(chartImageObj: {
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }): void {
    const { dataURL, imgWidth, imgHeight } = chartImageObj;
    this.chartDataPDF.dataURL = dataURL;
    this.chartDataPDF.imgWidth = imgWidth;
    this.chartDataPDF.imgHeight = imgHeight;
  }

  handleCountData(countData: number): void {
    this.length = countData;
  }

  handleLoadingCount(loadindLength: boolean): void {
    this.carregandoLength = loadindLength;
  }

  cancelarCarregamentoFiltros() {
    this.tipoInformacaoService.cancelarChamada();
    this.grupoService.cancelarChamada();
    this.empresaService.cancelarChamada();
    this.midiaService.cancelarChamada();
    this.veiculoService.cancelarChamada();
    this.categoriaService.cancelarChamada();
    this.sentimentoService.cancelarChamada();
    this.autoridadeService.cancelarChamada();
    this.subGrupoService.cancelarChamada();
    this.partidoService.cancelarChamada();
    this.pautaService.cancelarChamada();
  }

  async recarregarFiltros() {
    this.panelExpansion = true;
    this.cancelarCarregamentoFiltros();

    this.erroCarregamentoFiltros = false;
    this.carregandoFiltros = true;
    this.carregandoFiltrosInit = true;

    this.filterSelected = {} as filterSelected;
    this.filterSelected.tipoInformacao = 1;
    this.minDate = <Date>{};
    this.maxDate = new Date();

    this.completeTipoInformacao.options.forEach((element) => {
      if (element.value == 'Notícias/Publicações') {
        element.select();
      } else {
        element.deselect();
      }
    });
    this.completeGrupo.options.forEach((element) => {
      element.deselect();
    });
    this.completePartido.options.forEach((element) => {
      element.deselect();
    });
    this.completeEmpresa.options.forEach((element) => {
      element.deselect();
    });
    this.completeMidia.options.forEach((element) => {
      element.deselect();
    });
    this.completeVeiculo.options.forEach((element) => {
      element.deselect();
    });
    this.completeCategoria.options.forEach((element) => {
      element.deselect();
    });
    this.completeSentimento.options.forEach((element) => {
      element.deselect();
    });
    this.completeAutoridade.options.forEach((element) => {
      element.deselect();
    });
    if (this.permissaoSuperintendencia) {
      this.completeSubGrupo.options.forEach((element) => {
        element.deselect();
      });
    }
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.filterTrigger.categoria = '';
    this.filterTrigger.grupo = '';
    this.filterTrigger.partido = '';
    this.filterTrigger.empresa = '';
    this.filterTrigger.sentimento = '';
    this.filterTrigger.subCategoria = '';
    this.filterTrigger.subGrupo = '';
    this.filterTrigger.autoridade = '';
    this.filterTrigger.midia = '';
    this.filterTrigger.veiculo = '';

    this.filterForm.controls.dataInicial.setValue(this.thirtyDaysAgo);
    this.filterForm.controls.dataFinal.setValue(this.todayDate);
    this.filterForm.controls.titulo.setValue(null);
    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);

    this.tiposInformacao = [];
    this.grupos = [];
    this.empresas = [];
    this.midias = [];
    this.veiculos = [];
    this.categorias = [];
    this.sentimentos = [];
    this.autoridades = [];
    this.subGrupos = [];
    this.partidos = [];

    try {
      await Promise.all([
        this.listar_tipoInformacao(),
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_partidos(this.criarStringRequest({ partido: true })),
      ]);
      this.carregandoFiltros = false;
      this.carregandoFiltrosInit = false;
      this.onResize();

      this.completeTipoInformacao.options.forEach((element_10) => {
        if (element_10.value == 'Notícias/Publicações') {
          element_10.select();
        }
      });
    } catch (error) {
      this.erroCarregamentoFiltros = true;
      Swal.fire({
        icon: 'error',
        title: 'Houve um erro ao carregar os filtros',
        text: 'Por favor, tente novamente.',
        confirmButtonColor: 'orange',
      });
    }
  }

  disableFiltrosFunc() {
    this.filterForm.controls.tipoInformacao.disable();
    this.filterForm.controls.categorias.disable();
    this.filterForm.controls.grupos.disable();
    this.filterForm.controls.empresas.disable();
    this.filterForm.controls.sentimentos.disable();
    this.filterForm.controls.autoridades.disable();
    this.filterForm.controls.partidos.disable();
    this.filterForm.controls.midias.disable();
    this.filterForm.controls.veiculos.disable();
    this.filterForm.controls.subGrupos.disable();
    this.filterForm.controls.titulo.disable();
    this.disableFiltros = true;
  }

  enableFiltrosFunc() {
    if (this.categorias.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.categorias);
    }
    if (this.grupos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.grupos);
    }
    if (this.empresas.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.empresas);
    }
    if (this.sentimentos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.sentimentos);
    }
    if (this.autoridades.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.autoridades);
    }
    if (this.partidos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.partidos);
    }
    if (this.midias.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.midias);
    }
    if (this.veiculos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.veiculos);
    }
    if (this.tiposInformacao.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.tipoInformacao);
    }
    this.filterForm.controls.titulo.enable();
    this.disableFiltros = false;
  }

  recarregarNoticiaPorSentimento() {
    this.carregandoSent = true;
    this.erroNoticiaPorSentimento = false;
    new Promise((resolve, reject) => {
      this.dashboardJuridicoService
        .noticiaPorSentimento(this.request)
        .subscribe({
          next: (value) => {
            this.positiva = 0;
            this.negativa = 0;
            this.neutra = 0;
            value.forEach((element: any) => {
              if (element[0] == 'POSITIVA') this.positiva = element[1];
              if (element[0] == 'NEGATIVA') this.negativa = element[1];
              if (element[0] == 'NEUTRA') this.neutra = element[1];
            });

            this.carregandoSent = false;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            this.carregandoSent = false;
            captureException(error);
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorSentimento = true;
    });
  }

  recarregarOrgaosPorTempo() {
    this.dataOrgaoPorTempo = null;
    this.erroOrgaosPorTempo = false;
    new Promise((resolve, reject) => {
      this.dashboardJuridicoService.orgaosPorTempo(this.request).subscribe({
        next: (value) => {
          this.orgaosOrgaoPorTempo = value.orgaos;
          this.dataOrgaoPorTempo = value.datas;
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch(() => {
      this.erroOrgaosPorTempo = true;
    });
  }

  recarregarSentimentoPorOrgao() {
    this.dataSentimentoPorOrgao = null;
    this.erroSentimentoPorOrgao = false;
    new Promise((resolve, reject) => {
      this.dashboardJuridicoService.sentimentoPorOrgao(this.request).subscribe({
        next: (value) => {
          this.dataSentimentoPorOrgao = value;
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch(() => {
      this.erroSentimentoPorOrgao = true;
    });
  }

  recarregarNoticiaPorCategoria() {
    this.dataNoticiaPorCategoria = null;
    this.erroNoticiaPorCategoria = false;
    new Promise((resolve, reject) => {
      this.dashboardJuridicoService
        .sentimentoPorCategoria(this.request)
        .subscribe({
          next: (value) => {
            this.dataNoticiaPorCategoria = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorCategoria = true;
    });
  }

  recarregarSentimentoPorTempo() {
    this.dataSentimentoPorTempo = null;
    this.erroSentimentoPorTempo = false;
    new Promise((resolve, reject) => {
      this.dashboardJuridicoService.sentimentoPorTempo(this.request).subscribe({
        next: (value) => {
          this.sentimentoSentimentoPorTempo = value.sentimentos;
          this.dataSentimentoPorTempo = value.datas;
          this.colorSentimentoTempo = this.setColorSentimento(
            this.sentimentoSentimentoPorTempo
          );
          resolve(true);
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
      });
    }).catch(() => {
      this.erroSentimentoPorTempo = true;
    });
  }

  recarregarNoticiaPorSubCategoria() {
    this.dataNoticiaPorSubCategoria = null;
    this.erroNoticiaPorSubCategoria = false;
    new Promise((resolve, reject) => {
      this.dashboardJuridicoService
        .noticiaPorSubCategoria(this.request)
        .subscribe({
          next: (value) => {
            this.dataNoticiaPorSubCategoria = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorSubCategoria = true;
    });
  }

  recarregarNoticiaPorSentimentoDiario() {
    this.dataNoticiaPorSentimentoDiario = null;
    this.erroNoticiaPorSentimentoDiario = false;
    new Promise((resolve, reject) => {
      this.dashboardJuridicoService
        .noticiaPorSentimentoDiario(this.request)
        .subscribe({
          next: (value) => {
            this.dataNoticiaPorSentimentoDiario = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
    }).catch(() => {
      this.erroNoticiaPorSentimentoDiario = true;
    });
  }

  recarregarNuvemDePalavras() {
    this.dataNuvemDePalavras = null;
    this.erroNuvemDePalavras = false;
    this.needFilterWordCloud = false;
    new Promise((resolve, reject) => {
      this.dashboardJuridicoService
        .nuvemDePalavras(this.hasFiltroParaNuvem, this.request)
        .subscribe({
          next: (value) => {
            this.dataNuvemDePalavras = value;
            resolve(true);
          },
          error: (error: HttpErrorResponse) => {
            this.erroNuvemDePalavras = true;
            reject(error);
          },
          complete: () => {
            this.needFilterWordCloud = true;
          },
        });
    }).catch((error: HttpErrorResponse) => {
      captureException(error);
      throw error;
    });
  }

  formatarNuvemDePalavrasLargura() {
    if (window.innerWidth <= 768) {
      return 1;
    }
    const excludeKeys = [
      'Notícias por Categoria (Top 15)',
      'Notícias por Subcategoria (Top 15)',
      'Notícias por Classificação (Linhas e Barras)',
      'Notícias por Órgão (Linhas e Barras) (Top 5)',
    ];

    const trueCount = this.permissionCharts.filter(
      (key) => !excludeKeys.includes(key)
    ).length;

    // Verificar se o número de `true` é par ou ímpar
    const isEven = trueCount % 2 === 0;

    if (isEven) {
      return 1;
    } else {
      return 2;
    }
  }
}
