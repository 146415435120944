<div class="tela">
  <mat-toolbar class="toolbar" color="primary">
    <button mat-icon-button (click)="voltar()">
      <mat-icon>undo</mat-icon>
    </button>

    <!---Adiciona um espaço flexível para empurrar os elementos para a direita--->
    <span class="flex-space"></span>

    <div class="user-profile">
      <div class="user-avatar">
        <!---Insira a imagem do usuário aqui--->
        <img src="../../../assets/pessoa_icone.svg" alt="Imagem do usuário" />
        <!---<img *ngIf="avatar != 'null'"  src={{avatar}} alt="" />
        <img *ngIf="avatar == 'null'"  src="../../../assets/pessoa_icone.svg" alt="" />--->
      </div>
      <div class="user-info">
        <span>{{ usuario }}</span>
        <mat-icon [matMenuTriggerFor]="userMenu">arrow_drop_down</mat-icon>
      </div>
    </div>
  </mat-toolbar>

  <mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="deslogar()">Sair</button>
    <button mat-menu-item (click)="administrativo()" *ngIf="admin">
      Visão Administrativa
    </button>
    <button mat-menu-item (click)="paineis()" *ngIf="admin">
      Paineis de Visualização
    </button>
  </mat-menu>

  <div class="forms">
    <img class="logo" src="../../../assets/logo_clipei_novo.png" alt="" />

    <form class="form-login" [formGroup]="passwordForm">
      <span class="titulo-forms">Trocar Senha</span>

      <mat-form-field appearance="outline" class="input-login">
        <mat-label>Senha Atual</mat-label>
        <input
          formControlName="senha"
          [type]="hidePassword ? 'password' : 'text'"
          matInput
          placeholder="Senha"
        />
        <mat-icon
          matSuffix
          class="std_gray"
          (click)="visibilidadeSenha('senha')"
        >
          {{ hidePassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-login">
        <mat-label>Nova Senha</mat-label>
        <input
          formControlName="novaSenha"
          [type]="hideNewPassword ? 'password' : 'text'"
          matInput
          placeholder="Senha"
        />
        <mat-icon
          matSuffix
          class="std_gray"
          (click)="visibilidadeSenha('novaSenha')"
        >
          {{ hideNewPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-login">
        <mat-label>Confirmar Senha</mat-label>
        <input
          formControlName="confirmSenha"
          [type]="hideConfirmNewPassword ? 'password' : 'text'"
          matInput
          placeholder="Senha"
        />
        <mat-icon
          matSuffix
          class="std_gray"
          (click)="visibilidadeSenha('confirmSenha')"
        >
          {{ hideConfirmNewPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </mat-form-field>

      <!---routerLink="/home"  --->
      <button
        (click)="trocarSenha()"
        mat-raised-button
        class="button-login"
        color="primary"
        [disabled]="desabilitaBotao || passwordForm.invalid"
      >
        Trocar Senha
      </button>
    </form>

    <div class="errors">
      <mat-error class="hidden-error" *ngIf="senhaIncorreta"
        >Senha Atual está incorreta</mat-error
      >
      <mat-error class="hidden-error" *ngIf="!novaSenhaCombina && click"
        >A confirmação está incorreta
      </mat-error>
    </div>
  </div>
</div>
