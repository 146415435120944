<mat-expansion-panel
  class="filtro"
  [(expanded)]="panelExpansion"
  [disabled]="erroCarregamentoFiltros"
  [class.carregando]="carregandoFiltros && !erroCarregamentoFiltros"
>
  <!---Cabeçalho do expansion panel------------------------------------------------------------------------------>
  <mat-expansion-panel-header>
    <mat-panel-title> Filtros </mat-panel-title>
    <mat-panel-description>
      <div class="chipspace">
        <mat-chip-listbox
          *ngIf="!carregandoFiltrosInit && !erroCarregamentoFiltros"
        >
          <mat-chip-row *ngFor="let filterChip of chipShow">
            {{ filterChip }}
          </mat-chip-row>
        </mat-chip-listbox>

        <mat-chip-listbox
          *ngIf="carregandoFiltrosInit && !erroCarregamentoFiltros"
        >
          <mat-chip-row> Carregando Filtros... </mat-chip-row>
        </mat-chip-listbox>

        <mat-chip-listbox *ngIf="erroCarregamentoFiltros">
          <button
            mat-raised-button
            (click)="recarregarFiltros()"
            class="button-login"
            color="primary"
          >
            Recarregar Filtros
          </button>
        </mat-chip-listbox>
      </div>
      <mat-icon>filter_alt</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <!---Filtros do expansion panel-------------------------------------------------------------------------------->
  <div>
    <form [formGroup]="filterForm">
      <mat-grid-list [cols]="isTelaMenorQue500px" rowHeight="80px">
        <!---Filtro de tipo-Informação--------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Tipo de Informação</mat-label>

            <mat-select
              #completeTipoInformacao
              placeholder="Tipo de Informação"
              multiple="false"
              (closed)="interfiltragem('tipoInformacao')"
              [formControl]="filterForm.controls.tipoInformacao"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="
                    filterForm.controls.searchTextboxTipoInformacao
                  "
                  matInput
                />
              </div>

              <mat-divider></mat-divider>

              <mat-option
                *ngFor="let option of filteredOptionsTiposInformacao | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'tipoInformacao', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de grupo--------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Grupo</mat-label>

            <mat-select
              #completeGrupo
              placeholder="Grupo"
              multiple="true"
              (closed)="interfiltragem('grupos')"
              [formControl]="filterForm.controls.grupos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxGrupo"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{ filterTrigger.grupo }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsGrupos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'grupo', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de data---------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Data inicial</mat-label>
            <input
              matInput
              readonly
              formControlName="dataInicial"
              (blur)="addFiltroInput('dataInicial')"
              (dateChange)="setDate($event)"
              [max]="maxDate"
              [matDatepicker]="data_inicial"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="data_inicial"
              [disabled]="disableFiltros"
            ></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #data_inicial></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de data---------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Data final</mat-label>
            <input
              matInput
              readonly
              formControlName="dataFinal"
              (blur)="addFiltroInput('dataFinal')"
              (dateChange)="setDate($event)"
              [max]="todayDate"
              [min]="minDate"
              [matDatepicker]="data_final"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="data_final"
              [disabled]="disableFiltros"
            ></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #data_final></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de empresa--------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Empresa</mat-label>

            <mat-select
              #completeEmpresa
              placeholder="Empresa"
              multiple="true"
              (closed)="interfiltragem('empresas')"
              [formControl]="filterForm.controls.empresas"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxEmpresa"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.empresa
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsEmpresas | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'empresa', option.id)
                "
                [value]="option.nomefantasia"
              >
                {{ option.nomefantasia }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de Veiculo--------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Encontro</mat-label>

            <mat-select
              #completeVeiculo
              placeholder="Encontro"
              multiple="true"
              (closed)="interfiltragem('veiculos')"
              [formControl]="filterForm.controls.veiculos"
            >
              <mat-optgroup>
                <div class="icon-input">
                  <mat-icon class="search-icon">search</mat-icon>
                  <input
                    type="text"
                    class="search-input"
                    [formControl]="filterForm.controls.searchTextboxVeiculo"
                    matInput
                  />
                </div>
              </mat-optgroup>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.veiculo
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsVeiculos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'veiculo', option.id)
                "
                [value]="option.nome"
              >
                {{ option.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de categoria--------------------------------------------------------------------------->
        <mat-grid-tile [colspan]="1">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Categoria</mat-label>

            <mat-select
              #completeCategoria
              placeholder="Categoria"
              multiple="true"
              (closed)="interfiltragem('categorias')"
              [formControl]="filterForm.controls.categorias"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxCategoria"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.categoria
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsCategorias | async"
                (onSelectionChange)="
                  addFiltroSelect(
                    $event,
                    'categoria',
                    option.id,
                    option.id_categoriapai
                  )
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de sentimento--------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Classificação</mat-label>

            <mat-select
              #completeSentimento
              placeholder="Classificação"
              multiple="true"
              (closed)="interfiltragem('sentimentos')"
              [formControl]="filterForm.controls.sentimentos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxSentimento"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.sentimento
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsSentimentos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'sentimento', option.sentimento)
                "
                [value]="option.sentimento"
              >
                {{ option.sentimento }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de autoridade--------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Liderança</mat-label>
            <mat-select
              #completeAutoridade
              placeholder="Liderança"
              multiple="true"
              (closed)="interfiltragem('autoridades')"
              [formControl]="filterForm.controls.autoridades"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxAutoridade"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.autoridade
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsAutoridades | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'autoridade', option.id)
                "
                [value]="option.nome"
              >
                {{ option.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de programa--------------------------------------------------------------------------->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Reuniões</mat-label>

            <mat-select
              #completePrograma
              placeholder="Reuniões"
              multiple="true"
              (closed)="interfiltragem('programas')"
              [formControl]="filterForm.controls.programas"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxPrograma"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.programa
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsProgramas | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'programa', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!---Filtro de Título---------------------------------------------------------------------------->
        <mat-grid-tile [colspan]="numbertitle">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Título ou Palavra Chave</mat-label>
            <input
              type="text"
              placeholder="Título ou Palavra Chave"
              (blur)="addFiltroInput('titulo')"
              matInput
              formControlName="titulo"
            />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>

    <div class="filtrar-limpar">
      <button
        mat-raised-button
        (click)="filtrar()"
        class="button-login"
        color="primary"
      >
        Filtrar
      </button>
      <button
        mat-raised-button
        [disabled]="validClearFilter()"
        (click)="limparFiltros()"
        class="button-login"
        color="primary"
      >
        Limpar Filtros
      </button>
    </div>
  </div>

  <mat-progress-spinner
    class="spinner"
    *ngIf="carregandoFiltros"
    mode="indeterminate"
  ></mat-progress-spinner>
</mat-expansion-panel>

<div class="totalizadores">
  <div class="cards">
    <mat-grid-list [cols]="responsiveCards" rowHeight="100px">
      <mat-grid-tile>
        <mat-card class="totalNoticias">
          <mat-card-content *ngIf="!this.carregandoLength">
            <b>
              <span class="destaque">
                {{ length }}
              </span>
              Notícias Encontradas</b
            >
          </mat-card-content>
          <mat-card-content *ngIf="this.carregandoLength">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-card class="totalNoticias">
          <mat-card-content
            *ngIf="!this.carregandoSent && !this.erroNoticiaPorSentimento"
          >
            <b style="color: #77c4a8">
              <span class="destaque">
                {{ positiva }}
              </span>
              Notícias Positivas</b
            >
          </mat-card-content>
          <mat-card-content
            *ngIf="!this.carregandoSent && this.erroNoticiaPorSentimento"
          >
            <p>Erro ao carregar notícias positivas</p>
          </mat-card-content>
          <mat-card-content *ngIf="this.carregandoSent">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-card class="totalNoticias">
          <mat-card-content
            *ngIf="!this.carregandoSent && !this.erroNoticiaPorSentimento"
          >
            <b style="color: #f5c869">
              <span class="destaque">
                {{ neutra }}
              </span>
              Notícias Neutras</b
            >
          </mat-card-content>
          <mat-card-content
            *ngIf="!this.carregandoSent && this.erroNoticiaPorSentimento"
          >
            <p>Erro ao carregar notícias neutras</p>
          </mat-card-content>
          <mat-card-content *ngIf="this.carregandoSent">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-card class="totalNoticias">
          <mat-card-content
            *ngIf="!this.carregandoSent && !this.erroNoticiaPorSentimento"
          >
            <b style="color: #dd6b7f">
              <span class="destaque">
                {{ negativa }}
              </span>
              Notícias Negativas</b
            >
          </mat-card-content>
          <mat-card-content
            *ngIf="!this.carregandoSent && this.erroNoticiaPorSentimento"
          >
            <p>Erro ao carregar notícias negativas</p>
          </mat-card-content>
          <mat-card-content *ngIf="this.carregandoSent">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div class="cards">
    <!----------------Gráfico porcentagem de noticias positivas------------------->
    <mat-grid-list [cols]="1" rowHeight="200px">
      <mat-grid-tile>
        <mat-card
          class="gauge"
          *ngIf="
            dataNoticiaPorSentimento &&
            dataNoticiaPorSentimento.length > 0 &&
            !erroNoticiaPorSentimento &&
            this.hasNoticiasGauche
          "
        >
          <mat-card-content>
            <app-gauge-chart
              #charts
              (chartImage)="handleChartImage($event)"
              [data]="dataNoticiaGauge"
              [color]="colorSentimento"
              title="Saúde da Marca no Período"
            ></app-gauge-chart>
            <div class="tooltip-container">
              <mat-icon
                matTooltipPosition="right"
                matTooltipClass="red-tooltip"
                matTooltip="Termômetro de Saúde da Marca&#13;

            A métrica é gerada a partir da análise das menções dos vereadores que citam diretamente a empresa e são classificadas nos sentimentos Positivo e Negativo, tendo como ponto de partida a impressão do receptor ao ter acesso as citações.&#13;

            Abolimos o sentimento Neutro desta análise, porque a simplificação do diagnóstico evita subjetividades e torna o resultado mais seguro."
              >
                info_outlined</mat-icon
              >
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card
          class="gauge"
          *ngIf="!dataNoticiaPorSentimento && !erroNoticiaPorSentimento"
        >
          <mat-card-content class="loading-content">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>

        <mat-card
          class="gauge"
          *ngIf="
            dataNoticiaPorSentimento?.length == 0 &&
            !erroNoticiaPorSentimento &&
            this.hasNoticiasGauche
          "
        >
          <mat-card-content class="loading-content">
            <p>
              Não há dados para exibir em relação às
              <strong>Saúde da Marca</strong>
            </p>
          </mat-card-content>
        </mat-card>

        <mat-card class="gauge" *ngIf="erroNoticiaPorSentimento">
          <mat-card-content class="loading-error-content">
            <p>
              Erro ao carregar gráfico
              <strong>Saúde da Marca no Período</strong>
            </p>
            <button
              mat-raised-button
              (click)="recarregarNoticiaPorSentimento()"
              class="button-login"
              color="primary"
            >
              Recarregar Gráfico
            </button>
          </mat-card-content>
        </mat-card>

        <mat-card class="gauge" *ngIf="!this.hasNoticiasGauche">
          <mat-card-content class="loading-content">
            <p>Não há notícias <b>positivas</b> e nem <b>negativas</b></p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<hr />

<mat-tab-group dynamicHeight>
  <mat-tab label="Dasboard Comunidade">
    <div #dashboard>
      <h1>Comunidade</h1>
      <button
        mat-raised-button
        (click)="gerarPDF()"
        class="button-login"
        color="primary"
      >
        Gerar PDF
      </button>

      <!-------------------------------------Gráficos-------------------------------------------------------------------->

      <mat-grid-list [cols]="numbergraphs" rowHeight="480px">
        <!----------------Gráfico sentimento por orgão------------------->
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Classificação por Encontro')"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorOrgao &&
              dataSentimentoPorOrgao.orgaos.length > 0 &&
              !erroSentimentoPorOrgao
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                [data]="dataSentimentoPorOrgao"
                title="Classificação por Encontro"
                [color]="coresSentimento"
                label="orgaos"
                (chartImage)="handleChartImage($event)"
              />
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataSentimentoPorOrgao && !erroSentimentoPorOrgao">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorOrgao &&
              dataSentimentoPorOrgao.orgaos.length == 0 &&
              !erroSentimentoPorOrgao
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação à
                <strong>Classificação por Encontro</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoPorOrgao">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Classificação por Encontro</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarSentimentoPorOrgao()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!----------------Gráfico notícia por sentimento------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Classificação (Rosca)')
          "
        >
          <mat-card
            *ngIf="
              dataNoticiaPorSentimento &&
              dataNoticiaPorSentimento.length > 0 &&
              !erroNoticiaPorSentimento
            "
          >
            <mat-card-content>
              <app-doughnut-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataNoticiaPorSentimento"
                [color]="colorSentimento"
                title="Notícias por Classificação"
              ></app-doughnut-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataNoticiaPorSentimento && !erroNoticiaPorSentimento"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorSentimento?.length == 0 && !erroNoticiaPorSentimento
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Classificação</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorSentimento">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Notícias por Classificação</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarNoticiaPorSentimento()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!----------------Gráfico sentimento por autoridades------------------>
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Lideranças por Classificação')"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorAutoridade &&
              dataSentimentoPorAutoridade.autoridades.length > 0 &&
              !erroAutoridadesPorSentimento
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                [data]="dataSentimentoPorAutoridade"
                title="Lideranças por Classificação"
                [color]="coresSentimento"
                label="autoridades"
                (chartImage)="handleChartImage($event)"
              />
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataSentimentoPorAutoridade && !erroAutoridadesPorSentimento
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorAutoridade &&
              dataSentimentoPorAutoridade.autoridades.length == 0 &&
              !erroAutoridadesPorSentimento
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Lideranças por Classificação</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroAutoridadesPorSentimento">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Lideranças por Classificação</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarAutoridadesPorSentimento()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!----------------Gráfico nuvem de   palavras------------------>
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Nuvem de Palavras')"
        >
          <mat-card
            *ngIf="
              dataNuvemDePalavras &&
              dataNuvemDePalavras.length > 0 &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content>
              <app-word-cloud
                #charts
                [data]="dataNuvemDePalavras"
                title="Palavras que mais apareceram"
                (chartImage)="handleChartImage($event)"
              />
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataNuvemDePalavras &&
              !needFilterWordCloud &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataNuvemDePalavras?.length == 0 && !erroNuvemDePalavras"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Palavras que mais apareceram</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataNuvemDePalavras &&
              needFilterWordCloud &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content class="loading-content">
              <p>
                É necessário selecionar um filtro para exibir as
                <strong>Palavras que mais aparecem</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNuvemDePalavras">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Palavras que mais aparecem</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarNuvemDePalavras()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!----------------Gráfico evangelizadores------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Lideranças Evangelizadoras (Top 15)'
            )
          "
        >
          <mat-card
            *ngIf="
              dataEvangelizadores &&
              dataEvangelizadores.length > 0 &&
              !erroSentimentoEvangelizadores
            "
          >
            <mat-card-content>
              <app-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataEvangelizadores"
                [color]="colorEvangelizadores"
                [colorfull]="false"
                title="Lideranças Evangelizadoras (Top 15)"
              ></app-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataEvangelizadores && !erroSentimentoEvangelizadores"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataEvangelizadores?.length == 0 && !erroSentimentoEvangelizadores
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Lideranças Evangelizadoras (Top 15)</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoEvangelizadores">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Lideranças Evangelizadoras (Top 15)</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarSentimentoEvangelizadores()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!----------------Gráfico detratores------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Lideranças Detratoras (Top 15)')
          "
        >
          <mat-card
            *ngIf="
              dataDetratores &&
              dataDetratores.length > 0 &&
              !erroSentimentoDetratores
            "
          >
            <mat-card-content>
              <app-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataDetratores"
                [color]="colorDetratores"
                [colorfull]="false"
                title="Lideranças Detratoras (Top 15)"
              ></app-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataDetratores && !erroSentimentoDetratores">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataDetratores?.length == 0 && !erroSentimentoDetratores"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Lideranças Detratoras (Top 15)</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoDetratores">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Lideranças Detratoras (Top 15)</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarSentimentoDetratores()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list [cols]="numbergraphs" rowHeight="350px">
        <!----------------Gráfico notícia por categoria------------------->
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Categoria (Top 15)')
          "
          rowspan="2"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorCategoria &&
              dataSentimentoPorCategoria.categorias.length > 0 &&
              !erroSentimentoPorCategoria
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [color]="coresSentimento"
                [data]="dataSentimentoPorCategoria"
                label="categorias"
                title="Notícias por Categoria"
              ></app-stacked-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataSentimentoPorCategoria && !erroSentimentoPorCategoria"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorCategoria &&
              dataSentimentoPorCategoria.categorias.length == 0 &&
              !erroSentimentoPorCategoria
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Categoria</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoPorCategoria">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico <strong>Notícias por Categoria</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarSentimentoPorCategoria()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!----------------Gráfico notícia por subcategoria------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Subcategoria (Top 15)')
          "
          rowspan="2"
        >
          <mat-card
            *ngIf="
              dataNoticiaPorSubCategoria &&
              dataNoticiaPorSubCategoria.subcategorias.length > 0 &&
              !erroNoticiaPorSubCategoria
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataNoticiaPorSubCategoria"
                [color]="coresSentimento"
                label="subcategorias"
                title="Notícias por Subcategoria"
              />
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataNoticiaPorSubCategoria && !erroNoticiaPorSubCategoria"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorSubCategoria &&
              dataNoticiaPorSubCategoria.subcategorias.length == 0 &&
              !erroNoticiaPorSubCategoria
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Subcategoria</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorSubCategoria">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Notícias por Subcategoria</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarNoticiaPorSubCategoria()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list [cols]="numbergraphs" rowHeight="480px">
        <!----------------Gráfico sentimento por tempo------------------->
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Classificação (Linhas e Barras)'
            )
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorTempo &&
              dataSentimentoPorTempo.length > 0 &&
              !erroSentimentoPorTempo
            "
          >
            <mat-card-content>
              <app-bar-chart-line-chart
                #charts
                [data]="
                  sentimentoSentimentoPorTempo !== null
                    ? sentimentoSentimentoPorTempo
                    : []
                "
                [datas]="dataSentimentoPorTempo"
                [color]="colorSentimentoTempo"
                [cols]="numbergraphs"
                title="Notícias por Classificação"
                (chartImage)="handleChartImage($event)"
                [(lastTypeSelected)]="typeSelectedNoticiasPorClassificacao"
              />
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataSentimentoPorTempo && !erroSentimentoPorTempo">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorTempo?.length == 0 && !erroSentimentoPorTempo
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Classificação</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoPorTempo">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Notícias por Classificação</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarSentimentoPorTempo()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!----------------Gráfico orgao por tempo------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Encontro (Linhas e Barras) (Top 5)'
            )
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            *ngIf="
              dataOrgaoPorTempo &&
              dataOrgaoPorTempo.length > 0 &&
              !erroOrgaosPorTempo
            "
          >
            <mat-card-content>
              <app-bar-chart-line-chart
                #charts
                [color]="colorOrgaoTempo"
                [data]="orgaosOrgaoPorTempo !== null ? orgaosOrgaoPorTempo : []"
                [datas]="dataOrgaoPorTempo"
                [cols]="numbergraphs"
                title="Notícias por Encontro (Top 5)"
                (chartImage)="handleChartImage($event)"
                [(lastTypeSelected)]="typeSelectedNoticiasPorOrgao"
              />
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataOrgaoPorTempo && !erroOrgaosPorTempo">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataOrgaoPorTempo?.length == 0 && !erroOrgaosPorTempo"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Encontro(Top 5)</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroOrgaosPorTempo">
            <mat-card-content class="loading-error-content">
              <p>
                Erro ao carregar gráfico
                <strong>Notícias por Encontro(Top 5)</strong>
              </p>
              <button
                mat-raised-button
                (click)="recarregarOrgaosPorTempo()"
                class="button-login"
                color="primary"
              >
                Recarregar Gráfico
              </button>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </mat-tab>

  <!-------------------------------------Tabela-------------------------------------------------------------------->
  <mat-tab label="Tabela Comunidade">
    <app-tabela
      [conteudo]="4"
      [request]="request"
      [service]="dashboardComunidadeService"
      (countData)="handleCountData($event)"
      (loadingCount)="handleLoadingCount($event)"
    >
    </app-tabela>
  </mat-tab>
</mat-tab-group>
