import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  midia: string;
  link: string;
  data: string;
  veiculo: string;
  titulo: string;
  arquivo: string;
}

@Component({
  selector: 'app-dialog-pdf',
  templateUrl: './dialog-pdf.component.html',
  styleUrls: ['./dialog-pdf.component.scss'],
})
export class DialogPdfComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  pdf: string = `${this.data.arquivo.substring(13)}`;

  closeDialog(): void {
    this.dialogRef.close();
  }

  acessar(): void {
    // Abre o site em uma nova aba do navegador
    window.open(this.data.link, '_blank');
  }
}
