import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  hidePassword = true;
  erroLogin = false;
  mensagemErro = 'Usuário ou senha incorreta';
  loginForm = new FormGroup({
    login: new FormControl(''),
    senha: new FormControl(''),
  });

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    localStorage.clear();
  }

  visibilidadeSenha() {
    this.hidePassword = !this.hidePassword;
  }

  esqueciSenha() {
    this.router.navigate(['esqueci-senha']);
  }

  async login() {
    if (this.loginForm.valid) {
      const _login = this.loginForm.value.login?.trim() as string;
      const _senha = this.loginForm.value.senha as string;

      Swal.fire({
        title: 'Fazendo login...',
        html: 'Aguarde enquanto verificamos suas credenciais.',
        showConfirmButton: false,
        confirmButtonColor: 'orange',
        allowOutsideClick: () => !Swal.isLoading(),
        didOpen: async () => {
          Swal.showLoading();
          try {
            await this.authService.autenticar(_login, _senha).toPromise();

            const nome = localStorage.getItem('nome');
            if (nome) {
              await firstValueFrom(
                this.authService.autenticacaoDoisFatores(nome)
              )
                .then((response) => {
                  if (response.status === 201) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Login bem-sucedido!',
                      text: 'Você será redirecionado para a página de autenticação de dois fatores.',
                      showConfirmButton: false,
                      timer: 1000,
                      timerProgressBar: true,
                    }).then(() => {
                      this.router.navigateByUrl('/verificacao-identidade');
                      this.loginForm.reset();
                    });
                  } else {
                    Swal.fire({
                      icon: 'success',
                      title: 'Login bem-sucedido!',
                      text: 'Você será redirecionado para a página inicial.',
                      showConfirmButton: false,
                      timer: 1000,
                      timerProgressBar: true,
                    }).then(() => {
                      this.router.navigateByUrl('/home');
                      this.loginForm.reset();
                    });
                  }
                })
                .catch((error) => {
                  Sentry.captureException(error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Sistema indisponível',
                    text: 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
                    confirmButtonColor: 'orange',
                  });
                  throw error;
                });
              return;
            }
          } catch (error: any) {
            if (error?.status === 404) {
              Swal.fire({
                icon: 'error',
                title: 'Erro no login',
                text: 'Por favor, verifique suas credenciais e tente novamente.',
                confirmButtonColor: 'orange',
              });
              this.mensagemErro = 'Usuário ou senha incorreta';
            } else {
              Sentry.captureException(error);
              Swal.fire({
                icon: 'error',
                title: 'Sistema indisponível',
                text: 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
                confirmButtonColor: 'orange',
              });
              this.mensagemErro =
                'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.';
            }
            this.erroLogin = true;
          }
        },
      });
    }
  }
}
