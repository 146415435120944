<div class="tela">
  <div class="forms">
    <img src="../../../assets/logo_clipei_novo.png" alt="Logo da Clipei" />

    <form [formGroup]="codeForm" (ngSubmit)="verificarIdentidade()">
      <span class="titulo-forms">Verificação de Identidade</span>

      <p>
        Para segurança extra, você precisará verificar sua identidade. Nós
        mandamos um código de verificação para
        <strong>{{ emailCriptografado }}</strong
        >.
      </p>

      <mat-form-field appearance="outline">
        <mat-label for="code">Código</mat-label>
        <input
          id="code"
          formControlName="code"
          type="text"
          matInput
          placeholder="Código de Verificação"
          maxlength="6"
          (change)="getTokenErrorMessage()"
        />
        <mat-error *ngIf="this.codeForm.invalid">{{
          getTokenErrorMessage()
        }}</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="(codeForm.invalid || isLoading) && desativaBotao"
      >
        <mat-progress-spinner
          class="spinner"
          diameter="25"
          color="accent"
          *ngIf="isLoading"
          mode="indeterminate"
        ></mat-progress-spinner>
        Verificar Código
      </button>
    </form>
  </div>
</div>
